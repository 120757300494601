import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import useSelectFilters from "../../../../../customHooks/useSelectFilters";
import { shouldHideForCurrentClient } from "../../../../../utils/commonUtilities";
import Filter from "../../../../ProductProfile/ProductProfileTable/Filter";
import * as Notify from "../../../../../components/Notification/Notifications";
import Select from "../../../../../components/Filters/SingleSelect";

const Filters = (props) => {
  const {
    filterLabels,
    filterOptions,
    fetchFilterData,
    saveDetails,
    basicDetails,
    resetStep1,
    isEdit,
    distanceInputRef,
  } = props;

  const [level1Options, setlevel1Options] = useState();
  const [level2Options, setlevel2Options] = useState();
  const [level3Options, setlevel3Options] = useState();
  const [transferOptions, setTransferOptions] = useState();
  const [transfer, setTransfer] = useState(null);

  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
  ] = useSelectFilters(
    fetchFilterData,
    true,
    level2Options,
    level3Options,
    filterOptions?.l4_name,
    setlevel2Options,
    setTransferOptions
  );

  const prep = [
    {
      id: 1,
      isRequired: true,
      label: filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      isDisabled: isEdit,
    },
    {
      id: 2,
      label: filterLabels?.level2,
      isRequired: true,
      name: "level2",
      value: level2,
      onChange: onLevel2Change,
      placeHolder: `Select ${filterLabels?.level2 ? filterLabels?.level2 : ""}`,
      options: level2Options?.length ? level2Options : [],
      level: "l2_name",
      isDisabled: isEdit,
    },
    {
      id: 3,
      label: filterLabels?.level3,
      isRequired: true,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${filterLabels?.level3 ? filterLabels?.level3 : ""}`,
      optionsArray: level3Options?.length ? level3Options : [],
      optionsArray: isEdit ? level3 : (level3Options?.length ? level3Options : []),
      // hide: shouldHideForCurrentClient("l3_name"),
      isMultiSelect: true,
      level: "l3_name",
      isDisabled: isEdit,
    },
    // {
    //   id: 4,
    //   label: "Transfer Within",
    //   value: level4,
    //   onChange: onLevel4Change,
    //   placeHolder: `Select ${filterLabels?.level2 ? filterLabels?.level2 : ""}`,
    //   options: transferOptions?.length ? transferOptions : [],
    //   level: "l4_name",
    // },
  ];

  useEffect(() => {
    setlevel1Options(filterOptions?.l1_name);
    setlevel2Options(filterOptions?.l2_name);
    setlevel3Options(filterOptions?.l3_name);
    !transferOptions?.length &&
      setTransferOptions(filterOptions?.transfer_within);
  }, [filterOptions]);

  useEffect(() => {
    if (basicDetails?.distance) {
      distanceInputRef.current.value = basicDetails.distance;
    }
    if (!isEmpty(basicDetails)) {
      setLevel1(basicDetails?.l1_name);
      setLevel2(basicDetails?.l2_name);
      setLevel3(basicDetails?.l3_name);
      setTransfer(basicDetails?.transfer);
      if (isEdit) {
        fetchFilterData({
          l1_name: basicDetails?.l1_name,
          l2_name: basicDetails?.l2_name,
        });
        fetchFilterData({});
      }
    } else {
      !isEdit && fetchFilterData({});
    }
  }, [basicDetails]);

  const saveData = () => {
    // if(!level1 || !level2 || !level3 || !level4) {
    //   Notify.error("Please enter all the mandatory fields!!");
    // }
    // else {
    saveDetails(
      { l1_name: level1, l2_name: level2, l3_name: level3, level4: transfer },
      distanceInputRef?.current?.value,
      true
    );
    // }
  };

  // useEffect(() => {
  //   if(!transfer && transferOptions?.length && !isEdit) {
  //     setTransfer(transferOptions?.[0])
  //   }
  // }, [transferOptions])

  useEffect(() => {
    if(level3?.length || transfer?.value) {
      saveData();
    }
  }, [level3, transfer]);

  const onTransferChange = (val) => {
    setTransfer(val);
  }

  return (
    <>
      <Filter data={prep} />
      <div className="col-md-3">
        <label className="fnt-md fnt-bold fnt-bold">
          Transfer Within
        </label>
        <Select
          name="department"
          value={transfer}
          onChange={onTransferChange}
          placeHolder={"Select Trasfet Within"}
          options={transferOptions?.length ? transferOptions : []}
        />
      </div>
      <div className="px-4">
        <label className="fnt-md fnt-bold fnt-bold">Distance(miles)</label>
        <input
          ref={distanceInputRef}
          onKeyDown={(event) => {
            if (event.key === ".") {
              event.preventDefault();
            }
          }}
          className="form-input form-control w-auto ml-2"
          type="number"
          placeholder="Distance"
        />
      </div>
      {/* <div className="col-md-3 mt-2">
        <button
            onClick={saveData}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Save
        </button>
      </div> */}
    </>
  );
};

export default React.memo(Filters);
