import React, { useState } from 'react'
import StoreTransferRulesView from './StoreTransferRules/StoreTransferRulesView';
import StoreTransferConfigView from './StoreTransferConfiguration/StoreTransferConfigView';
import { Tab, Tabs } from 'react-bootstrap';

const StoreToStoreConfigContainer = () => {
    const [activeTab, setActiveTab] = useState("str");

    const handleTabSelect = (f_tab) => {
        setActiveTab(f_tab)
      }

  return (
    <div className="container__tabs">
    <Tabs defaultActiveKey="str" activeKey={activeTab}
      onSelect={handleTabSelect}>
      <Tab eventKey="str" title="Store Transfer Rules">
        {
          (activeTab === "str") &&
          <StoreTransferRulesView />
        }
      </Tab>
        <Tab eventKey="stc" title="Store Transfer Configuration">
          {
            (activeTab === "stc") &&
            <StoreTransferConfigView />
          }
        </Tab>
    </Tabs>
  </div>
  );
}

export default StoreToStoreConfigContainer;
