export const DOWNLOAD_CSV_BEFORE_FINALIZE = "@@stsFinalize/DOWNLOAD_CSV_BEFORE_FINALIZE"
export const DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS = "@@stsFinalize/DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS"
export const DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR = "@@stsFinalize/DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR"
export const UPDATE_S2S_FINALIZE = "@@stsFinalize/UPDATE_S2S_FINALIZE";
export const UPDATE_S2S_FINALIZE_SUCCESS = "@@stsFinalize/UPDATE_S2S_SUCCESS";
export const UPDATE_S2S_FINALIZE_ERROR = "@@stsFinalize/UPDATE_S2S_ERROR";

export const RESET_ALL = "@@stsFinalize/RESET_ALL";
export const RESET_ALL_FINALIZE = "@@stsFinalize/RESET_ALL_FINALIZE";
export const GET_PAST_ALLOCATION_PO_S2S = "@@stsFinalize/GET_PAST_ALLOCATION_PO_S2S";
export const GET_PAST_ALLOCATION_PO_SUCCESS_S2S = "@@stsFinalize/GET_PAST_ALLOCATION_PO_SUCCESS_S2S";
export const GET_PAST_ALLOCATION_PO_ERROR_S2S = "@@stsFinalize/GET_PAST_ALLOCATION_PO_ERROR_S2S";

export const DOWNLOAD_CSV = "@@stsFinalize/DOWNLOAD_CSV";
export const DOWNLOAD_CSV_SUCCESS = "@@stsFinalize/DOWNLOAD_CSV_SUCCESS";
export const DOWNLOAD_CSV_ERROR = "@@stsFinalize/DOWNLOAD_CSV_ERROR";

export const DISABLE_EDIT = "@@stsFinalize/DISABLE_EDIT";
export const DISABLE_EDIT_SUCCESS = "@@stsFinalize/DISABLE_EDIT_SUCCESS";
export const DISABLE_EDIT_ERROR = "@@stsFinalize/DISABLE_EDIT_ERROR";

export const FINALIZE_ALLOCATION = "@@stsFinalize/FINALIZE_ALLOCATION";
export const FINALIZE_ALLOCATION_SUCCESS = "@@stsFinalize/FINALIZE_ALLOCATION_SUCCESS";
export const FINALIZE_ALLOCATION_ERROR = "@@stsFinalize/FINALIZE_ALLOCATION_ERROR";

export const UPDATE_STORE_TRANSFER_QTY = "@@stsFinalize/UPDATE_STORE_TRANSFER_QTY"
export const UPDATE_STORE_TRANSFER_QTY_SUCCESS = "@@stsFinalize/UPDATE_STORE_TRANSFER_QTY_SUCCESS"
export const UPDATE_STORE_TRANSFER_QTY_ERROR = "@@stsFinalize/UPDATE_STORE_TRANSFER_QTY_ERROR"