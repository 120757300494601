import * as actionTypes from "./ActionTypes"

export const getProductViewSummary = (payload) => ({
    type: actionTypes.GET_PRODUCT_VIEW_SUMMARY,
    payload
})

export const getProductStoreView = (payload) => ({
    type: actionTypes.GET_PRODUCT_STORE_VIEW,
    payload
})

export const updateTransferUnitsForMultipleProducts = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS,
    payload
})

export const updateTransferUnitsForMultipleProductsSuccess = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_SUCCESS,
    payload
})

export const updateTransferUnitsForMultipleProductsError = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_ERROR,
    payload
})

export const resetAll = () => ({
    type: actionTypes.RESET_ALL,
  });