import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  FETCH_FILTER_DATA,
  FETCH_TABLE_DATA,
  FETCH_SKU_TABLE_DATA,
  fetchDailySummaryDataSuccess,
  fetchDailySummaryDataError,
  fetchSKUDataS2SError,
  fetchSKUDataS2SSuccess,
} from "./DailySummaryS2SActions";

import { ERROR_MSG } from "../../../../constants/globalConstant";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  getDailySummaryFilterDataS2S,
  getDailySummaryDataS2S,
  getDailySummaryStoreArticleDetailsS2S,
  getDailySummarySKUDataS2S,
} from "../../../../routes/api";
import {
  cardsInfo,
  DAILY_SUMMARY_TABLE_HEADERS_S2S,
} from "./DailySummaryConstants";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

const level_ln_map = {
  level1: "l1_name",
  level2: "l2_name",
  level3: "l3_name",
  level4: "l4_name",
  level5: "l5_name",
  level6: "l6_name",
  level7: "l7_name",
  article: "article",
  color: "color",
  style: "style",
  source_store: "source_store",
  destination_store: "destination_store",
};

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if (payload?.start_date) {
      req["start_date"] = payload?.start_date;
    }
    if (payload?.end_date) {
      req["end_date"] = payload?.end_date;
    }
    delete payload?.start_date;
    delete payload?.end_date;
    if (payload) {
      for (const key in payload) {
        if (!isEmpty(payload[key])) {
          if (Array.isArray(payload[key][0])) {
            req[level_ln_map[key]] = payload[key]?.[0].map((ele) => ele.value);
          } else {
            req[level_ln_map[key]] = payload[key].map((ele) => ele.value);
          }
        }
      }
    }
    if (filterType) {
      req["filter_type"] = filterType;
    }
    const res = yield call(getDailySummaryFilterDataS2S, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping;
      for (const key in res.data.data[0]) {
        let k = "";
        if (key === "l1_name") {
          k = "departmentOptions";
        } else if (key === "l2_name") {
          k = "genderOptions";
        } else if (key === "l3_name") {
          k = "subCatOptions";
        } else if (key === "l4_name") {
          k = "dcsOptions";
        } else if (key === "l5_name") {
          k = "level5Options";
        } else if (key === "l6_name") {
          k = "level6Options";
        } else if (key === "l7_name") {
          k = "level7Options";
        } else if (key === "color") {
          k = "colorOptions";
        } else if (key === "article") {
          k = "articleIdOptions";
        } else if (key === "style") {
          k = "styleOptions";
        } else if (key === "source_store") {
          k = "sourceStoreOptions";
        } else if (key === "destination_store") {
          k = "destinationStoreOptions";
        }

        if (Array.isArray(res?.data?.data?.[0]?.[key])) {
          data[k] = res.data.data[0][key]
            ?.filter((val) => val)
            .map((element) => ({
              value: element,
              label: element,
            }));
        } else {
          data[k] = [];
          if (res?.data?.data?.[0]?.[key]) {
            for (const [label, value] of Object.entries(
              res?.data?.data?.[0]?.[key]
            )) {
              data[k].push({
                label: label,
                value: value,
              });
            }
          }
        }
      }
      yield put(
        fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key })
      );
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* fetchStoreDataWorker(action) {
  try {
    const { payload } = action;
    const { request } = payload;
    const req = {};
    for (let i in request) {
      if (!isEmpty(request[i])) {
        if (Array.isArray(request[i])) {
          req[i] = request[i].map((val) => val.value);
        } else {
          req[i] = request[i];
        }
      }
    }

    const res = yield call(getDailySummaryDataS2S, req);

    if (res.data.status) {
      const { aggregated_data, table_data } = res?.data;

      const l_aggregated_data = aggregated_data[0];

      const cardData = [
        {
          title: cardsInfo.CARD1,
          value: l_aggregated_data?.allocation_count || 0,
        },
        {
          title: cardsInfo.CARD2,
          value: l_aggregated_data?.style_color_count || 0,
        },
        {
          title: cardsInfo.CARD3,
          value: l_aggregated_data?.total_transfer_units || 0,
        },
        {
          title: cardsInfo.CARD4,
          value: l_aggregated_data?.total_source_inventory || 0,
        },
        {
          title: cardsInfo.CARD5,
          value: l_aggregated_data?.total_destination_inventory || 0,
        },
      ];
      let excelData = [];

      res.data.table_data.forEach((detail) => {
        detail.org_released_at = detail.released_at
          ? moment(detail.released_at).format("MM-DD-YYYY HH:mm:ss")
          : detail.released_at;
        detail.released_at = detail.released_at
          ? moment(detail.released_at).format("YYYY-MM-DD HH:mm:ss.SSS Z")
          : detail.released_at;

        excelData.push({
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.ARTICLE_ID]: detail.article,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE]: detail.style,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.COLOR]: detail.color,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE_COLOR]: detail.style_color,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE_DESCRIPTION]:
            detail.style_description,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.GLOBAL_COLOR]: detail.global_color,
          [levelFiltersLabelFormatter("level1")]: detail.l1_name,
          [levelFiltersLabelFormatter("level2")]: detail.l2_name,
          [levelFiltersLabelFormatter("level3")]: detail.l3_name,
          [levelFiltersLabelFormatter("level4")]: detail.l4_name,
          [levelFiltersLabelFormatter("level5")]: detail.l5_name,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.ALLOCATION_ID]: detail.plan_name,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.RELEASED_AT]: detail.org_released_at,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.TRANSFER_UNITS]:
            detail.transfer_units,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.SOURCE_INVENTORY]:
            detail.source_inventory,
          [DAILY_SUMMARY_TABLE_HEADERS_S2S.DEST_INVENTORY]:
            detail.destination_inventory,
        });
      });

      yield put(
        fetchDailySummaryDataSuccess({
          data: res.data.table_data,
          cardData: cardData,
          excelData: excelData,
        })
      );
    } else {
      yield put(fetchDailySummaryDataError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(fetchDailySummaryDataError({ error: ERROR_MSG }));
  }
}

function* fetchSKUDataWorker(action) {
  try {
    const { payload } = action;
    const { request } = payload;
    const req = {};
    for (let i in request) {
      if (!isEmpty(request[i])) {
        if (Array.isArray(request[i])) {
          req[i] = request[i].map((val) => val.value);
        } else {
          req[i] = request[i];
        }
      }
    }

    if (request?.is_store_view) {
      delete req?.is_store_view;
    }

    const res = yield call(
      request?.is_store_view
        ? getDailySummaryStoreArticleDetailsS2S
        : getDailySummarySKUDataS2S,
      req
    );

    const excelData = res.data.data.flatMap((detail) => {
      // If there are no subRows, create one row with main details
      if (!detail.subRows || detail.subRows.length === 0) {
        return [
          {
            "Plan Name": detail.plan_name,
            "Style Color Id": detail.article,
            Size: detail.size,
            "Destination Store": "",
            "Source Store": "",
            "Transfer Units": "",
            "Source Inventory": "",
            "Source WOS": "",
            "Destination Inventory": "",
            "Destination WOS": "",
          },
        ];
      }

      // If there are subRows, create a row for each subRow
      return detail.subRows.map((subRow) => ({
        "Plan Name": detail.plan_name,
        "Style Color Id": detail.article,
        Size: detail.size,
        "Destination Store": subRow.destination_store,
        "Source Store": subRow.source_store,
        "Transfer Units": subRow.transfer_units,
        "Source Inventory": subRow.source_inventory,
        "Source WOS": subRow.source_wos,
        "Destination Inventory": subRow.destination_inventory,
        "Destination WOS": subRow.dest_wos,
      }));
    });

    if (res.data.status) {
      yield put(
        fetchSKUDataS2SSuccess({
          data: res?.data?.data,
          excelData: excelData,
        })
      );
    } else {
      yield put(fetchSKUDataS2SError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(fetchSKUDataS2SError({ error: ERROR_MSG }));
  }
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchTableData() {
  yield takeLatest(FETCH_TABLE_DATA, fetchStoreDataWorker);
}

function* fetchSKUTableData() {
  yield takeLatest(FETCH_SKU_TABLE_DATA, fetchSKUDataWorker);
}

export function* dailySummaryS2SSaga() {
  yield all([fetchFilterDataWatcher(), fetchTableData(), fetchSKUTableData()]);
}
