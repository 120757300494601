import { intersectionWith, isEqual } from "lodash";
import { useState } from "react";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";

function useSelectFilterStoretoSore(
  fetchFilterData,
  dynamicHeaderForTable = false,
  level2Options,
  level3Options,
  level4Options,
  setlevel2Options,
  setlevel3Options,
  setlevel4Options,
  level5Options,
  setlevel5Options,
  level6Options,
  setlevel6Options,
  level7Options,
  setlevel7Options
) {
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [level4, setLevel4] = useState(null);
  const [level5, setLevel5] = useState(null);
  const [level6, setLevel6] = useState(null);
  const [level7, setLevel7] = useState(null);
  const [request, setRequest] = useState(null);

  const resetRestFilters = (p_index) => {
    if (p_index == 0) {
      level2 && setLevel2(null);
      level2Options && setlevel2Options(null)
    //   lmsStoreAttribute && setLMSAttribute(null);
    //   lmsStoreValue && setLMSValue(null);
    //   lmsStoreValueOptions && setLMSValueOptions(null);
    }
    if (p_index == 1 || p_index == 0) {
      level3 && setLevel3(null);
      level3Options && setlevel3Options(null)
    //   lmsStoreAttribute && setLMSAttribute(null);
    //   lmsStoreValue && setLMSValue(null);
    //   lmsStoreValueOptions && setLMSValueOptions(null);
    }
    if (p_index == 1 || p_index == 0 || p_index === 2) {
      level4 && setLevel4(null)
      level4Options && setlevel4Options(null)
    }
    if (p_index == 1 || p_index == 0 || p_index === 2 || p_index === 3) {
      level5 && setLevel5(null)
      level5Options && setlevel5Options(null)
    }
    if (p_index == 1 || p_index == 0 || p_index === 2 || p_index === 3 || p_index === 4) {
      level6 && setLevel6(null)
      level6Options && setlevel6Options(null)
    }
    if (p_index == 1 || p_index == 0 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 5) {
      level7 && setLevel7(null)
      level7Options && setlevel7Options(null)
    //   styleIdValue && setStyleIdValue(null);
    //   styleIdOptions && setStyleIdOptions(null);
    //   styleColorIdValue && setStyleColorIdValue(null);
    //   styleColorIdOptions && setStyleColorIdOptions(null);
    }

    console.log("Reset Filter is being called");
  };

  const onLevel1Change = (val, p_userPreference) => {
    setLevel1(val);
    resetRestFilters(0);
    if (p_userPreference?.user_preference) {
      fetchFilterData({ l1_name: [val] }, { key: "l10" });
    } else {
      fetchFilterData({ l1_name: [val] }, { key: "l2" });
    }
    setRequest((old) => {
      return {
        ...old,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,
      };
    });
  };
  const onLevel2Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], level2Options, isEqual);
      setLevel2(valuesArray[1]);
      if (event?.user_preference) {
        fetchFilterData(
          { l1_name: [event?.l1_name], l2_name: val },
          { key: "l3" }
        );
      } else {
        fetchFilterData({ l1_name: [level1], l2_name: req_val }, { key: "l3" });
      }
      // fetchFilterData({ l1_name: [level1], l2_name: req_val }, { key: "l3" })
      setRequest((old) => {
        return {
          ...old,
          level2: valuesArray[0],
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
        };
      });
    } else {
      setLevel2(val);
      if (event?.user_preference) {
        fetchFilterData(
          { l1_name: [event?.l1_name], l2_name: [val] },
          { key: "l3" }
        );
      } else {
        fetchFilterData({ l1_name: [level1], l2_name: [val] }, { key: "l3" });
      }
      setRequest((old) => {
        return {
          ...old,
          level2: val,
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
        };
      });
    }
    resetRestFilters(1);
    //API call to get next filter
  };
  const onLevel3Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);
      // let value = intersectionWith(val, level3Options, isEqual);
      setLevel3(valuesArray[1]);
      fetchFilterData(
        { l1_name: [level1], l2_name: [level2], l3_name: req_val },
        { key: "l4" }
      );
      setRequest((old) => {
        return {
          ...old,
          level3: valuesArray[0],
          level4: null,
          level5: null,
          level6: null,
          level7: null,
        };
      });
    } else {
      setLevel3(val);
      // if(event?.user_preference) {
      //   fetchFilterData({ l1_name: [event?.l1_name], l2_name: [event?.l2_name], l3_name: [val] },{key:'l4'});
      // }
      // else{
      fetchFilterData(
        { l1_name: [level1], l2_name: [level2], l3_name: [val] },
        { key: "l4" }
      );
      // }
      setRequest((old) => {
        return {
          ...old,
          level3: val,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
        };
      });
    }
    resetRestFilters(2);
  };

  const onLevel4Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel4(valuesArray[1]);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: level3,
          l4_name: req_val,
        },
        { key: "l6" }
      );
      setRequest((old) => {
        return {
          ...old,
          level4: valuesArray[0],
          level5: null,
          level6: null,
          level7: null,
        };
      });
    } else {
      setLevel4(val);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: [level3],
          l4_name: [val],
        },
        { key: "l6" }
      );
    }
    resetRestFilters(3);
  };

  const onLevel5Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel5(valuesArray[1]);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: level3,
          l4_name: level4,
          l5_name: req_val,
        },
        { key: "l7" }
      );
      setRequest((old) => {
        return {
          ...old,
          level5: valuesArray[0],
          level6: null,
          level7: null,
        };
      });
    } else {
      setLevel5(val);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: [level3],
          l4_name: [level4],
          l5_name: [val],
        },
        { key: "l7" }
      );
    }
    resetRestFilters(4);
  };

  const onLevel6Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel6(valuesArray[1]);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: [level3],
          l4_name: level4,
          l5_name: level5,
          l6_name: req_val,
        },
        { key: "l8" }
      );
      setRequest((old) => {
        return {
          ...old,
          level6: valuesArray[0],
          // "level7": null
        };
      });
    } else {
      setLevel6(val);
      fetchFilterData(
        {
          l1_name: [level1],
          l2_name: [level2],
          l3_name: [level3],
          l4_name: [level4],
          l5_name: [level5],
          l6_name: [val],
        },
        { key: "l8" }
      );
    }
    resetRestFilters(5);
  };

  const onLevel7Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      // let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel7(valuesArray[1]);
      setRequest((old) => {
        return {
          ...old,
          level7: valuesArray[0],
        };
      });
    } else {
      setLevel7(val);
    }
  };

  const resestRequest = () => {
    setRequest(null);
  };

  return [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    request,
    resestRequest,
    level5,
    setLevel5,
    onLevel5Change,
    level6,
    setLevel6,
    onLevel6Change,
    level7,
    setLevel7,
    onLevel7Change,
  ];
}

export default useSelectFilterStoretoSore;
