import React from "react";
import SelectCellComponent from "../../../../../components/Table/Cellrenderer/SelectCellComponent";
import ReactTable from "../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../../UserManagement/ColumnFilter";
import { COMBINATION_OPTIONS } from "./constants";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreCombinationTable = ({ data, setRTInstance, initialSelectedRows }) => {
  const COLUMNS = [
    {
      Header: "Store Number",
      accessor: "store_code",
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
      filter: 'bulkFilterCommaSeperated',
      width: 200,
    },
    {
      Header: "Store Name",
      accessor: "store_name",
      Filter: ColumnFilter,
      width: 250,
    },
    {
      Header: "Store Type",
      accessor: "store_type",
      Cell: (instance) => (
        <div>
          <SelectCellComponent
            defaultValue={Number(instance.row?.original?.store_type) || COMBINATION_OPTIONS[0]?.value}
            options={COMBINATION_OPTIONS}
            changeHandler={(rowIdx, columnId, value) => {}}
            {...instance}
          />
        </div>
      ),
      disableFilters: true,
      width: 200,
    },
  ];

  return (
    <ReactTableWithPlugins
      shouldPagination
      totalRecordsLen={data ? data.length : 0}
      data={data?.length ? data : []}
      columns={COLUMNS}
      initialSelectedRows={initialSelectedRows}
      getInstanceOnMount={(instance) => {
        setRTInstance(instance);
      }}
      features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
      renderMarkup="TableMarkup"
      keyRT={"StyleInvTable"}
      tableId="store_rule_combination"
      hideColumnsFilter
      hidebutton
    />
  );
};
export default StoreCombinationTable;
