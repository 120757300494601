import React, { useEffect, useState } from "react";
import ModalComponent from "../../../../../components/ModalComponent/ModalComponent";
import StoreCombinationTable from "./StoreCombinationTable";
import * as Notify from "../../../../../components/Notification/Notifications";
import { COMBINATION_OPTIONS } from "./constants";
import SetAllModal from "../../../../../components/ErrorModal/SetAllModal";
import { cloneDeep } from "lodash";

const StoreCombination = (props) => {
  const {
    RTInstance,
    showPopup,
    closePopup,
    moveForward,
    isEdit,
    data,
    selectedStores,
  } = props;

  const [uniqueStores, setUniqueStores] = useState([]);
  const [tableInstance, setRTInstance] = useState({});
  const [showSetAll, setShowSetAll] = useState(false);
  const [storeType, setStoreType] = useState(null);
  const [initialSelectedRows, setInitialSelectedRows] = useState({});
  const [selectedRowIds, setSelectedRows] = useState({});

  useEffect(() => {
    const stores = [];
    const uniqueStoreIds = [];
    const selected = Object.values(selectedStores)?.flat();
    const selectedRows = {};
    let index = 0;

    if (data?.length) {
      data.forEach((store) => {
        if (selected.includes(store.store_code)) {
          selectedRows[index] = true;
          uniqueStoreIds.push(store.store_code);
          stores.push(store);
          index += 1;
        }
      });
    }
    // else if (!isEdit) {
    const { getSelectedRows, preFilteredRowsById: rows } = RTInstance;
    getSelectedRows?.forEach((key) => {
      const store = cloneDeep(rows[key]?.original);
      if (rows[key]?.depth && !uniqueStoreIds.includes(store.store_code) && !store?.is_deleted) {
        uniqueStoreIds.push(store.store_code);
        store["store_type"] = 1;
        stores.push(store);
        if (selected.includes(store.store_code)) {
          selectedRows[index] = true;
          index += 1;
        }
      }
    });
    setUniqueStores(stores);
    setInitialSelectedRows(selectedRows);
    // }
  }, [RTInstance]);

  const openSetAllModal = () => {
    if (tableInstance?.selectedFlatRows?.length) {
      setShowSetAll(true);
    } else {
      Notify.error("Please select atleast one row!!");
    }
  };

  const onChangeHandler = (val) => {
    setStoreType(val.value);
  };

  const prepInput = React.useMemo(
    () => [
      {
        id: 1,
        isRequired: true,
        label: "Store Action",
        name: "status",
        className: "setAll__filter",
        onChange: onChangeHandler,
        // placeHolder: `Select `,
        options: COMBINATION_OPTIONS,
        isMultiSelect: false,
        hideSelectAll: true,
      },
    ],
    []
  );

  const handleSetAllOk = () => {
    const setAllRows = Object.keys(tableInstance.state.selectedRowIds);
    setAllRows.forEach((rowIdx) => {
      console.log("rowIdx", rowIdx);
      tableInstance.updateMyData(rowIdx, "store_type", storeType);
    });
  };

  const handleClose = () => {
    setShowSetAll(false);
  };

  const goNext = () => {
    if (tableInstance?.selectedFlatRows?.length < 2) {
      Notify.error("Please select minimum two stores!!");
    } else {
      const data = tableInstance?.selectedFlatRows;
      const isValid =
        data.some((item) => item?.original?.store_type == 1) ||
        (data.some((item) => item?.original?.store_type == 2) &&
          data.some((item) => item?.original?.store_type == 3));
      if (isValid) {
        moveForward(
          data.map((store) => store.original),
          true
        );
      } else {
        Notify.error(
          "Please select at least one source and destination combination!!"
        );
      }
    }
  };

  return (
    <div className="modal__div" style={{ zIndex: 1000 }}>
      {showSetAll && (
        <SetAllModal
          modalBodyStyle={{ height: "30rem" }}
          filter={prepInput}
          messageStyle={{ margin: "auto", color: "red" }}
          handleOk={handleSetAllOk}
          handleCancel={handleClose}
          handleClose={handleClose}
          disableApply={!storeType}
        />
      )}
      <ModalComponent showAlert={showPopup} closeAlert={closePopup} width="60%">
        <div className="modal-container">
          <div className="modal-header">
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div>
                <h5 className="page-heading m-0">Stores Combination</h5>
              </div>
              <div>
                <button type="button" className="close" data-dismiss="modal">
                  <i
                    className="fa fa-times text-right m-2 "
                    aria-hidden="true"
                    onClick={closePopup}
                    style={{ cursor: "pointer" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            className="modal-middle-container modal-body"
            style={{ padding: "auto" }}
          >
            <StoreCombinationTable
              data={uniqueStores}
              setRTInstance={setRTInstance}
              initialSelectedRows={initialSelectedRows}
              selectedRowIds={selectedRowIds}
              storeType={storeType}
            />
          </div>
          <div className="d-flex justify-content-center py-4">
            <button
              className="btn btn-primary fnt-md px-2 mr-4"
              onClick={openSetAllModal}
            >
              Set All
            </button>
            <button className="btn btn-primary fnt-md px-2" onClick={goNext}>
              Next
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default StoreCombination;
