import DailySummaryReducer from "./DailySummaryReducers";
import StoreViewReducer from "./StoreView/Reducers/StoreViewReducer";
import DailySummaryS2SReducers from "./DailySummaryS2S/DailySummaryS2SReducers";
import dailyStoreViewS2S from "./DailySummaryS2S/StoreViewS2S/Reducers/StoreViewS2SReducer"

const rootReducer = {
    ...DailySummaryReducer,
    ...StoreViewReducer,
    ...DailySummaryS2SReducers,
    ...dailyStoreViewS2S
}

const dailySummaryReducers = rootReducer

export default dailySummaryReducers