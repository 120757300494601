import React, { useRef } from "react";
import Filters from "./Filters";
import {
  getFilters,
  resetStep1,
  validateRuleName,
} from "../Actions/CreateStoreTransferRuleActions";
import { connect } from "react-redux";
// import { useEffect } from "react";
import * as Notify from "../../../../../components/Notification/Notifications";
import { useEffect } from "react";
import PageLoader from "../../../../../components/Loader/PageLoader";
import { useState } from "react";
import { isEmpty } from "lodash";

const BasicDetails = (props) => {
  const {
    validateName,
    storeValues,
    basicDetails,
    rules,
    isEdit,
    validateLoading,
    validateSuccess,
    validateError,
    resetStep1,
    refs
  } = props;

  // const nameInputRef = useRef();

  const [selectedFilters, setFilters] = useState({});

  const saveDetails = (filters, distance, isSaveClicked) => {
    // if (refs?.nameInputRef?.current?.value) {
    //   // Notify.error("Please enter the rule name!!");
    //   storeDetails(filters, distance);
    //   setFilters(filters);
    // } else {
    //   // if (isSaveClicked && isEdit) {
    //   storeDetails(filters, distance);
    //     // Notify.success("Changes saved, click next to continue!!");
    //   // }
    //   setFilters(filters);
    //   // !isEdit && validateName({ rule_name: nameInputRef?.current?.value });
    // }
    storeDetails(filters, distance);
    setFilters(filters);
  };

  useEffect(() => {
    if (basicDetails?.ruleName) {
      refs.nameInputRef.current.value = basicDetails.ruleName;
    }
  }, [basicDetails]);

  useEffect(() => {
    if (isEdit && rules?.length && isEmpty(basicDetails)) {
      refs.nameInputRef.current.value = rules[0]?.rule_name;
      refs.distanceInputRef.current.value = rules[0]?.distance_filter;
      let filterObj = {};
      const { product_filters, transfer_within } = rules[0];
      for (let key in product_filters) {
        filterObj[key] = product_filters[key]
          ?.filter((val) => val !== "*")
          ?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
      }
      filterObj["level4"] = { label: transfer_within, value: transfer_within };
      const dis =
        basicDetails?.distance >= 0
          ? basicDetails?.distance
          : rules[0]?.distance_filter;
      storeDetails(filterObj, dis);
      saveDetails(
        filterObj,
        basicDetails?.distance >= 0
          ? basicDetails?.distance
          : rules[0]?.distance
      );
    }
  }, [rules]);

  // useEffect(() => {
  //   if (validateError) {
  //     Notify.error(validateError);
  //   } 
  //   else if (validateSuccess) {
  //     storeDetails();
  //     !isEdit && Notify.success("Changes saved, click next to continue!!");
  //     resetStep1();
  //   }
  // }, [validateSuccess, validateError]);

  const storeDetails = (
    filters = selectedFilters,
    distance = refs?.distanceInputRef?.current?.value
  ) => {
    storeValues({
      data: {
        l1_name: filters?.l1_name,
        l2_name: filters?.l2_name,
        l3_name: filters?.l3_name,
        transfer: filters?.level4
      },
    });
  };

  return (
    <PageLoader loader={validateLoading}>
      <section className="row section">
        <div className="col-md-3 required">
          <label className="fnt-md fnt-bold fnt-bold required">
            Enter Rule Name
          </label>
          <input
            disabled={isEdit}
            ref={refs.nameInputRef}
            className="form-input form-control w-auto"
            type={"text"}
            placeholder="Enter Rule Name"
          />
        </div>
      </section>
      <section className="row section">
        <Filters distanceInputRef={refs.distanceInputRef} isEdit={isEdit} {...props} saveDetails={saveDetails} />
      </section>
    </PageLoader>
  );
};

const mapStateToProps = ({ createRule, storeTransferRules }) => ({
  filterOptions: createRule.filters,
  filterLabels: createRule.filterLabels,
  basicDetails: createRule.basicDetails,
  rules: storeTransferRules.rules,
  validateLoading: createRule.validateLoading,
  validateSuccess: createRule.validateSuccess,
  validateError: createRule.validateError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload) => dispatch(getFilters(payload)),
  validateName: (payload) => dispatch(validateRuleName(payload)),
  resetStep1: () => dispatch(resetStep1()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
