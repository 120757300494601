export const initialState = {
    isFilterLoading: false,
    filters: {},
    stores: [],
    storesLoading: false,
    storesError: null,
    basicDetails: {},
    transfer: null,
    distance: null,
    ruleName: "",
    selectedStores: {},
    storeMapping: [],
    storeMappingLoading: false,
    storeMappingError: null,
    createRuleLoading: false,
    createRuleSuccess: false,
    createRuleError: null,
    validateLoading: false,
    validateSuccess: false,
    validateError: null,
    selectedRows: {}
}