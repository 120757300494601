
export const cardsInfo = {
  CARD1: "# of Allocation",
  CARD2: "# of Style colors Allocation",
  CARD3: "Total Transfer Units",
  CARD4: "Total Source Inventory",
  CARD5: "Total Destination Inventory", 
};

export const DAILY_SUMMARY_TABLE_HEADERS_S2S = {
  ARTICLE_ID: `Style Color ID`,
  STYLE_DESCRIPTION: "Style Description",
  STYLE_COLOR: "Style Color",
  GLOBAL_COLOR:"Global Color",
  COLOR: "Color",
  ALLOCATION_ID: "Plan Name",
  STORE_ID: "Store Number",
  UNITS_ALLOCATED: "Units Allocated",
  DC_UNITS_OH: "DC Units OH",
  STYLE: "Style",
  COLOR_ID: "Color ID",
  RESERVE_QTY: "Reserved Qty",
  TOTAL: "Total",
  RELEASED_AT: "Released at",
  TRANSFER_UNITS:"Transfer Units",
  SOURCE_INVENTORY:"Source Inventory",
  DEST_INVENTORY:"Destination Inventory",
  STYLE_COLOR:"Style Color",
  GLOBAL_COLOR:"Global Color",
  RELEASED_AT:"Released At",
  STYLE:"Style",
};

export const RELEASE_DATE_FORMAT = "MM-DD-YYYY HH:mm:ss";
       
      