import React, { useRef, useState, useEffect } from "react";
import { skuStoreLevelDetailsData } from "./DailySummaryConstants";
import ColumnFilter from "../../../UserManagement/ColumnFilter.jsx";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import { fetchSKUDataS2S } from "./DailySummaryS2SActions";
import { scrollIntoView } from "../../../../utils/commonUtilities.js";
import { connect } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader.jsx";
import { ERROR_MSG } from "../../../../constants/globalConstant.js";
import ExportFile from "../../../../components/ExportFile/ExportFile.jsx";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function SKUStoreLevelDetailsS2S(props) {
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData?.length) scrollIntoView(tableRef);
  }, [props.tableData]);

  useEffect(() => {
    props.subActiveTab === "reporting_s2s" &&
      props.fetchSKUDataS2S({ request: props.req });
  }, [props.req]);

  const validateData = (data) => Array.isArray(data) && data.length > 0;

  const skuStoreTableColumns = [
    {
      Header: "Plan Name",
      accessor: "plan_name",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 350,
    },
    {
      Header: "Style Color ID",
      accessor: "article",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 200,
    },
    {
      id: "expander",
      Header: "Size",
      accessor: "size",
      Cell: ({ value, row }) => {
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        return row.canExpand ? (
          <div className="row" {...row.getToggleRowExpandedProps()}>
            <span className="expand__text__number">{value}</span>
            {row.isExpanded ? (
              <div className="cursor-pointer">
                <i
                  className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                  title="Collpase"
                ></i>
              </div>
            ) : (
              <div className="cursor-pointer">
                <i
                  className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                  title="Expand"
                ></i>
              </div>
            )}
          </div>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Destination Store",
      accessor: "destination_store",
      width: 200,
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
    },
    {
      Header: "Source Store",
      accessor: "source_store",
      width: 200,
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
    },
    {
      Header: "Transfer Units",
      accessor: "transfer_units",
      width: 200,
      Filter: (instance) => (
        <NumberRangeColumnFilter {...instance} containSubRows={true} />
      ),
      filter: "filterNumberWithSubRows",
    },
    {
      Header: "Source Inventory",
      accessor: "source_inventory",
      width: 200,
      Filter: (instance) => (
        <NumberRangeColumnFilter {...instance} containSubRows={true} />
      ),
      filter: "filterNumberWithSubRows",
    },
    {
      Header: "Source WOS",
      accessor: "source_wos",
      Filter: (instance) => (
        <NumberRangeColumnFilter {...instance} containSubRows={true} />
      ),
      filter: "filterNumberWithSubRows",
      width: 200,
    },
    {
      Header: "Dest. Inventory",
      accessor: "destination_inventory",
      width: 200,
      Filter: (instance) => (
        <NumberRangeColumnFilter {...instance} containSubRows={true} />
      ),
      filter: "filterNumberWithSubRows",
    },
    {
      Header: "Dest. WOS",
      accessor: "dest_wos",
      Filter: (instance) => (
        <NumberRangeColumnFilter {...instance} containSubRows={true} />
      ),
      filter: "filterNumberWithSubRows",
      width: 200,
    },
  ];
  return (
    <PageLoader loader={props.loading} gridLoader={true}>
      {props.error ? (
        <div className="error">{props.tableDataError || ERROR_MSG}</div>
      ) : (
        validateData(props.tableData) && (
          <div style={{ paddingTop: "3rem" }} ref={tableRef}>
            <div className="deepdive__export__wrapper">
              {props?.tableData.length ? (
                <ExportFile
                  text={"Excel"}
                  data={props.skuExcelData}
                  sheetName={"Daily Allocation Summary S2S"}
                  fileName={`daily_summary_S2S_${new Date().getTime()}`}
                  type={1}
                />
              ) : null}
            </div>
            <ReactTableWithPlugins
              columns={skuStoreTableColumns}
              data={props?.tableData?.length ? props.tableData : []}
              renderMarkup="TableMarkup"
              keyRT="sortAndSearch"
              totalRecordsLen={props?.tableData?.length}
              tableId="skuStoreDailySummaryS2S"
              tableWrapperStyle={{
                height: "fit-content",
                maxHeight: "50rem",
              }}
              headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
              containSubRow={true}
              embededScroll
            />
          </div>
        )
      )}
    </PageLoader>
  );
}

const mapStateToProps = ({ dailySummaryS2S }) => ({
  error: dailySummaryS2S.skuError,
  tableDataError: dailySummaryS2S.skuErrorMessage,
  loading: dailySummaryS2S.skuLoading,
  tableData: dailySummaryS2S.skuTableData,
  skuExcelData: dailySummaryS2S.skuExcelData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSKUDataS2S: (payload) => dispatch(fetchSKUDataS2S(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SKUStoreLevelDetailsS2S);
