import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./CellRenderer.scss";
import "../../Filters/Filters.css";
import { drop, intersectionWith, isEmpty, isEqual } from "lodash";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";


const MultiSelect = React.memo(({ ...instance }) => {
  const { defaultOptions, value, dropdownOptions, updateMyData, onDropDownChange, onBlurEvent, isDisabled,
    selectAll } = instance;
  const [dropdownValue, setDropdownValue] = useState(null);
  const [isChanged, setIsChanged] = useState(false)
  const [optionsArray, setOptionArray] = useState([])

  useEffect(() => {
    if (Array.isArray(value)) {
      setDropdownValue(() => value);
    } else if(value) {
      setDropdownValue({ value: value, label: value });
    }
  }, [value]);

  useEffect(() => {
    if(!dropdownOptions || !dropdownOptions.length || isDisabled){
      setOptionArray(dropdownOptions)
    }
  },[isDisabled, dropdownOptions])

  useEffect(()=> {
    if(isEmpty(dropdownOptions))
      setOptionArray(dropdownOptions)
  },[dropdownOptions])

  // useEffect(() => {
  //   if(defaultOptions){
  //     setDropdownValue(() => defaultOptions[0]);
  //   }
  //   else
  //     setDropdownValue(() => value);
  // }, [value]);

  const onFocus = () => {
    if(value?.length && dropdownOptions?.length){
      let optionsArray
      if(selectAll){
        optionsArray = [...new Set([{ label: "Select All", value: "*" },...value,...dropdownOptions])]
      } 
      else{
        optionsArray = [...new Set([...value,...dropdownOptions])]
      }
      const uniqueObjects = [...new Map(optionsArray.map(item => [item.value, item])).values()]

      setOptionArray(uniqueObjects)
    }
    else{
      let optionsArray
      if(selectAll){
        optionsArray = [{ label: "Select All", value: "*" },...dropdownOptions]
      }
      else {
        optionsArray = [...dropdownOptions]
      }
      setOptionArray(optionsArray)
    }
  }


  const onDropDownChangeHandler = (val, event, that) => {
    // let value = intersectionWith(val, dropdownOptions, isEqual);
    setIsChanged(true)
    let valuesArray = handleSelectAll(val, event, that)
    setDropdownValue(() => valuesArray[1]);
    updateMyData(instance.row.index, instance.column.id, valuesArray[1]);

    if (onDropDownChange) {
      onDropDownChange(
        instance.row.index,
        instance.column.id,
        valuesArray[0],
        instance.row.values.article
      );
    }
  };

  const onBlurChangeHandler = () => {
    if (onBlurEvent && isChanged) {
    onBlurEvent(        
      instance.row.index,
      instance.column.id,
      value?.filter(item => item.value !== "*"),
      instance.row.values.article
      );
    }
    setIsChanged(false)
  }

  function onChange(value, event) {
    onDropDownChangeHandler(value,event, this)
  }

  return (
    <>
      <ReactMultiSelectCheckboxes
        // placeholderButtonLabel={props.placeHolder}
        options={(optionsArray && optionsArray) || []}
        onChange={onChange}
        onBlur={onBlurChangeHandler}
        onFocus={onFocus}
        // onKeyDown={props.onKeyDown}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        value={dropdownValue}
        styles={{ menuPortal: base => ({ ...base, zIndex: 10005 }) }}
      />
    </>
  );
});

export default MultiSelect;
