export const FETCH_FILTER_DATA = "@@dashboard/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@dashboard/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@dashboard/FETCH_FILTER_ERROR";

export const GET_RECOMMENDED_ACTIONS_DATA = "@@dashboard/GET_RECOMMENDED_ACTIONS_DATA";
export const GET_RECOMMENDED_ACTIONS_DATA_SUCCESS = "@@dashboard/GET_RECOMMENDED_ACTIONS_DATA_SUCCESS";
export const GET_RECOMMENDED_ACTIONS_DATA_ERROR = "@@dashboard/GET_RECOMMENDED_ACTIONS_DATA_ERROR";

export const GET_STYLE_INVENTORY_DATA = "@@dashboard/GET_STYLE_INVENTORY_DATA";
export const GET_STYLE_INVENTORY_DATA_SUCCESS = "@@dashboard/GET_STYLE_INVENTORY_DATA_SUCCESS";
export const GET_STYLE_INVENTORY_DATA_ERROR = "@@dashboard/GET_STYLE_INVENTORY_DATA_ERROR";

export const GET_CHART_DATA = "@@dashboard/GET_CHART_DATA";
export const GET_CHART_DATA_SUCCESS = "@@dashboard/GET_CHART_DATA_SUCCESS";
export const GET_CHART_DATA_ERROR = "@@dashboard/GET_CHART_DATA_ERROR";
export const GET_STYLE_INV_POPUP_DATA = "@@dashboard/GET_STYLE_INV_POPUP_DATA";
export const GET_STYLE_INV_POPUP_DATA_SUCCESS =
  "@@dashboard/GET_STYLE_INV_POPUP_DATA_SUCCESS";
export const GET_STYLE_INV_POPUP_DATA_ERROR =
  "@@dashboard/GET_STYLE_INV_POPUP_DATA_ERROR";

export const GET_REVIEW_RECOMMEND_DATA = "@@dashboard/GET_REVIEW_RECOMMEND_DATA";
export const GET_REVIEW_RECOMMEND_DATA_PROGRESS = "@@dashboard/GET_REVIEW_RECOMMEND_DATA_PROGRESS";
export const GET_REVIEW_RECOMMEND_DATA_SUCCESS =
  "@@dashboard/GET_REVIEW_RECOMMEND_DATA_SUCCESS";
export const GET_REVIEW_RECOMMEND_DATA_ERROR =
  "@@dashboard/GET_REVIEW_RECOMMEND_DATA_ERROR";

export const RESET_FILTERS_DATA = "@@dashboard/RESET_FILTERS_DATA";

export const CLEAR_CHART_DATA = "@@dashboard/CLEAR_CHART_DATA";

export const GET_TRANSACTION_DATA = "@@dashboard/GET_TRANSACTION_DATA"
export const GET_TRANSACTION_SUCCESS = "@@dashboard/GET_TRANSACTION_SUCCESS"
export const GET_TRANSACTION_ERROR = "@@dashboard/GET_TRANSACTION_ERROR"

export const DOWNLOAD_EXCEL_DATA = "@@dashboard/DOWNLOAD_EXCEL_DATA"
export const GET_DOWNLOAD_EXCEL_DATA_SUCCESS = "@@dashboard/GET_DOWNLOAD_EXCEL_DATA_SUCCESS"
export const GET_DOWNLOAD_EXCEL_DATA_ERROR = "@@dashboard/GET_DOWNLOAD_EXCEL_DATA_ERROR"
export const GET_CHECK_ALL_DATA_SUCCESS = "@@dashboard/GET_CHECK_ALL_DATA_SUCCESS"

export const RESET_DOWNLOAD_INDEX = "@@dashboard/RESET_DOWNLOAD_INDEX"
export const RESET_PAGE_INDEX = "@@dashboard/RESET_PAGE_INDEX"
export const CREATE_ALLOCATION="@@dashboard/CREATE_ALLOCATION"
export const CREATE_ALLOCATION_SUCCESS="@@dashboard/CREATE_ALLOCATION_SUCCESS"
export const CREATE_ALLOCATION_ERROR="@@dashboard/CREATE_ALLOCATION_ERROR"
export const SET_LOADER = "@@dashboard/SET_LOADER"
export const RESET_ALL_S2S = "@@dashboard/RESET_ALL_S2S"
export const UPDATE_STORE_S2S = "@@dashboard/UPDATE_STORE_S2S"
export const UPDATE_STORE_S2S_SUCCESS = "@@dashboard/UPDATE_STORE_S2S_SUCCESS"
export const UPDATE_STORE_S2S_ERROR = "@@dashboard/UPDATE_STORE_S2S_ERROR"

export const GET_CHECK_ALL_DATA_SUCCESS_S2S = "@@dashboard/GET_CHECK_ALL_DATA_SUCCESS_S2S"

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
});

export const fetchFilterData = (payload, filterType) => ({
  type: FETCH_FILTER_DATA,
  payload,
  filterType,
});

export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const getRecommendedActionstData = (payload) => ({
  type: GET_RECOMMENDED_ACTIONS_DATA,
  payload,
});

export const getRecommendedActionstDataSuccess = (payload) => ({
  type: GET_RECOMMENDED_ACTIONS_DATA_SUCCESS,
  payload,
});

export const getRecommendedActionstDataError = (payload) => ({
  type: GET_RECOMMENDED_ACTIONS_DATA_ERROR,
  payload,
});

export const getChartData = (payload) => ({
  type: GET_CHART_DATA,
  payload,
});


export const getChartDataSuccess = (payload) => ({
  type: GET_CHART_DATA_SUCCESS,
  payload,
});

export const getChartDataError = (payload) => ({
  type: GET_CHART_DATA_ERROR,
  payload,
});

export const getStyleInventoryData = (payload) => ({
  type: GET_STYLE_INVENTORY_DATA,
  payload,
});

export const getStyleInventoryDataSuccess = (payload) => ({
  type: GET_STYLE_INVENTORY_DATA_SUCCESS,
  payload,
});

export const getStyleInventoryDataError = (payload) => ({
  type: GET_STYLE_INVENTORY_DATA_ERROR,
  payload,
});

export const getStyleInvPopupData = (payload) => ({
  type: GET_STYLE_INV_POPUP_DATA,
  payload,
});

export const getStyleInvPopupDataSuccess = (payload) => ({
  type: GET_STYLE_INV_POPUP_DATA_SUCCESS,
  payload,
});

export const getStyleInvPopupDataError = (payload) => ({
  type: GET_STYLE_INV_POPUP_DATA_ERROR,
  payload,
});

export const clearChartData = (payload) => ({
  type: CLEAR_CHART_DATA,
  payload,
});

export const getTransactionDetails = (payload) => ({
  type: GET_TRANSACTION_DATA,
  payload,
})

export const getTransactionDetailsSuccess = (payload) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload,
})


export const getTransactionDetailsError = (payload) => ({
  type: GET_TRANSACTION_ERROR,
  payload,
})

export const getReviewRecommendData = (payload) => ({
  type: GET_REVIEW_RECOMMEND_DATA,
  payload
});

export const getReviewRecommendDataOnProgress = () => ({
  type: GET_REVIEW_RECOMMEND_DATA_PROGRESS,
})

export const getReviewRecommendDataSuccess = (payload) => ({
  type: GET_REVIEW_RECOMMEND_DATA_SUCCESS,
  payload
});

export const getReviewRecommendDataError = (payload) => ({
  type: GET_REVIEW_RECOMMEND_DATA_ERROR,
  payload
});

export const getDownloadExcelData = (payload) => ({
  type: DOWNLOAD_EXCEL_DATA,
  payload
});

export const resetDownloadIndex = () => ({
  type: RESET_DOWNLOAD_INDEX,
});

export const getCheckAllDataSuccess = (payload) => ({
  type: GET_CHECK_ALL_DATA_SUCCESS,
  payload
})

export const resetPageIndex = () => ({
  type: RESET_PAGE_INDEX
})

export const createAllocation = (payload,isSSE) => ({
  type: CREATE_ALLOCATION,
  payload,
  isSSE
});

export const createAllocationSuccess = (payload) => ({
  type: CREATE_ALLOCATION_SUCCESS,
  payload,
});

export const createAllocationError = (payload) => ({
  type: CREATE_ALLOCATION_ERROR,
  payload,
});

export const setLoaderToFalse = (payload) => ({
  type: SET_LOADER,
  payload
})

export const resetAllS2S = () => ({
  type: RESET_ALL_S2S
})

export const UpdateStoreS2S = (payload) => ({
  type: UPDATE_STORE_S2S,
  payload
})

export const UpdateStoreS2SSuccess = (payload) => ({
  type: UPDATE_STORE_S2S_SUCCESS,
  payload,
})

export const UpdateStoreS2SError = () => ({
  type: UPDATE_STORE_S2S_ERROR,
})

export const getCheckAllDataSuccessS2S = (payload) => ({
  type: GET_CHECK_ALL_DATA_SUCCESS_S2S,
  payload
})