import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../../../../routes/api";
import * as actionTypes from "./Actions/ActionTypes";
import { ERROR_MSG } from "../../../../../constants/globalConstant";
import {updateTransferUnitsForMultipleProductsSuccess,updateTransferUnitsForMultipleProductsError} from "./Actions/Actions"

function* fetchProductView(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getSTSProductView, payload);
    if (response.status) {
      yield put({
        type: actionTypes.GET_PRODUCT_VIEW_SUMMARY_SUCCESS,
        data: response?.data?.data
      });
    } else {
      yield put({
        type: actionTypes.GET_PRODUCT_VIEW_SUMMARY_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_VIEW_SUMMARY_ERROR, error: ERROR_MSG });
  }
}

function* fetchProductStoreView(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getSTSProductStoreView, payload);
    if (response.status) {
      const { data, size_data } = response?.data;
      yield put({
        type: actionTypes.GET_PRODUCT_STORE_VIEW_SUCCESS,
        data: { data, size_data },
      });
    } else {
      yield put({
        type: actionTypes.GET_PRODUCT_STORE_VIEW_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_STORE_VIEW_ERROR, error: ERROR_MSG });
  }
}

function* updateTransferUnitsForProductsWorker(action){
  const response = yield call(Api.updateTransferUnitsForStoreToStore, {...action.payload })
  try {
      if (response.status) {
          yield put(updateTransferUnitsForMultipleProductsSuccess({ 'allocationCode': response?.data?.data?.allocation_code, 'data': true }))
      }
      else {
          yield put(updateTransferUnitsForMultipleProductsError({ 'error': ERROR_MSG }));
      }
  }
  catch (error) {
      yield put(updateTransferUnitsForMultipleProductsError(error));
  }
   
}

function* fetchProductViewWatcher() {
  yield takeLatest(actionTypes.GET_PRODUCT_VIEW_SUMMARY, fetchProductView);
}

function* fetchProductStoreViewWatcher() {
  yield takeLatest(actionTypes.GET_PRODUCT_STORE_VIEW, fetchProductStoreView);
}

function* updateTransferUnitsForProductsWatcher() {
  yield takeLatest(actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS, updateTransferUnitsForProductsWorker)
}

export function* productViewSaga() {
  yield all([
    fetchProductViewWatcher(),
    fetchProductStoreViewWatcher(),
    updateTransferUnitsForProductsWatcher()
  ]);
}
