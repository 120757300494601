import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageLoader from "../../../../../components/Loader/PageLoader";
import { getStoreMapping } from "../Actions/CreateStoreTransferRuleActions";
import ReviewStoresTable from "./ReviewStoresTable";
import ExportFile from "../../../../../components/ExportFile/ExportFile";
import { formatExcelData } from "./Constants";

const ReviewStores = (props) => {
  const [initialSelectedRows, setInitialSelectedRows] = useState({});
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const {
    getStoreMapping,
    basicDetails,
    selectedStores,
    storeMappingLoading,
    storeMappingData,
    createRuleLoading,
    setRTInstance,
    id,
    storeTypes,
  } = props;

  useEffect(() => {
    let req = {
      store_groups: selectedStores,
      transfer_within: basicDetails?.transfer?.value,
      source_list: storeTypes
        .filter((store) => store.store_type == 1 || store.store_type == 2)
        .map((store) => store.store_code),
      dest_list: storeTypes
        .filter((store) => store.store_type == 1 || store.store_type == 3)
        .map((store) => store.store_code),
    };
    basicDetails?.distance && (req["distance"] = basicDetails?.distance);
    id && (req["rule_id"] = id);
    getStoreMapping(req);
  }, [basicDetails]);

  useEffect(() => {
    if (storeMappingData?.length && !storeMappingLoading && id) {
      let selectedRows = {};
      storeMappingData.forEach((store, index) => {
        selectedRows[index] = store?.exists_flag;
      });
      setInitialSelectedRows(selectedRows);
    }
  }, [storeMappingData]);

  const generateExcel = () => {
    setExcelData(formatExcelData(storeMappingData));
    setDownloadExcel(true);
  };

  return (
    <PageLoader
      loader={storeMappingLoading || createRuleLoading}
      gridLoader={true}
    >
      {storeMappingData?.length && (
        <div className="d-flex justify-content-end mr-4 mb-2">
          <ExportFile
            downloadExcelData={downloadExcel}
            callFunc={generateExcel}
            text={"Excel"}
            data={excelData}
            fileName={`store_destination_mapping${new Date().getTime()}`}
            type={1}
            // formateXLSX={true}
          />
        </div>
      )}
      <ReviewStoresTable
        data={storeMappingData}
        setRTInstance={setRTInstance}
        initialSelectedRows={initialSelectedRows}
      />
      {id && storeMappingData?.length ? (
        <div className="mt-3 d-flex align-items-center">
          <div className="indicate-div bg-success"></div>
          <span className="ml-2 font-italic mb-0"> Newly Added Mapping ( Review new combinations )</span>
        </div>
      ) : (
        ""
      )}
    </PageLoader>
  );
};

const mapStateToProps = ({ createRule }) => ({
  basicDetails: createRule.basicDetails,
  selectedStores: createRule.selectedStores,
  storeMappingLoading: createRule.storeMappingLoading,
  storeMappingData: createRule.storeMapping,
});

const mapDispatchToProps = (dispatch) => ({
  getStoreMapping: (payload) => dispatch(getStoreMapping(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStores);
