import React, { useState, useEffect } from "react";
import Store2StoreFilters from "./Store2StoreFilters";
import { connect } from "react-redux";
import { isEmpty, } from "lodash";
import StoreTransferReview from "../Dashboard/RecommendedAction/StoreTransferReview/StoreTransferReview";
import {
  getReviewRecommendData,
  resetPageIndex,
} from "../Dashboard/DashboardActions";
import { cloneDeep } from "lodash";
import "./S2S.css";

const Store2StoreContainer = (props) => {
  const [filters, setFilters] = useState({});
  const [storeTransferDataCall, setStoreTransferDataCall] = useState(false);
  const [tableDataResetFlag, setTableDataResetFlag] = useState(false);
  const [tabledData, setTableData] = useState([]);
  const [rtInstanceData, setRTInstanceData] = useState(false);
  const [storeTableInstance, setStoreTableInstance] = useState({});
  const [initialIndex, setInitialIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  const [isFirstCall, setisFirstCall] = useState(true);
  const [totalCount, setTotalCount] = useState(0);


  let selectedFilters = {};
  useEffect(() => {
    setTableData([]);
    // setSearchTermReq({});
    // setSortReq({});
    // setStoreTableInstance(null);
    setInitialIndex(0);
    setPageIndex(0);
    setPrevIndex(0);
    setTotalCount(0);
    setNextIndex(0);
    props.resetPageIndex();
    // setShowTable(false)
    // setInitialSelectedRows({})
    // setCheckAll([]) //check here for this logic
    // setCheckAllReqArr([])
    // setPrevAction("");
    // setSelectedRows([]);
    // setTableDataAdditionFlag(false);
  }, [filters]);

  useEffect(() => {
    if (props.reviewRecommendedData) {
      if (storeTableInstance?.data?.length) {
        setTableData([
          ...storeTableInstance?.data,
          ...props.reviewRecommendedData,
        ]);
      } else {
        setTableData(props.reviewRecommendedData);
      }
    }
  }, [props.reviewRecommendedData]);

  useEffect(() => {
    setPageIndex(props.pageIndex);
  }, [props.pageIndex]);

  useEffect(() => {
    if (
      !isEmpty(searchTermReq) ||
      !isFirstCall ||
      (!isEmpty(sortReq) && !isEmpty(filters))
    ) {
      setTableData([]);
      setStoreTableInstance(null);
      setInitialIndex(0);
      setPageIndex(0);
      setPrevIndex(0);
      setNextIndex(0);
      props.resetPageIndex();


      props.getReviewRecommendData({
        ...filters,
        store_transfer: true,
        row_index: 0,
        searchColumns: searchTermReq,
        sortColumn: sortReq,
      });
    }
  }, [searchTermReq, sortReq]);

  useEffect(() => {
    // if (props.nextIndex == 100 && !reviewCall) {
    //   setPrevIndex(0);
    //   // setInitialIndex(0)
    // }
    setTotalCount(props.recDatatotalCount);
    setNextIndex(props.recDatanextIndex);
  }, [props.recDatanextIndex, props.recDatatotalCount]);

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) {
      isFirstCall && setisFirstCall(false);
    }
    setSortReq(p_sort[0]);
  };

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if (split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",");
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        var id = p_term.split(split_type ? split_type : " ");
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            values: id,
            search: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        let min = p_term[0] == "" ? null : p_term[0];
        let max = p_term[1] == "" ? null : p_term[1];
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: {
              min: min,
              max: max,
            },
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        //For Transfer rule send different accessor of rule_name
        let p_id_value = p_id == "rule_id" ? "rule_name" : p_id;
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id_value] && delete l_searchTermReq[p_id_value];
        } else {
          l_searchTermReq[p_id_value] = {
            type: p_type,
            value: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    }
    isFirstCall && setisFirstCall(false);
  };

  const getStore2StoreData = (filters) => {
    let reqFilters = {};
    for (let key in filters) {
      if (filters[key]) {
        filters[key] && (reqFilters[key] = filters[key]);
      }
    }
    setSearchTermReq({});
    setSortReq({});
    setInitialIndex(0);
    setPrevIndex(0);
    setFilters(reqFilters);
    setStoreTransferDataCall(true);
    setTableData([]);
    if (reqFilters) {
      selectedFilters = reqFilters;
    }
    props.getReviewRecommendData({
      ...selectedFilters,
      row_index: 0,
      row_count: 100,
    });
  };

  const updateArticleData = (setAllRows, transferStrategy) => {
    setTableData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach((row) => {
        // demandType && (newData[row]["demand_type"] = demandType);
        transferStrategy &&
          (newData[row]["transfer_strategy"] = transferStrategy);
        // userDefinedFixedInv && demandType === "Fixed" && (newData[row]["fixed_percentage"] = userDefinedFixedInv);
        // userDefinedFixedInv && demandType === "Fixed" && (newData[row]["fixed_units"] = "");
        // userDefinedFixedInv && (newData[row]["final_total_inventory"] = Math.round((newData[row]["store_inv"] * (userDefinedFixedInv / 100))));
      });

      return newData;
    });
  };


  const fetchData = (index) => {
    if (prevIndex >= index || index < pageIndex || props?.out_of_data || props.loading) return;

    setPrevIndex(index);
    setInitialIndex(
      Math.floor(pageIndex / 10) <= 0 ? 0 : Math.floor(pageIndex / 10 - 1)
    );
    // setTableDataAdditionFlag(true);
    props.getReviewRecommendData({
      ...filters,
      row_index: nextIndex,
      searchColumns: searchTermReq,
      sortColumn: sortReq,
    });
  };

  const updateStoresForRuleChange = (updatedStoresData,{article,transfer_rule})=> {
    setTableData((old) => {
      return old.map((item,index) => {
        if (item.article == article) {
          const createOptions = (list) => {
            return list.map((item) => ({
              label: item,
              value: item,
            }));
          };
          return {
            ...item,
            store_value: [...createOptions(updatedStoresData)],
            store_options:[...createOptions(updatedStoresData)],
            rule_id:transfer_rule,
          };
        }
        return item;
      });
    })
  }

  const resetFilters = () => {
    setTableData([]);
  }
  return (
    <div className="store2store_container">
      <div className="container__header">
        <h1 className="fnt-lg fnt-bold">Create Store to Store Allocation</h1>
      </div>
      <div className="filter">
        <Store2StoreFilters
          setFilters={setFilters}
          setStoreTransferDataCall={setStoreTransferDataCall}
          getStore2StoreData={getStore2StoreData}
          resetFilters={resetFilters}
        />
      </div>
      <div className="row pl-4">
        <div className="required fnt-md pl-4" style={{ color: "red" }}>
          <label></label>Fields are mandatory
        </div>
      </div>
      <div>
        {storeTransferDataCall && (
          <StoreTransferReview
            reviewCall
            data={tabledData}
            filters={filters}
            reviewRequest={filters}
            storeTableInstance={storeTableInstance}
            setStoreTableInstance={setStoreTableInstance}
            initialIndex={initialIndex}
            updateArticleData={updateArticleData}
            fetchData={fetchData}
            prevIndex={prevIndex}
            pageIndex={pageIndex}
            searchTermReq={searchTermReq}
            sortReq={sortReq}
            fecthDataWithSorting={fecthDataWithSorting}
            fecthDataWithSearchTerm={fecthDataWithSearchTerm}
            updateStoresForRuleChange={updateStoresForRuleChange}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  loading: dashboard.reviewRecommendLoading,
  reviewRecommendedData: dashboard.reviewRecommendedData,
  pageIndex: dashboard.reviewPageIndex,
  out_of_data: dashboard.reviewRecommendOutofData,
  recDatatotalCount: dashboard.recDatatotalCount,
  recDatanextIndex: dashboard.recDatanextIndex,
});

const mapDispatchToProps = (dispatch) => ({
  getReviewRecommendData: (payload) =>
    dispatch(getReviewRecommendData(payload)),
  resetPageIndex: () => dispatch(resetPageIndex()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store2StoreContainer);
