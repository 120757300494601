import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";

import {
  getStoretoStoreFilterData,
  getStoretoStoreTableData,
  updateStoretoStoreConfig,
} from "../../../../routes/api";
import { isEmpty, map } from "lodash";
import {
  dateFormat,
  flattenArray,
  isObject,
} from "../../../../utils/commonUtilities";
import moment from "moment";
import { ERROR_MSG } from "../../../../constants/globalConstant";

import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  UPDATE_SAVE_CONFIG_GRID_SUCCESS,
  UPDATE_SAVE_CONFIG_GRID_ERROR,
  UPDATE_SAVE_CONFIG_GRID_EDIT,
  FETCH_USER_CREATED_DATA_STORE,
  fetchUserCreatedDataSuccessStoreToStore,
  fetchUserCreatedDataErrorStoreToStore,
} from "./StoreTransferConfigurationAction";

const options = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Deactivated",
    value: "Deactivated",
  },
  {
    label: "Paused",
    value: "Paused",
  },
];

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchUserCreatedDataWatcher() {
  yield takeLatest(
    FETCH_USER_CREATED_DATA_STORE,
    fetchUserCreatedDataWorkerStore
  );
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          req[key] = flattenArray(payload[key]).map((ele) => ele.value);
        }
      }
    }
    if (filterType) {
      req["filter_type"] = filterType;
    }
    const res = yield call(getStoretoStoreFilterData, req);
    // console.log("ss", res);
    if (res.data.status) {
      // console.log("ss1");
      const data = {};
      data["filterMapping"] = res.data?.filterMapping;
      // let topObject = Object.keys(res.data.data[0])[0]
      // if(filterType != "style"){
      for (const key in res.data.data[0]) {
        let k = "";
        if (key === "l1_name") {
          k = "level1Options";
        } else if (key === "l2_name") {
          k = "level2Options";
        } else if (key === "l3_name") {
          k = "level3Options";
        } else if (key === "l4_name") {
          k = "level4Options";
        } else if (key === "l5_name") {
          k = "level5Options";
        } else if (key === "l6_name") {
          k = "level6Options";
        } else if (key === "l7_name") {
          k = "level7Options";
        } else if (key === "style_name") {
          k = "styleOptions";
        } else if (key === "color") {
          k = "colourOptions";
        } else if (key === "country") {
          k = "countryOptions";
        }
        // if(key==="color" || key === "style_name") {
        //     data[k] = res.data.data.map((element) => ({
        //         value: element[key],
        //         label: element[key],
        //       }));
        // }
        // else{
        data[k] = res.data.data[0][key]
          .filter((element) => element)
          .map((element) => ({
            value: element,
            label: element,
          }));
        // }
      }
      // }
      // else{
      //     data["styleOptions"] = res.data.data
      // }
      // console.log("ss2");
      yield put(
        fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key })
      );
    } else {
      // console.log("ss3");
      yield put(fetchFilterDataError({ error: res.error }));
    }
  } catch (error) {
    // console.log(error, "ss4");
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* fetchUserCreatedDataWorkerStore(action) {
  try {
    let l_payload = action.payload;
    let arrayProperty = {};
    for (var key in l_payload) {
      if (Array.isArray(l_payload[key])) {
        if (!l_payload[key]?.length) {
          delete arrayProperty[key];
        } else {
          arrayProperty[key] = l_payload[key].map((ele) => ele.value);
        }
      } else if (
        isObject(l_payload[key]) &&
        !["searchColumns", "sortColumn"].includes(key)
      ) {
        arrayProperty[key] = [l_payload?.[key]?.value];
      }
    } 
    let req_body = {
      l1_name: [l_payload?.level1?.value],
    };

    if (l_payload?.page) {
      req_body["page"] = l_payload?.page;
    }

    if (arrayProperty["level2"]?.length > 0) {
      req_body["l2_name"] = arrayProperty["level2"];
    }

    if (arrayProperty["level3"]?.length > 0) {
      req_body["l3_name"] = arrayProperty["level3"];
    }

    if (arrayProperty["level4"]?.length > 0) {
      req_body["l4_name"] = arrayProperty["level4"];
    }

    if (arrayProperty["level5"]?.length > 0) {
      req_body["l5_name"] = arrayProperty["level5"];
    }

    if (arrayProperty["level6"]?.length > 0) {
      req_body["l6_name"] = arrayProperty["level6"];
    }

    if (arrayProperty["level7"]?.length > 0) {
      req_body["l7_name"] = arrayProperty["level7"];
    }

    if (arrayProperty["colour"]?.length > 0) {
      req_body["color"] = arrayProperty["colour"];
    }

    if (arrayProperty["style_name"]?.length > 0) {
      req_body["style_name"] = arrayProperty["style_name"];
    }
    req_body["row_index"] = l_payload?.row_index || 0;
    req_body["row_count"] = l_payload?.row_count || 1000;
    req_body["searchColumns"] = l_payload?.searchColumns;
    req_body["sortColumn"] = l_payload?.sortColumn;

    const response = yield call(getStoretoStoreTableData, req_body);

    let responseWithSalesAttribute;
    if (response.status) {
      responseWithSalesAttribute = response.data.data;
      responseWithSalesAttribute = responseWithSalesAttribute.map((val) => {
        // console.log("val here is ", val);
        return {
          ...val,
          options: options,
          transfer_strategy_options: response?.data?.transfer_strategy_options,
        };
      });
    }

    // console.log(
    //   "the value of the response withSalesAttribute",
    //   responseWithSalesAttribute
    // );
    let resp = {
      data: responseWithSalesAttribute,
      status: { ...response.status },
      error: { ...response.error },
    };

    if (response.status) {
      yield put(
        fetchUserCreatedDataSuccessStoreToStore({
          data: resp.data,
          totalCount: response.data.totalCount,
          nextIndex: response.data.row_index,
          transfer_strategy_options: response?.data?.transfer_strategy_options,
        })
      );
    } else {
      yield put(fetchUserCreatedDataErrorStoreToStore({ error: resp.error }));
    }
  } catch (error) {
    yield put(fetchUserCreatedDataErrorStoreToStore(error));
  }
}

function* updateAutoAllocationWatcher() {
  yield takeLatest(
    UPDATE_SAVE_CONFIG_GRID_EDIT,
    updateAutoAllocationRequiredWorker
  );
}
function* updateAutoAllocationRequiredWorker(action) {
  try {
    const { payload } = action;
    let req = { ...payload };
    // for (const key in payload) {
    //     if (Array.isArray(payload[key]) && payload[key] !== "auto_allocation_status_inline") {
    //         if(!payload[key]?.length) {
    //             delete req[key]
    //         }
    //         else {
    //             req[key] = payload[key].map((ele) => ele?.value || ele)
    //         }
    //     }
    //     else if (isObject(payload[key]) && !isEmpty(payload[key]) && key != "searchColumns" && key != "sortColumn") {
    //         req[key] = [payload?.[key]?.value]
    //     }
    // }
    // req = { ...req, "updated_by": localStorage?.getItem("email")}
    const res = yield call(updateStoretoStoreConfig, req);

    if (res?.data.status) {
      let data = res.data;
      yield put({ type: UPDATE_SAVE_CONFIG_GRID_SUCCESS, data: data });
    } else {
      yield put({
        type: UPDATE_SAVE_CONFIG_GRID_ERROR,
        error: "Something went wrong!",
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_SAVE_CONFIG_GRID_ERROR,
      error: "Something went wrong!",
    });
  }
}

export function* StoreTransferConfigurationSaga() {
  yield all([
    fetchFilterDataWatcher(),
    fetchUserCreatedDataWatcher(),
    updateAutoAllocationWatcher(),
  ]);
}
