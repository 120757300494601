import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import PageLoader from "../../../../components/Loader/PageLoader";

import ViewAllocationNewStore from "./ViewAllocationNewStore.jsx"

const NewStoreAllocations = (props) => {

    const { loading, data, error, store, setDetailedView, isDetailedView } = props

    const [viewData, setData] = useState([])

    useEffect(() => {
        let allocations = [];
        data.forEach(item => {
            allocations.push({...item, article: item.articles_str, inventory_source: item.source})
        })
        setData(allocations)
    }, [data])

    return (
        <div>
            {
                !isDetailedView &&
                <>
                    <h3 className="fnt-bold m-0 p-3">Allocations (store - {store}) </h3>
                    <hr className="m-0" />
                </>
            }
            <section className="sectionm-0">
                <PageLoader loader={loading} gridLoader={true}>
                    {(error) ? (<div className="error">{error}</div>) :
                        (data ? (
                            <ViewAllocationNewStore data={viewData} isNewStore showDetailedView={setDetailedView} store={store} />
                        ) : null)}
                </PageLoader>
            </section>
        </div>
    )
}

export default NewStoreAllocations
