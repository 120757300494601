import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../../../routes/api";
import * as actionTypes from "./Actions/ActionTypes";
import { ERROR_MSG } from "../../../../constants/globalConstant";
import moment from "moment";

function* getStoreTransferRules(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getStoreTransferRules, payload);
    if (response.status) {
      let data = response?.data?.data;
      data.forEach(item => {
        item.updated_at = item.updated_at ? moment(item.updated_at).format("MM-DD-YYYY HH:mm:ss") : ""
      })

      yield put({
        type: actionTypes.GET_STORE_TRANSFER_RULES_SUCCESS,
        data
      });
    } else {
      yield put({
        type: actionTypes.GET_STORE_TRANSFER_RULES_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STORE_TRANSFER_RULES_ERROR, error: ERROR_MSG });
  }
}

function* deleteStoreTransferRule(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.deleteStoreTransferRule, payload);
    if (response.status) {
      yield put({
        type: actionTypes.DELETE_STORE_TRANSFER_RULE_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_STORE_TRANSFER_RULE_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.DELETE_STORE_TRANSFER_RULE_ERROR, error: ERROR_MSG });
  }
}

function* getRuleMapping(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getRuleMapping, payload);
    if (response.status) {
      yield put({
        type: actionTypes.GET_RULE_MAPPING_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_RULE_MAPPING_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_RULE_MAPPING_ERROR, error: ERROR_MSG });
  }
}


function* getStoreTransferRulesWatcherSaga() {
  yield takeLatest(actionTypes.GET_STORE_TRANSFER_RULES, getStoreTransferRules);
}

function* deleteStoreTransferRuleWatcherSaga() {
  yield takeLatest(actionTypes.DELETE_STORE_TRANSFER_RULE, deleteStoreTransferRule);
}

function* getRuleMappingWatcher() {
  yield takeLatest(actionTypes.GET_RULE_MAPPING, getRuleMapping);
}

export function* storeTransferRulesSaga() {
  yield all([
    getStoreTransferRulesWatcherSaga(),
    deleteStoreTransferRuleWatcherSaga(),
    getRuleMappingWatcher()
  ]);
}
