import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";
import * as actionTypes from "../Actions/ActionTypes";

export default {
  stsProductView: handleActions(
    {
      [actionTypes.GET_PRODUCT_VIEW_SUMMARY]: (state, payload) => {
        return immutable(state, {
          productSummaryData: { $set: [] },
          productSummaryLoading: { $set: true },
          productSummaryError: { $set: null },
          productSummarySuccess: { $set: false },
          productStoreData: { $set: [] },
          productStoreLoading: { $set: false },
          productStoreError: { $set: null },
          productStoreSuccess: { $set: false },
        });
      },
      [actionTypes.GET_PRODUCT_VIEW_SUMMARY_SUCCESS]: (state, payload) => {
        return immutable(state, {
          productSummaryData: { $set: payload?.data },
          productSummaryLoading: { $set: false },
          productSummaryError: { $set: null },
          productSummarySuccess: { $set: true },
        });
      },
      [actionTypes.GET_PRODUCT_VIEW_SUMMARY_ERROR]: (state, payload) => {
        return immutable(state, {
          productSummaryData: { $set: [] },
          productSummaryLoading: { $set: false },
          productSummaryError: { $set: (payload && payload.error) || null },
          productSummarySuccess: { $set: false },
        });
      },
      [actionTypes.GET_PRODUCT_STORE_VIEW]: (state, payload) => {
        return immutable(state, {
          productStoreData: { $set: [] },
          productStoreLoading: { $set: true },
          productStoreError: { $set: null },
          productStoreSuccess: { $set: false },
        });
      },
      [actionTypes.GET_PRODUCT_STORE_VIEW_SUCCESS]: (state, payload) => {
        return immutable(state, {
          productStoreData: { $set: payload?.data },
          productStoreLoading: { $set: false },
          productStoreError: { $set: null },
          productStoreSuccess: { $set: true },
        });
      },
      [actionTypes.GET_PRODUCT_STORE_VIEW_ERROR]: (state, payload) => {
        return immutable(state, {
          productStoreData: { $set: [] },
          productStoreLoading: { $set: false },
          productStoreError: { $set: (payload && payload.error) || null },
          productStoreSuccess: { $set: false },
        });
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS]: (state) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: true },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: false },
        });
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
            alloacationCode: { $set: (payload && payload.allocationCode) || state.allocationCode},
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: (payload && payload.data) || false },
            updateTransferUnitsError: { $set: false },
        })
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_ERROR]: (state, { payload }) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: true },
        })
      },
      [actionTypes.RESET_ALL]: (state) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: false },
        })
      },
    },
    initialState
  ),
};
