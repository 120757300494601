import React, { useState, useEffect } from "react";
import DashboardFilters from "../../Dashboard/DashboardFilters";
import { connect } from "react-redux";
import { getAllocationPlans, resetAll } from "../ViewAllocationActions";
import { clearChartData } from "../../Dashboard/DashboardActions";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";
import ColumnFilter from "../../UserManagement/ColumnFilter.jsx";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { ERROR_MSG } from "../../../constants/globalConstant";
import PageLoader from "../../../components/Loader/PageLoader.jsx";
import STSFinalize from "../../ProductStoreStatus/StoreToStoreConfig/StoreToStoreFinalize/STSFinalize.jsx";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const ViewAllocationS2S = (props) => {
  const {
    store,
    setDetailedView,
    setSelectedAllocation,
    isBackClicked,
    setIsBackclicked,
  } = props;

  const [selectedFilters, setSelectedFilters] = useState({});
  const [allocationPlans, setAllocationPlans] = useState([]);
  const [filterReq, setFilterReq] = useState({});
  // const [isBackClicked, setIsBackclicked] = useState(false);
  // const [detailedView, setDetailedView] = useState(false);
  const [RTinstance, setRTinstance] = useState({});
  const [initialIndex, setInitialIndex] = useState(0);
  // const [selectedAllocation, setSelectedAllocation] = useState({});


  const handleDetailedViewClick = (row) => {
    // isNewStore && showDetailedView(true)
    // setSelectedPlan(row)
    // setAllocationCode(row.plan_id)
    setSelectedAllocation({
      ...row,
      allocationCode: row.plan_id,
      allocationName: row.plan_description,
    });
    setDetailedView(true);
  };

  const allocationPlansColumns = React.useMemo(() => [
    {
      Header: "Plan Name",
      accessor: "plan_description",
      Cell: (instance) => <ReactTollTip {...instance} />,
      Filter: ColumnFilter,
      width: 250,
    },
    // {
    //     Header: 'Pack ID',
    //     accessor: 'packs',
    //     Cell: (instance) => <ReactTollTip {...instance} />,
    //     Filter: ColumnFilter,
    //     width: 250,
    // },
    {
      Header: "Style Color ID",
      accessor: "article",
      Cell: (instance) => <ReactTollTip {...instance} />,
      Filter: ColumnFilter,
      width: 250,
    },
    {
      Header: "PO ID",
      accessor: "po_number",
      Filter: ColumnFilter,
    },
    {
      Header: "ASN ID",
      accessor: "asn",
      Filter: ColumnFilter,
    },
    {
      Header: "Inventory",
      accessor: "inventory_source",
      Filter: ColumnFilter,
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Filter: ColumnFilter,
      minWidth: 300,
    },
    {
      Header: "Released By",
      accessor: "released_by",
      Filter: ColumnFilter,
      width: 250,
    },
    {
      Header: "Creation Date",
      accessor: "creation_date",
      Filter: ColumnFilter,
      sortType: (rowA, rowB) =>
        new Date(rowA?.original?.creation_date) >
        new Date(rowB?.original?.creation_date),
    },
    {
      Header: "Released Date",
      accessor: "released_date_picked",
      Filter: ColumnFilter,
      sortType: (rowA, rowB) => {
        if (
          new Date(rowA?.original?.released_date_picked) >
          new Date(rowB?.original?.released_date_picked)
        )
          return 1;

        if (
          new Date(rowB?.original?.released_date_picked) >
          new Date(rowA?.original?.released_date_picked)
        )
          return -1;
      },
    },
    {
      Header: "Creation Time",
      accessor: "creation_time",
      Filter: ColumnFilter,
      sortType: "basic",
    },
    {
      Header: "Released Time",
      accessor: "released_time",
      Filter: ColumnFilter,
      sortType: "basic",
    },
    {
      Header: "# Styles Allocated",
      accessor: "style_count",
      Filter: ColumnFilter,
    },
    {
      Header: "# Stores Allocated",
      accessor: "source_count",
      id: "store_cnt",
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      accessor: "actions",
      sticky: "right",
      Cell: (instance) => (
        <div>
          <button
            className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
            onClick={() => {
              handleDetailedViewClick(instance.row?.original);
            }}
          >
            Detailed View
            <i
              className="fa fa-angle-right ml-2"
              title="Configure"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      ),
      // width: 150,
      disableFilters: true,
    },
  ]);

  const getFilters = (filters) => {
    setSelectedFilters(filters);
  };

  const resetFilters = () => {
    setAllocationPlans([]);
  };

  const getAllocationPlans = (req) => {
    setFilterReq(req);
    props.getAllocationPlans(req);
  };

  const backFromDetailedView = (isDetailedView) => {
    // isNewStore && showDetailedView(false)
    setDetailedView(isDetailedView);
    setIsBackclicked(true);
  };

  useEffect(() => {
    // if (props.allocationPlans?.length > 0) {
    //     if (RTinstance && RTinstance.data) {
    //         setAllocationPlans([...RTinstance.data, ...props.allocationPlans]);
    //     }
    //     else {
    setAllocationPlans(props.allocationPlans);
    //     }
    // }
  }, [props.allocationPlans]);

  return (
    <>
      {
        <div className={`container__wrapper`}>
          {/* <Notification /> */}
          {
            <>
              <div className="container__header__with__button config-header">
                <h1 className="fnt-lg fnt-bold">
                  View Past Store to Store Allocations
                </h1>
              </div>
              <div className="filter row">
                <DashboardFilters
                  viewAllocationFilters={selectedFilters}
                  resetFilters={resetFilters}
                  isViewAllocation={true}
                  getAllocations={getAllocationPlans}
                  getFilters={getFilters}
                  isBackClicked={isBackClicked}
                  setIsBackclicked={setIsBackclicked}
                  isS2SviewAllocation={true}
                />
                <div style={{ marginTop: "3rem" }} className="col-md-3">
                  <div
                    className="required fnt-md pt-2"
                    style={{ color: "red" }}
                  >
                    <label></label>Fields are mandatory
                  </div>
                </div>
              </div>
            </>
          }
          <div className="view_allocation__tables">
            <div className="view_allocation__table">
              <PageLoader loader={props.allocationPlansLoading}>
                {props.allocationPlansError ? (
                  <div className="error">{ERROR_MSG}</div>
                ) : (
                  <ReactTableWithPlugins
                    shouldPagination
                    sortBy={[
                      { id: "creation_date", desc: true },
                      { id: "creation_time", desc: true },
                    ]}
                    totalRecordsLen={
                      allocationPlans ? allocationPlans.length : 0
                    }
                    data={allocationPlans ? allocationPlans : []}
                    columns={allocationPlansColumns}
                    renderMarkup="TableMarkup"
                    keyRT="sortAndSearch"
                    tableId="view_past_allocations_s2s"
                    // fetchData={(data) => fetchData(data)}
                    initialPageIndex={initialIndex}
                    getInstanceOnMount={(instance) => {
                      setRTinstance(instance);
                    }}
                    tableWrapperStyle={{
                      height: "fit-content",
                      maxHeight: "50rem",
                    }}
                    headerWrapperStyle={{
                      position: "sticky",
                      top: 0,
                      zIndex: "4",
                    }}
                    // getManualSortByOptions={(instance) => {
                    //     fecthDataWithSorting(instance)
                    // }}
                    // manualSortBy={true}
                  />
                )}
              </PageLoader>
            </div>
          </div>
        </div>
      }
      {/* {detailedView && (
        console.log("Rendering Finalize component with s2s:", {
          detailedView,
          selectedAllocation,
          store
        }),
        <STSFinalize
          planDetails={selectedAllocation}
          goBack={backFromDetailedView}
          store={store}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({ viewAllocation }) => {
  return {
    allocationPlansLoading: viewAllocation.allocationPlansLoading,
    allocationPlans: viewAllocation.allocationPlans,
    allocationPlansError: viewAllocation.allocationPlansError,
    styleIndex: viewAllocation.style_index,
    outofData: viewAllocation.out_of_data,
    pageIndex: viewAllocation.pageIndex,
    nextIndex: viewAllocation.nextIndex,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllocationPlans: (payload) => dispatch(getAllocationPlans(payload)),
  resetAll: () => dispatch(resetAll()),
  clearChartData: (payload) => dispatch(clearChartData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllocationS2S);
