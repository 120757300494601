import React from "react";

const EmbededPaginationMarkup = ({
  flatRows,
  totalCount,
  containSubRow,
  groupedRows
}) => {
  return (
    <div className="pagination d-flex justify-content-between mt-3">
          <span>
            Total Records {" "}
            {totalCount || (containSubRow ? groupedRows?.length : flatRows?.length)}
          </span>
    </div>
  );
};

export default EmbededPaginationMarkup;
