import React, { useState, useEffect } from "react";
import {useHistory} from "react-router";
import ColumnFilterServerSide from "../../../../components/Filters/ColumnFilterServerSide";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import {
  levelFiltersLabelFormatter,
  updateArticleName,
} from "../../../../utils/filterLevelMapping";
import NumberRangeColumnFilterServerSide from "../../../../components/Filters/NumberRangeColumnFilterServerSide";
import PageLoader from "../../../../components/Loader/PageLoader";
import { connect } from "react-redux";
import { getReviewRecommendData, getStyleInventoryData ,createAllocation,resetPageIndex,setLoaderToFalse,resetAllS2S,UpdateStoreS2S} from "../../DashboardActions";
import MultiSelectCell from "../../../../components/Table/Cellrenderer/MultiSelectCell";
import SelectCell from "../../../../components/Table/Cellrenderer/SelectCell"
import SelectCellComponent from "../../../../components/Table/Cellrenderer/SelectCellComponent";
import StrategyCSS from "../../../Strategy/Strategy.module.scss";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";
import { DEMAND_TYPE_OPTIONS , TRANSFER_STRATEGY_OPTIONS} from "./StoreTransferReviewConstants";
import * as Notify from "../../../../components/Notification/Notifications"
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { removeCheckedArticles,getNewData } from "../../../../utils/paginationHelper";
import {isUseSSE,reloadPageWithUpdateToken} from "../../../../utils/commonUtilities";
import {SSE_CREATE_ALLOCATON} from "../../../../constants/apiConstants";
import {STORE_TO_STORE_FINALIZE} from "../../../../constants/routeConstants";
import { ERROR_MSG } from "../../../../constants/globalConstant";
import { getFinalCheckedRowsForCAData } from "../../../../utils/paginationHelper";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreTransferReview = (props) => {
  const {initialIndex,setInitialIndex,updateArticleData,fetchData,prevIndex,pageIndex,nextIndex,fecthDataWithSearchTerm,fecthDataWithSorting,searchTermReq,sortReq,totalCount} = props;

  const history = useHistory();
  const { data, loading , reviewCall } = props;
  const [tableData, setTableData] = useState([]);
  const [RTInstance, setRTInstance] = useState({});
  const [showSetAll, setShowSetAll] = useState(false);
  const [userDefinedFixedInv,setUserDefinedFixedInv] = useState(null);
  const [fixedInvDisabled,setFixedInvDisabled] = useState(true);
  const [demandType,setDemandType] = useState(null);
  const [transferStrategy,setTransferStrategy]=useState(null);
  const [checkAll, setCheckAll] = useState([]);
  const [prevAction, setPrevAction] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [transferStrategyOptions,setTransferStrategyOptions] = useState([]);
  const [selectedRows,setSelectedRows] = useState([]);
  const [hideTotalRecords, setHideTotalRecords] = useState(true);
  const [hideTotalPages, setHideTotalPages] = useState(true);
  const [useSSE, setUseSSE] = useState(false);
  const [timeoutLoader,setTimeoutLoader] = useState(false);
  const [changedStoresId,setChangedStoresId] = useState(null);
  const [initialSelectedRows, setInitialSelectedRows] = useState({});


  //new state variable created for select all and deselect all functionality 
  const [checkAllReqArr, setCheckAllReqArr] = useState([]);
  const [isGetAllLoading, setIsGetAllLoading] = useState(false);
  const [checkAllData, setCheckAllData] = useState([]);




  let eventSource;

  const COLUMNS = [
    {
      Header: `${updateArticleName(false)} ID`,
      accessor: "article",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.article?.search}
          placeholder={"Search in bulk by ','"}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "array", ",")
          }
        />
      ),
      // sticky: "left"
    },
    {
      Header: `Style ID`,
      accessor: "styleid",
      // Filter: (instance) => (
      //   <ColumnFilterServerSide
      //     {...instance}
      //     searchTermValue={searchTermReq?.styleid?.value}
      //     changeHandler={(term, id) =>
      //       fecthDataWithSearchTerm(term, id, "string")
      //     }
      //   />
      // ),
      disableFilters: true,
    },
    {
      Header: `Color ID`,
      accessor: "colorid",
      // Filter: (instance) => (
      //   <ColumnFilterServerSide
      //     {...instance}
      //     searchTermValue={searchTermReq?.colorid?.value}
      //     changeHandler={(term, id) =>
      //       fecthDataWithSearchTerm(term, id, "string")
      //     }
      //   />
      // ),
      disableFilters: true,
    },
    {
      Header: `Style Description`,
      accessor: "style_desc",
      // Filter: (instance) => (
      //   <ColumnFilterServerSide
      //     {...instance}
      //     searchTermValue={searchTermReq?.style_desc?.value}
      //     changeHandler={(term, id) =>
      //       fecthDataWithSearchTerm(term, id, "string")
      //     }
      //   />
      // ),
      width:300,
      disableFilters: true,
    },
    {
      Header: levelFiltersLabelFormatter("level1"),
      accessor: "l1_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l1_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: levelFiltersLabelFormatter("level2"),
      accessor: "l2_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l2_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
      minWidth:200,
    },
    {
      Header: levelFiltersLabelFormatter("level3"),
      accessor: "l3_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l3_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: levelFiltersLabelFormatter("level4"),
      accessor: "l4_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l4_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: levelFiltersLabelFormatter("level5"),
      accessor: "l5_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l5_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
      minWidth:250,
    },
    {
      Header: levelFiltersLabelFormatter("level6"),
      accessor: "l6_name",
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.l6_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "Sizes",
      accessor: "size_value",
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            selectAll={true}
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.size_options}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let sizes = values?.map(val => val?.value);
            }}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Stores",
      accessor: "store_value",
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            selectAll={true}
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.store_options}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let sizes = values?.map((val) => val?.value);
            }}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
    // {
    //   Header: "Demand Type",
    //   accessor: "demand_type",
    //   Cell: (instance) => (
    //     <div className={StrategyCSS.multiselectDiv}>
    //       <SelectCellComponent
    //         {...instance}
    //         defaultValue={instance.row.original?.demand_type}
    //         options={instance.row.original?.forecastOptionsList}
    //         changeHandler={(rowIdx, columnId, values, article) => {
    //           // if(values === "IA") {
    //           //   // instance.updateMyData(rowIdx, 'final_total_inventory', '');
    //           //   instance.updateMyData(rowIdx, 'fixed_units', '');
    //           //   instance.updateMyData(rowIdx, 'fixed_percentage', '');
    //           // };
    //         }}
    //       />

    //       {/* <SelectCell {...instance}
    //         dropdownOptions={instance.row.original?.forecastOptionsList}
    //         value={instance.row.original?.demand_type}
    //         changeHandler={(rowIdx, columnId, values) => {
    //           // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
    //         }}
    //       /> */}
    //     </div>
    //   ),
    //   disableFilters: true,
    //   filter: 'filterSingleSelectCell',
    //   disableSortBy: true,
    // },
    // {
    //   Header: "Fixed Inv Units",
    //   accessor: "fixed_units",
    //   Cell: (instance) => (
    //     instance.row.original?.demand_type === "Fixed" ? <InputCell {...instance} min={0} max={instance.row.original['store_inv']} type="number"
    //       changeHandler={(rowIdx, columnId, values) => {
    //         instance.updateMyData(rowIdx, 'fixed_percentage', '');
    //         // instance.updateMyData(rowIdx, 'final_total_inventory', Number(values));
    //       }}
    //     /> : (" ")
    //   ),
    //   disableFilters: true,
    //   disableSortBy: true
    // },
    // {
    //   Header: "Fixed Inv %",
    //   accessor: "fixed_percentage",
    //   Cell: (instance) => (
    //     instance.row.original?.demand_type === "Fixed" ? <InputCell {...instance} min={0} max={100} type="number"
    //       changeHandler={(rowIdx, columnId, values) => {
    //         instance.updateMyData(rowIdx, 'fixed_units', '');
    //         // instance.updateMyData(rowIdx, 'final_total_inventory', Math.round((values/100) * instance.row.original?.["store_inv"]));
    //       }}
    //     /> : (" ")
    //   ),
    //   disableFilters: true,
    //   disableSortBy: true
    // },
    // {
    //   Header: "Final Net Available Inv.",
    //   accessor: "final_total_inventory",
    //   // Cell: (inst) => numberUSFormatting(inst?.value),
    //   width: 180,
    //   disableFilters: true,
    //   disableSortBy: true
    // },
    {
      Header: "Store Inv. (OH)",
      accessor: "store_inv",
      disableFilters: true,
      //Filter disabled because api is failing from the backend
      // Filter: (instance) => (
      //   <NumberRangeColumnFilterServerSide
      //     {...instance}
      //     minSearchTermValue={searchTermReq?.store_inv?.value?.min}
      //     maxSearchTermValue={searchTermReq?.store_inv?.value?.max}
      //     clickHandler={(min, max, id) =>
      //       fecthDataWithSearchTerm([min, max], id, "range")
      //     }
      //   />
      // ),
    },
    {
      Header: "DC Inv",
      accessor: "dc_inv",
      disableFilters: true,
      //Filter disabled because api is failing from the backend
      // Filter: (instance) => (
      //   <NumberRangeColumnFilterServerSide
      //     {...instance}
      //     minSearchTermValue={searchTermReq?.dc_inv?.value?.min}
      //     maxSearchTermValue={searchTermReq?.dc_inv?.value?.max}
      //     clickHandler={(min, max, id) =>
      //       fecthDataWithSearchTerm([min, max], id, "range")
      //     }
      //   />
      // ),
    },
    {
      Header: "WTD",
      accessor: "wtd",
      disableFilters: true,
    },
    {
      Header: "MTD",
      accessor: "mtd",
      disableFilters: true,
    },
    {
      Header: "QTD",
      accessor: "qtd",
      disableFilters: true,
    },
    {
      Header: "Transfer Rule",
      accessor: "rule_id",
      Cell: (instance) => (
        <div>
          <SelectCellComponent
            {...instance}
            defaultValue={Number(instance.row.original?.rule_id)}
            options={instance.row.original?.rule_options}
            changeHandler={(rowIdx, columnId, values, article) => {
              // let sizes = values?.map((val) => val?.value);
              setChangedStoresId({article:instance?.rows?.[rowIdx]?.original?.article,transfer_rule:values});
              let storeReq = {
                "rule_id" : [values],
                "storesFilter": "Store Transfer available"
              };
              props.UpdateStoreS2S(storeReq);

            }}
          />
        </div>
      ),
      filter: 'filterSingleSelectCell',
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.rule_id?.value || searchTermReq?.rule_name?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
    {
      Header: "Transfer Strategy",
      accessor: "transfer_strategy",
      Cell: (instance) => (
        <div>
          <SelectCellComponent
            {...instance}
            defaultValue={instance.row.original?.transfer_strategy}
            options={instance.row.original?.transfer_strategy_options}
            changeHandler={(rowIdx, columnId, values, article) => {
            }}
          />
        </div>
      ),
      filter: 'filterSingleSelectCell',
      Filter: (instance) => (
        <ColumnFilterServerSide
          {...instance}
          searchTermValue={searchTermReq?.transfer_strategy?.value}
          changeHandler={(term, id) =>
            fecthDataWithSearchTerm(term, id, "string")
          }
        />
      ),
    },
  ];

  
  // const onDemandTypeChange = (val) => {
  //   val.value === "Fixed" ? setFixedInvDisabled(false) : setFixedInvDisabled(true);
  //   setDemandType(val.value);
  // };
   

  const mapRTInstanceRowsToIds = () => {
    if (!RTInstance || !RTInstance.rows || !Array.isArray(RTInstance.rows)) {
      return {};
    }
  
    return RTInstance.rows.reduce((acc, row) => {
      if (row.original && row.original.article) {
        acc[row.original.article] = row.id;
      }
      return acc;
    }, {});
  };


  const onCreateAllocationTableHandler = (data) => {
    let allocationData = data?.length ? data : checkAllData;
    // const storeNumbers = props.storeCodes;
    if (allocationData?.length) {
      const rtRow = mapRTInstanceRowsToIds();
      let req = [];
      req = allocationData.map((row) =>{

        if (rtRow[row.article]) {
          //already present in rtinstance
          return {
            Product_Code: RTInstance.rows[rtRow[row.article]].original.article,
            Transfer_Rule: Number(RTInstance.rows[rtRow[row.article]].original.rule_id),
            Size_List: (RTInstance.rows[rtRow[row.article]].original.size_value).map(size => size.value).filter(size => size !== "*"),
            Demand_Type: (RTInstance.rows[rtRow[row.article]].original.demand_type == "IA" ? "IA Forecast" : RTInstance.rows[rtRow[row.article]].original.demand_type),
            // ...(RTInstance.flatRows[row].original.demand_type === "Fixed" ? { Fixed_inv_units: (Number(RTInstance.flatRows[row].original.fixed_units) || (Math.round((Number(RTInstance.flatRows[row].original.fixed_percentage)/100) * RTInstance.flatRows[row].original.store_inv))) } : null),
            Allocation_Name: "",
            Allocation_Code: "",
            Department: RTInstance.rows[rtRow[row.article]].original.l1_name,
            Source_Destination: (RTInstance.rows[rtRow[row.article]].original.store_value).map(row => row.value).filter(row => row !== "*"),
            User_Name: localStorage?.getItem("email"),
            Transfer_Strategy: RTInstance.rows[rtRow[row.article]].original.transfer_strategy,
            "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
            Product_Profile_Code: RTInstance.rows[rtRow[row.article]]?.original?.product_profile_code,
          }
        } else {
          //take data from allocationData
          return {
            Product_Code: row.article,
            Transfer_Rule: Number(row.rule_id),
            Size_List: row.size_list,
            Demand_Type: "IA Forecast",
            // ...(RTInstance.flatRows[row].original.demand_type === "Fixed" ? { Fixed_inv_units: (Number(RTInstance.flatRows[row].original.fixed_units) || (Math.round((Number(RTInstance.flatRows[row].original.fixed_percentage)/100) * RTInstance.flatRows[row].original.store_inv))) } : null),
            Allocation_Name: "",
            Allocation_Code: "",
            Department: row.l1_name,
            Source_Destination: row.source_destination,
            User_Name: localStorage?.getItem("email"),
            Transfer_Strategy: row?.transfer_strategy,
            "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
            Product_Profile_Code: row?.product_profile_code,
          }
        }
      })

      if (req && req.some(row => (!row?.Size_List?.length || !row?.Source_Destination?.length))) {
        Notify.error('Please select Sizes and Stores for all selected rows');
        return;
      }
      setTimeoutLoader(true);
      props.createAllocation(req,useSSE);

    } else if (Object.keys(RTInstance.state.selectedRowIds).length > 0) {
      createStoreAllocation();
    } else {
      Notify.error(
        `Select Atleast one ${updateArticleName(false)} Inventory!!`
      );
    }
  };
  const onTransferStrategyChange = (val) => {
    setTransferStrategy(val.value);
  };

  // const onUserDefinedFixedInvChange = (val) => {
  // demandType === "Fixed" &&  setUserDefinedFixedInv(Number(val))
  // };

  const prepInput = React.useMemo(() => [
    // {
    //   id: 1,
    //   isRequired: false,
    //   label: "Demand Type",
    //   name: "demand_type",
    //   className: "setAll__filter",
    //   onChange: onDemandTypeChange,
    //   placeHolder: `Select Allocation Status`,
    //   options: DEMAND_TYPE_OPTIONS,
    //   isMultiSelect: false,
    //   hideSelectAll: true,
    // },
    {
      id: 2,
      isRequired: true,
      label: "Transfer Strategy",
      name: "transfer_strategy",
      className: "setAll__filter",
      onChange:onTransferStrategyChange,
      placeHolder: `Select Transfer Strategy`,
      options: transferStrategyOptions,
      isMultiSelect: false,
      hideSelectAll: true,

    },
    // {
    //   id: 3,
    //   isRequired: false,
    //   label: "Fixed Inv %",
    //   name: "fixed_percentage",
    //   className: "setAll__input",
    //   value: userDefinedFixedInv,
    //   onChange: (e) => {
    //     let val = e.target.value
    //     if (val < 0) {
    //       val = 0
    //     }
    //     if (val > 100) {
    //       val = 100
    //     }
    //     onUserDefinedFixedInvChange(val)
    //   },
    //   disabled: fixedInvDisabled,
    //   input: true,
    //   type: "number",
    //   min: 0,
    //   max: 100
    // },
  ],[transferStrategy,demandType,userDefinedFixedInv,transferStrategyOptions,fixedInvDisabled])
  
  const handleSetAllCancel = () => {
    setShowSetAll(false);
  };

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  };

  const handleSetAllOk = () => {
    if (demandType || transferStrategy || (userDefinedFixedInv || userDefinedFixedInv === 0)) {
      let l_lastValueIndex = (checkAll?.length - 1 === -1) ? 0 : checkAll?.length - 1;

      let setAllRows = RTInstance?.selectedFlatRows?.map(
        (row) => row?.id
      );
      setSelectedRows([]);
      updateArticleData(setAllRows,transferStrategy);
    }
  };

  const createStoreAllocation = () => {
    if((RTInstance?.selectedFlatRows).length > 0) {
      const selectedRowsIds = RTInstance.flatRows.filter((val) => selectedRows.includes(val.original.article))
      .map((row) => row?.index);
      let req = [];
      req = selectedRowsIds.map((row) =>{
        return {
          Product_Code: RTInstance.flatRows[row].original.article,
          Transfer_Rule:Number(RTInstance.flatRows[row].original.rule_id),
          Size_List:(RTInstance.flatRows[row].original.size_value).map(size =>  size.value).filter(size => size !== "*"),
          Demand_Type:(RTInstance.flatRows[row].original.demand_type == "IA" ? "IA Forecast" : RTInstance.flatRows[row].original.demand_type),
          // ...(RTInstance.flatRows[row].original.demand_type === "Fixed" ? { Fixed_inv_units: (Number(RTInstance.flatRows[row].original.fixed_units) || (Math.round((Number(RTInstance.flatRows[row].original.fixed_percentage)/100) * RTInstance.flatRows[row].original.store_inv))) } : null),
          Allocation_Name:"",
          Allocation_Code:"",
          Department:RTInstance.flatRows[row].original.l1_name,
          Source_Destination:(RTInstance.flatRows[row].original.store_value).map(row => row.value).filter(row => row !== "*"),
          User_Name:localStorage?.getItem("email"),
          Transfer_Strategy:RTInstance.flatRows[row].original.transfer_strategy,
          "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
          Product_Profile_Code:RTInstance.flatRows[row]?.original?.product_profile_code,
        }
      })
      
      if (req && req.some(row => (!row?.Size_List?.length || !row?.Source_Destination?.length))) {
        Notify.error('Please select Sizes and Stores for all selected rows');
        return;
      }
      setTimeoutLoader(true);
      props.createAllocation(req,useSSE);
       
    } else {
      Notify.error(
        `Please select at least 1 article to create store allocation!!`
      );
    }
  };

  const setAllHandler = () => {
    if ((RTInstance?.selectedFlatRows).length > 0) {
      setShowSetAll(true);
      setFixedInvDisabled(true);
      setDemandType(null);
      setTransferStrategy(null);
      setUserDefinedFixedInv(null);
    } else {
      Notify.error(
        `Please select atleast 1 article to use Set All functionality!!`
      );
    }
  };

  const updateSetAll = (value) => {
    let l_newAction = {
        "searchTermReq": searchTermReq
    }
    
    // let l_newAction = {};
    if (!value.checked) {
      setPrevAction("checkAll");
      l_newAction["checkAll"] = true;
      setSelectedRows(old => {
        const selectedAllArticles = value.instance.map(row => row.article);
        return [...selectedAllArticles];
       })
    } else {
      setPrevAction("unCheckAll");
      setSelectedRows([]);
      l_newAction["unCheckAll"] = true;
      let l_updatedCheckedRows = removeCheckedArticles(checkedRows, {});
      setCheckedRows(l_updatedCheckedRows);
    }
    setCheckAll((old) => [...old, l_newAction]);
    
  };

  const updateCheckedRows = (value) => {
   
    let l_newAction = {},
      l_article = value.instance.article;

    if (!value.checked) {
      //case when a row is selected

     setSelectedRows( old => [...new Set([...old, value.instance.article])])

      setPrevAction("checkRow");
      if (prevAction === "checkRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy?.[l_lastValueIndex];
        l_checekdRows && l_checekdRows["checkedRows"]?.push(l_article);
        checkAllCopy.pop();
        l_checekdRows
          ? setCheckAll([...checkAllCopy, l_checekdRows])
          : setCheckAll([...checkAllCopy]);
      } else {
        l_newAction["checkedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = { [l_article]: value.instance };
      setCheckedRows((old) => {
        return {
          ...old,
          ...l_checkedRow,
        };
      });
    } else {
      //case when a row is unselected
      setPrevAction("unCheckRow");
      if (prevAction === "unCheckRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy[l_lastValueIndex];
        l_checekdRows["unCheckedRows"].push(l_article);
        checkAllCopy.pop();
        setCheckAll([...checkAllCopy, l_checekdRows]);
        // checkAll[l_lastValueIndex]?.checkAll
      } else {
        l_newAction["unCheckedRows"] = [l_article];
        // l_newAction["checkAll"] = false;
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = cloneDeep(checkedRows);
      delete l_checkedRow[l_article];
      setCheckedRows((old) => {
        return {
          ...l_checkedRow,
        };
      });

      setSelectedRows(old => {
        const indexToRemove = selectedRows.indexOf(l_article);
        if (indexToRemove > -1) { 
          old.splice(indexToRemove, 1); 
        }
        return [...old]
      })
    }
  };
  
  useEffect(() => {
    props.resetAllS2S();
  },[]);


  useEffect(() => {
    if (props.out_of_data || props?.data?.length < 100) {
      setHideTotalPages(false);
      setHideTotalRecords(false);
    } else {
      setHideTotalPages(true);
      setHideTotalRecords(true);
    }
  }, [props.out_of_data, props?.data]);

     
  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    if (props.updatedStoresDataDashboardS2S?.length && changedStoresId !== null) {   
      props.updateStoresForRuleChange(props.updatedStoresDataDashboardS2S,changedStoresId)
    }
  }, [props.updatedStoresDataDashboardS2S]);

  
  useEffect(() => {
   setTransferStrategyOptions([...props.transferStrategyOptions])
  },[props.transferStrategyOptions])

  useEffect(() => {
    if(isUseSSE()) {
      setUseSSE(true)
    }
  },[process.env.REACT_APP_CLIENT])

  useEffect(() => {
    if (props.createAllocationError) {
      Notify.error(props.createAllocationError?.message)
      setTimeoutLoader(false);
    }
    if(useSSE) {
      if (props.createAllocationSuccess && props.createAllocationData) {
        eventSource = new EventSource(`${SSE_CREATE_ALLOCATON}?uuid_str=${props.createAllocationData}`)
        eventSource.onmessage = (event) => {
          const result = JSON.parse(event.data).data;
          if (result.status && result.allocation_code) {
            
            Notify.success("Success in creating allocation!!");
            setTimeout(() => {
              history.push(STORE_TO_STORE_FINALIZE, {"warning": result?.warning_message, allocationCode: result.allocation_code });
              // window.location.reload()
              reloadPageWithUpdateToken()
            }, 2000);
            eventSource.close();
          }
          if(!result.status) {
            props.setLoaderToFalse()
            Notify.error(result?.message ||  ERROR_MSG)
            eventSource.close();
            setTimeoutLoader(false);
            props.resetAllS2S();
          }
        }
  
        eventSource.onerror = (event) => {
          if (event.target.readyState === EventSource.CLOSED) {
          }
          eventSource.close();
          props.setLoaderToFalse()
          Notify.error(ERROR_MSG)
          setTimeoutLoader(false);
          props.resetAllS2S();
        }
      }
    }
  }, [props.createAllocationError, props.createAllocationSuccess, props.createAllocationData, useSSE])

  useEffect(() => {
    setTableData([]);
    // setSearchTermReq({});
    // setSortReq({});

    //check this logic 
    // setRTInstance(null);


    // setInitialIndex(0);
    // setPageIndex(0);
    // setPrevIndex(0);
    // setTotalCount(0);
    // setNextIndex(0);
    // props.resetPageIndex();
    // setShowTable(false)
    // setInitialSelectedRows({})
    setCheckAll([])
    // setCheckAllReqArr([])
    setPrevAction("");
    setSelectedRows([]);
    //new state variable reset logic for select all and deselect all 

    setCheckAllReqArr([])
  }, []);

  useEffect(() => {
    //newly added for select all and deselect all 
    setCheckAll([])
    setCheckAllReqArr([])
    setPrevAction("");
  }, [props.filters]);

  const setSelectedInstance = (instance) => {
    setRTInstance(instance);
    props.setStoreTableInstance(instance);
  }

  const getCheckAllData = (p_checkAllReq, data) => {
    if (!isEmpty(p_checkAllReq)) {
      let l_checkAllRequest = p_checkAllReq[0];
      let reqFilters = {};
      for (let key in props.filters) {
        if (props.filters[key]) {
          reqFilters[key] = props.filters[key];
        }
      }

      if (reviewCall) {
        reqFilters["storesFilter"] = props.reviewRequest["storesFilter"];
      }

      //check this api logic for store to store
      props.getReviewRecommendData({
        ...reqFilters,
        checkAll: true,
        row_count: 500,
        row_index: props.checkAllNextIndexS2S,
        searchColumns: l_checkAllRequest?.searchTermReq,
        sortColumn: sortReq,
        reviewCall,
      });
    } else if(checkAll?.length) {
      onCreateAllocationTableHandler(data);
    }
  };

  const checkPaginationHistory = () => {
    let l_checkedUnchecked = checkAll.filter(
      (val) => val.checkAll || val.unCheckAll
    );
    if (l_checkedUnchecked?.length) {
      //When the user clicks on select all or deselect all then this flow triggers
      let l_checkedUnchecked = checkAll.filter(
        (val) => val.checkAll || val.unCheckAll
      );
      let l_checkAll = [];
      for (let i = 0; i < l_checkedUnchecked.length; i++) {
        if (l_checkedUnchecked[i].checkAll) {
          l_checkAll.push(l_checkedUnchecked[i]);
        }
        if (l_checkedUnchecked[i].unCheckAll) {
          l_checkAll = [];
        }
      }

      let checked = l_checkAll.filter((val) => val.checkAll);
      let unchecked = l_checkAll.filter((val) => val.unCheckAll);
      for (let i = 0; i < checked.length; i++) {
        for (let j = 0; j < unchecked.length; j++) {
          if (isEqual(checked[i].searchTermReq, unchecked[j].searchTermReq)) {
            checked.splice(i, 1);
          }
        }
      }
      setCheckAllReqArr(checked);
      setIsGetAllLoading(true);
      getCheckAllData(checked);
    } else {
      //flow for when user does not clicks on select all or deselect all
      onCreateAllocationTableHandler();
    }
  };


  useEffect(() => {
    // let checkAllCount = reviewCall ? stylesCount : props.checkAllTotalCount;
    if (props?.checkAllDataS2S?.length) {
      let l_finalCheckedArticles = getFinalCheckedRowsForCAData(
        checkAll,
        props.checkAllDataS2S
      );
      // let data = cloneDeep(checkAllData).concat(props?.checkAllData)
      let data = cloneDeep(checkAllData).concat(
        Object.values(l_finalCheckedArticles)
      );
      props?.checkAllDataS2S?.length && setCheckAllData(data);
      if (!props.checkAllOutOfIndex && isGetAllLoading) {
        getCheckAllData(checkAllReqArr, data);
      } else if (props.checkAllOutOfIndex) {
        let checkAllArr = [...checkAllReqArr];
        checkAllArr.shift();
        // setCheckAll(checkAllArr)
        setCheckAllReqArr(checkAllArr);
        getCheckAllData(checkAllArr, data);
      } else if (isGetAllLoading) {
        setPrevAction("");
      }
    } else if (props.checkAllOutOfIndex) {
      let checkAllArr = [...checkAllReqArr];
      checkAllArr.shift();
      setCheckAllReqArr(checkAllArr);
      getCheckAllData(checkAllArr);
    }
  }, [props?.checkAllDataS2S]);

  useEffect(() => {
    if (props?.data?.length) {
      let l_newData = getNewData({
        p_newData: [...props?.data],
        p_checkAll: checkAll,
      });
      if (RTInstance?.data?.length) {
        setInitialSelectedRows(l_newData?.finalCheckedRows);
      } else { 
        setInitialSelectedRows({});
      }
    }
  }, [props?.data]);

  return (
    <>
    {showSetAll && (
        <SetAllModal
          modalBodyStyle={{ height: "30rem"}}
          filter={prepInput}
          messageStyle={{ margin: "auto", color: "red" }}
          handleOk={handleSetAllOk}
          handleCancel={handleSetAllCancel}
          handleClose={() => handleClose()}
          disableApply={false}
        />
      )}
      <PageLoader loader={loading || props.storeDataLoading || timeoutLoader} gridLoader={true} loaderMessage="Loading...">
        <ReactTableWithPlugins
          getInstanceOnMount={(inst) => setSelectedInstance(inst)}
          columns={COLUMNS}
          data={props.data || []}
          renderMarkup="TableMarkup"
          keyRT="StyleInvTable"
          shouldPagination
          features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
          tableId="store_transfer_review"
          fetchData={(data) => {
            fetchData(data)}}
          initialPageIndex={initialIndex}
          initialSelectedRows={initialSelectedRows}
          // totalCount={totalCount}
          getManualSortByOptions={(instance) => {
            fecthDataWithSorting(instance);
          }}
          manualSortBy={true}
          checkAllCallBack={(val) => updateSetAll(val)}
          checkCallBack={(val) => updateCheckedRows(val)}
          hideTotalRecords={reviewCall && hideTotalRecords}
          hideTotalPages={reviewCall && hideTotalPages}
          hideDropdown={"Yes"}
        />

        <div className="text-center">

          <button
            className="btn btn-primary fnt-md px-2 mr-3"
            onClick={setAllHandler}
          >
            Set All
          </button>
          <button
            className="btn btn-primary fnt-md px-2 "
            onClick={() => checkPaginationHistory()}
          >
            Create Store Transfer
          </button>
        </div>
      </PageLoader>
    </>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  loading: dashboard.reviewRecommendLoading,
  transferStrategyOptions:dashboard.transferStrategyOptions,
  recDatatotalCount: dashboard.recDatatotalCount,
  recDatanextIndex: dashboard.recDatanextIndex,
  pageIndex: dashboard.reviewPageIndex,
  out_of_data: dashboard.reviewRecommendOutofData,
  storeDataLoading: dashboard.storeDataLoading,
  createAllocationError: dashboard.createAllocationError,
  createAllocationSuccess: dashboard.createAllocationSuccess,
  createAllocationData: dashboard.createAllocationData,
  updatedStoresDataDashboardS2S:dashboard.updatedStoresDataDashboardS2S,
  checkAllNextIndexS2S: dashboard.checkAllNextIndexS2S,
  checkAllDataS2S: dashboard.checkAllDataS2S,
  checkAllOutOfIndex:dashboard.checkAllOutOfIndex,

});

const mapDispatchToProps = (dispatch) => ({
  getReviewRecommendData: (payload) =>
    dispatch(getReviewRecommendData(payload)),
  createAllocation: (payload,isSSE) => dispatch(createAllocation(payload,isSSE)),
  resetPageIndex: () => dispatch(resetPageIndex()),
  setLoaderToFalse: () => dispatch(setLoaderToFalse()),
  resetAllS2S:() => dispatch(resetAllS2S()),
  UpdateStoreS2S:(payload) => dispatch(UpdateStoreS2S(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreTransferReview);
