import React from "react";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import ReviewStoresTable from "../CreateStoreTransferRule/ReviewStores/ReviewStoresTable";
import PageLoader from "../../../../components/Loader/PageLoader";

const RuleMappingDetails = (props) => {
  const { ruleName, closeAlert, data, loading } = props;
  return (
    <div className="modal_opacity">
      <ModalComponent showAlert={ruleName} closeAlert={closeAlert} width="90%">
        <div className="modal-container">
          <div className="modal-header">
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div>
                <h5 className="page-heading m-0">{ruleName}</h5>
              </div>
              <div>
                <button type="button" className="close" data-dismiss="modal">
                  <i
                    className="fa fa-times text-right m-2 "
                    aria-hidden="true"
                    onClick={closeAlert}
                    style={{ cursor: "pointer" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            className="modal-middle-container modal-body"
            style={{ padding: "auto" }}
          >
            <PageLoader loader={loading} gridLoader={true}>
              <ReviewStoresTable data={data} />
            </PageLoader>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default RuleMappingDetails;
