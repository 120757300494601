import React from "react";
import ReactTable from "../../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../../components/Table/WithPlugins";
import PageLoader from "../../../../../../components/Loader/PageLoader";
import ColumnFilter from "../../../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../../../components/Filters/NumberRangeColumnFilter";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreViewTable = (props) => {
  const { data, loading, getProducts ,setStoreCode,viewAllocationCallS2S} = props;


  const COLUMNS = [
    {
      Header: "Destination",
      accessor: "destination_store",
      Cell: ({ value, row }) => (
        row.canExpand ? (<div>
          <div className="clickable" onClick={() => {
            setStoreCode(value);
            getProducts(value, row.original.destination_store_name)
          }}>
            {value}
          </div>
        </div>) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        )
      ),
      Filter: (instance) => (
        <ColumnFilter {...instance} placeholder="Search in bulk by ','" />
      ),
      filter: "bulkFilterCommaSeperated",
      sticky: "left",
    },
    {
      Header: "Destination Name",
      accessor: "destination_store_name",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 250,
    },
    {
      Header: "Country",
      accessor: "destination_country",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "District",
      accessor: "destination_district",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 200,
    },
    {
      id: "expander", // Make sure it has an ID
      Header: "Source Count",
      accessor: "source_count",
      Cell: ({ value, row }) => {
        return row.canExpand ? (
          <div className="row" {...row.getToggleRowExpandedProps()}>
            <span className="expand__text">{value}</span>
            {row.isExpanded ? (
              <div className="cursor-pointer" style={{ color: "#50778e" }}>
                <i
                  className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                  title="Collpase"
                ></i>
              </div>
            ) : (
              <div className="cursor-pointer" style={{ color: "#50778e" }}>
                <i
                  className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                  title="Expand"
                ></i>
              </div>
            )}
          </div>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
          // <span>{value}</span>
        );
      },
      width: 200,
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    // {
    //   Header: "Source Count",
    //   accessor: "source_count",
    //   Filter: NumberRangeColumnFilter,
    //   filter: 'between'
    // },
    {
      Header: "Style Color Count",
      accessor: "style_color_count",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Transfer Units",
      accessor: "transfer_units",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Source",
      accessor: "source_store",
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
      width: 250,
    },
    {
      Header: "Source Name",
      accessor: "source_store_name",
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
      width: 250,
    },
  ];

  return (
    <PageLoader loader={loading} gridLoader={true}>
      <section className="section mt-4 m-0">
        <ReactTableWithPlugins
          // shouldPagination
          embededScroll
          containSubRow
          totalRecordsLen={data ? data.length : 0}
          data={data ? data : []}
          columns={COLUMNS}
          renderMarkup="TableMarkup"
          keyRT={"sortAndSearch"}
          tableId="sts_store_view"
          isHideCount={true}
          hideDropdown
        />
      </section>
    </PageLoader>
  );
};

export default StoreViewTable;
