import React from "react";
import NumberRangeColumnFilter from "../../../../../components/Filters/NumberRangeColumnFilter";
import ColumnFilter from "../../../../UserManagement/ColumnFilter";

export const COLUMNS = [
  {
    Header: "Source",
    accessor: "source",
    Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
    filter: 'bulkFilterCommaSeperated',
    sticky: "left"
  },
  {
    Header: "Source Name",
    accessor: "source_name",
    Filter: ColumnFilter,
    sticky: "left",
    width: 200
  },
  {
    Header: "Destination",
    accessor: "destination",
    Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
    filter: 'bulkFilterCommaSeperated',
    sticky: "left"
  },
  {
    Header: "Destination Name",
    accessor: "destination_name",
    Filter: ColumnFilter,
    sticky: "left",
    width: 200
  },
  {
    Header: "Distance(miles)",
    accessor: "distance",
    Filter: NumberRangeColumnFilter,
    filter: 'between',
  },
  {
    Header: "Source Region",
    accessor: "source_district",
    Filter: ColumnFilter,
    width: 200
  },
  {
    Header: "Destination Region",
    accessor: "destination_district",
    Filter: ColumnFilter,
    width: 200
  },
  {
    Header: "Source Grade",
    accessor: "source_grade",
    Filter: ColumnFilter,
    width: 120
  },
  {
    Header: "Destination Grade",
    accessor: "destination_grade",
    Filter: ColumnFilter,
  },
  {
    Header: "Lead Time",
    accessor: "lead_time",
    Filter: NumberRangeColumnFilter,
    filter: 'between',
    width: 120
  }
];

export const formatExcelData = (data) => {
  return data.map(item => ({
      "Source": item.source,
      "Destination": item.destination,
      "Distance(miles)": item.distance,
      "Source Region": item.source_district,
      "Destination Region": item.destination_district,
      "Source Grade": item.source_grade,
      "Destination Grade": item.destination_grade,
      "Lead Time": item.lead_time
    })
  )
}
