export const initialState = {
    storeSummaryData: [],
    storeSummaryLoading: false,
    storeSummaryError: null,
    storeSummarySuccess: false,
    storeProductData: [],
    storeProductLoading: false,
    storeProductError: null,
    storeProductSuccess: false,
    updateTransferUnitsLoading: false,
    updateTransferUnitsData: false,
    updateTransferUnitsError: false,
    allocationCode:null,
}