import React, { useState, useEffect } from "react";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import { DAILY_SUMMARY_TABLE_HEADERS_S2S } from "./DailySummaryConstants";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter.jsx";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function StyleColorTableS2S(props) {
  const { data, getDetails, tableId, dc } = props;
  const deepDiveTableColumnsS2S = [
    {
      Header: " ",
      sticky: "left",
      columns: [
        {
          Header: " ",
          sticky: "left",
          columns: [
            {
              Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.ARTICLE_ID,
              accessor: "article",
              Filter: (instance) => (
                <ColumnFilter
                  {...instance}
                  placeholder="Search in bulk by ','"
                />
              ),
              filter: "bulkFilterCommaSeperated",
              Cell: (instance) => (
                <div
                  className="clickable"
                  onClick={() => {
                    getDetails(instance.row?.original);
                  }}
                >
                  {instance.row?.original?.article}
                </div>
              ),
            },
            {
              Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE,
              accessor: "style",
              Filter: ColumnFilter,
            },
            {
              Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.COLOR,
              accessor: "color",
              Filter: ColumnFilter,
            },
          ],
        },
      ],
    },
    {
      Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE_DESCRIPTION,
      accessor: "style_description",
      width: 250,
      Filter: ColumnFilter,
    },
    {
      Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.STYLE_COLOR,
      accessor: "style_color",
      width: 200,
      Filter: ColumnFilter,
    },
    {
      Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.GLOBAL_COLOR,
      accessor: "global_color",
      Filter: ColumnFilter,
    },
    {
      Header: "Filters",
      identify: "dcSplitParent",
      columns: [
        {
          Header: levelFiltersLabelFormatter("level1"),
          accessor: "l1_name",
          disableFilters: true,
        },
        {
          Header: levelFiltersLabelFormatter("level2"),
          accessor: "l2_name",
          Filter: ColumnFilter,
          width: 250,
        },
        {
          Header: levelFiltersLabelFormatter("level3"),
          accessor: "l3_name",
          Filter: ColumnFilter,
        },
        {
          Header: levelFiltersLabelFormatter("level4"),
          accessor: "l4_name",
          Filter: ColumnFilter,
        },
        {
          Header: levelFiltersLabelFormatter("level5"),
          width: 200,
          accessor: "l5_name",
          Filter: ColumnFilter,
        },
        {
          Header: levelFiltersLabelFormatter("level6"),
          accessor: "l6_name",
          Filter: ColumnFilter,
        },
      ],
    },
    {
      Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.ALLOCATION_ID,
      accessor: "plan_name",
      Filter: ColumnFilter,
      width: 250,
    },
    {
      Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.RELEASED_AT,
      accessor: "org_released_at",
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: "Unit Transferred",
      columns: [
        {
          Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.TRANSFER_UNITS,
          accessor: "transfer_units",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        },
      ],
    },
    {
      Header: "Pre Transfer Status",
      columns: [
        {
          Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.SOURCE_INVENTORY,
          accessor: "source_inventory",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        },
        {
          Header: DAILY_SUMMARY_TABLE_HEADERS_S2S.DEST_INVENTORY,
          accessor: "destination_inventory",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        },
      ],
    },
  ];

  const validateData = (data) => Array.isArray(data) && data.length > 0;

  return (
    validateData(data) && (
      <ReactTableWithPlugins
        columns={deepDiveTableColumnsS2S}
        data={data ? data : []}
        renderMarkup="TableMarkup"
        keyRT="sortAndSearch"
        shouldPagination
        totalRecordsLen={data?.length}
        tableId={tableId}
        tableWrapperStyle={{ height: "fit-content", maxHeight: "50rem" }}
        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
      />
    )
  );
}

export default StyleColorTableS2S;
