import * as actionTypes from "./ActionTypes"

export const downloadCsvBeforeFinalize = (payload) => ({
    type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE,
    payload
})

export const downloadCsvBeforeFinalizeSuccess = (payload) => ({
    type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS,
    payload
})

export const downloadCsvBeforeFinalizeError = (payload) => ({
    type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
    payload
})
export const resetAllFinalizeS2S = () => ({
    type: actionTypes.RESET_ALL_FINALIZE,
})

export const updateS2SFinalize = (payload) => ({
    type: actionTypes.UPDATE_S2S_FINALIZE,
    payload
})

export const updateS2SFinalizeSuccess = (payload) => ({
    type: actionTypes.UPDATE_S2S_FINALIZE_SUCCESS,
    payload
})

export const updateS2SFinalizeError = (payload) => ({
    type: actionTypes.UPDATE_S2S_FINALIZE_ERROR,
    payload
})

export const getPastAllocationPOS2S = (payload) => ({
    type: actionTypes.GET_PAST_ALLOCATION_PO_S2S,
    payload,
});

export const finalizeAllocationSuccessS2S = (payload) => ({
    type: actionTypes.GET_PAST_ALLOCATION_PO_SUCCESS_S2S,
    payload
})

export const finalizeAllocationErrorS2S = (payload) => ({
    type: actionTypes.GET_PAST_ALLOCATION_PO_ERROR_S2S,
    payload
})

export const downloadCsv = (payload) => ({
    type: actionTypes.DOWNLOAD_CSV,
    payload
})

export const disableEdit = (payload) => ({
    type: actionTypes.DISABLE_EDIT,
    payload
})

export const disableEditSuccess = (payload) => ({
    type: actionTypes.DISABLE_EDIT_SUCCESS,
    payload
})

export const disableEditError = (payload) => ({
    type: actionTypes.DISABLE_EDIT_ERROR,
    payload
})

export const finalizeAllocation = (payload, source) => ({
    type: actionTypes.FINALIZE_ALLOCATION,
    payload,
    source
})

export const finalizeAllocationSuccess = (payload) => ({
    type: actionTypes.FINALIZE_ALLOCATION_SUCCESS,
    payload
})

export const finalizeAllocationError = (payload) => ({
    type: actionTypes.FINALIZE_ALLOCATION_ERROR,
    payload
})

export const updateStoreTransferQuantity = (payload) => ({
    type: actionTypes.UPDATE_STORE_TRANSFER_QTY,
    payload
})

export const updateStoreTransferQuantitySuccess = (payload) => ({
    type: actionTypes.UPDATE_STORE_TRANSFER_QTY_SUCCESS,
    payload
})

export const updateStoreTransferQuantityError = (payload) => ({
    type: actionTypes.UPDATE_STORE_TRANSFER_QTY_ERROR,
    payload
})