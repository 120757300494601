import React from "react";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ReactTollTip from "../../../../components/Table/Cellrenderer/ReactToolTip";
import EditIconCell from "../../../../components/Table/Cellrenderer/EditIconCell";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import { sortDateColumn } from "../../../../utils/tableHelperUtilities";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const StoreTransferRulesTable = (props) => {
  const { data, handleEdit, handleDelete, getRuleMapping } = props;
  const storeTransferRuleColumns = React.useMemo(
    () => [
      {
        Header: "Rule Name",
        accessor: "rule_name",
        width: 250,
        Filter: ColumnFilter,
        Cell: ({ row, value }) => (
          <div>
            <div
              className="clickable"
              onClick={() => getRuleMapping(row?.original?.rule_id, value)}
            >
              {value}
            </div>
          </div>
        ),
      },
      {
        Header: "Transfer Within",
        accessor: "transfer_within",
        Filter: ColumnFilter,
      },
      {
        Header: levelFiltersLabelFormatter("level1"),
        accessor: (data) => data.product_filters?.l1_name,
        Filter: ColumnFilter,
      },
      {
        Header: levelFiltersLabelFormatter("level2"),
        accessor: (data) => data.product_filters?.l2_name,
        Filter: ColumnFilter,
      },
      {
        Header: levelFiltersLabelFormatter("level3"),
        accessor: (data) => data.product_filters?.l3_name,
        Cell: (instance) => <ReactTollTip {...instance} />,
        width: 250,
        Filter: ColumnFilter,
      },
      {
        Header: "Created By",
        accessor: "created_by_username",
        Cell: (instance) => <ReactTollTip {...instance} />,
        width: 250,
        Filter: ColumnFilter,
      },
      {
        Header: "Updated By",
        accessor: "updated_by_username",
        Cell: (instance) => <ReactTollTip {...instance} />,
        width: 250,
        Filter: ColumnFilter,
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        width: 200,
        Filter: ColumnFilter,
        sortType: (rowA, rowB) => sortDateColumn(rowA?.original?.updated_at, rowB?.original?.updated_at, true)
      },
      {
        Header: "Actions",
        accessor: "actions",
        sticky: "right",
        Cell: (instance) => (
          <div>
            <EditIconCell
              handleEdit={(instance) => handleEdit(instance?.rule_id)}
              handleDelete={(instance) => handleDelete(instance?.rule_id)}
              instance={instance}
            ></EditIconCell>
          </div>
        ),
        resizable: true,
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <section className="section mt-4 m-0">
      <ReactTableWithPlugins
        sortBy={[{ id: "updated_at", desc: true }]}
        columns={storeTransferRuleColumns}
        data={data}
        renderMarkup="TableMarkup"
        keyRT="sortAndSearch"
        shouldPagination
        tableId="store_transfer_rules_view"
      />
    </section>
  );
};

export default StoreTransferRulesTable;
