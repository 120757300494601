import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import StoreTransferConfigTable from "./StoreTransferConfigTable.jsx";
import useSelectFilters from "../../../../customHooks/useSelectFilters";
import { connect } from "react-redux";
import * as Notify from "../../../../components/Notification/Notifications";
import { cloneDeep, isEmpty } from "lodash";
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander.js";
import {
  fetchFilterData,
  fetchUserCreatedDataStoreToStore,
  updateAutoAllocationRequired,
  resetFiltersOptions,
  resetAll
} from "./StoreTransferConfigurationAction.js";
import {
  getLevelFiltersForUserPreference,
  getUserPreferenceFilter,
  shouldHideForCurrentClient,
  setUserPreferenceFilter,
  getDefaultFiltersCondition,
  isMandatory,
  getFilterForClients,
} from "../../../../utils/commonUtilities";
const StoreTransferConfigView = ({ ...props }) => {
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [level1Options, setlevel1Options] = useState(null);
  const [level2Options, setlevel2Options] = useState(null);
  const [level3Options, setlevel3Options] = useState(null);
  const [level4Options, setlevel4Options] = useState(null);
  const [level5Options, setlevel5Options] = useState(null);
  const [articleConfigData, setArticleConfigData] = useState([]);
  const [RTInstance, setRTInstance] = useState({});
  const [searchTermReq, setSearchTermReq] = useState({});
  const [isFirstCall, setisFirstCall] = useState(true);
  const [sortReq, setSortReq] = useState({});
  const [initialIndex, setInitialIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [checkAllReset, setCheckAllReset] = useState(false);
  const [storefilters, setStoreFilters] = useState({});
  const [transfer_strategy,setTransferStrategy] = useState({});
  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    dynamicHeader,
    request,
    setRequest,
    resestRequest,
    dc,
    setDc,
    onDcChange,
    level5,
    setLevel5,
    onLevel5Change,
  ] = useSelectFilters(
    props.fetchFilterData,
    true,
    level2Options,
    level3Options,
    level4Options,
    setlevel2Options,
    setlevel3Options,
    setlevel4Options,
    null,
    null,
    level5Options,
    setlevel5Options
  );
  
  const filters ={
    level1,
    level2,
    level3: request?.level3 || level3,
    level4: request?.level4 || level4,
    }
  let selectedFilters = {}
  useEffect(() => {
    setlevel1Options(() => props.level1Options);
  }, [props.level1Options]);

  useEffect(() => {
    setlevel2Options(() => props.level2Options);
  }, [props.level2Options]);

  useEffect(() => {
    setlevel3Options(() => props.level3Options);
  }, [props.level3Options]);

  useEffect(() => {
    setlevel4Options(() => props.level4Options);
  }, [props.level4Options]);

  useEffect(() => {
    setlevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    getUserPreferenceFilter(
      { user_id: localStorage.getItem("user_id") },
      { onl1Change: onLevel1Change, onl2Change: onLevel2Change }
    );
    props.fetchFilterData({}, { key: "l1" });
    // resetFilters();
  }, []);

  useEffect(() => {
    if (props.updateAutoAllocationError) {
      Notify.error("Error in Saving Data !!");
    }
    if (props.updateAutoAllocationSuccess) {
      Notify.success("Saved Successfully!!");
      setRTInstance(null);
      setArticleConfigData([])
      props.fetchUserCreatedDataStoreToStore({...storefilters, row_index: 0, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq})
    }
  }, [props.updateAutoAllocationSuccess, props.updateAutoAllocationError]);

  useEffect(() => {
    if (props?.userCreatedData?.length) {
      if(RTInstance?.data?.length){
        setArticleConfigData([...RTInstance.data, ...props?.userCreatedData])
      } else {
        setArticleConfigData(props?.userCreatedData);
      }
    }
  }, [props?.userCreatedData]);

  useEffect(() => {
    if (props.nextIndex == 100) {
        setPrevIndex(0)
        // setInitialIndex(0)
    }
    setTotalCount(props.totalCount)
    setNextIndex(props.nextIndex)
  }, [props?.totalCount, props?.nextIndex,props?.userCreatedData])

  useEffect(() => {
    if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(storefilters)) {
        setArticleConfigData([])
        setRTInstance(null)
        //they were commented out earlier by me changed now 
        setInitialIndex(0)
        setPrevIndex(0)
        
        props.fetchUserCreatedDataStoreToStore({...storefilters, row_index: 0, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq})
    }
  }, [searchTermReq, sortReq])

   useEffect(() => {
   return () => {
      props.resetAll()
   }
  }, [])

  useEffect(() => {
    // console.log("value of transfer_strategy recieved is ",props.transfer_strategy);
    setTransferStrategy([...props.transfer_strategy])
  },[props.transfer_strategy])

  const resetFilters = () => {
    setArticleConfigData([]);
    setLevel1(null);
    setLevel2(null);
    setLevel3(null);
    setLevel4(null);
    props.resetFiltersOptions([]);
    // resestRequest();
  };

  const applyFilters = () => {
        let reqFilters ={};
        for(let key in filters){
          if(filters[key]) {
            filters[key] && (reqFilters[key] = filters[key])
          }
        }
        // console.log("the value of reqFilters is ",reqFilters);
        if (reqFilters) {
          selectedFilters = reqFilters
        }
        setStoreFilters(reqFilters);
        setIsRowClicked(false);
        setSearchTermReq({})
        setSortReq({})
        setNextIndex(0)
        setArticleConfigData([])
        setInitialIndex(0)
        setPrevIndex(0);
        setCheckAllReset(prev => !prev)
    if (
      getDefaultFiltersCondition({
        l1_name: level1,
        l2_name: level2,
        l3_name: level3,
      })
    ) {
      setUserPreferenceFilter(
        { user_id: localStorage.getItem("user_id") },
        getLevelFiltersForUserPreference({ l1_name: level1, l2_name: level2 })
      );
      props.fetchUserCreatedDataStoreToStore({
       ...selectedFilters
      });
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  const onSubClassChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    // let value = intersectionWith(val, sizeOptions, isEqual);
    setRequest((old) => {
      return {
        ...old,
        l4_name: valuesArray[0],
      };
    });
    setlevel4Options(valuesArray[1]);
  };
  const prep = [
    {
      id: 1,
      isRequired: false,
      label: "Channel",
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      isRequired: true,
    },
    {
      id: 2,
      isRequired: false,
      label: "Department",
      name: "level2",
      value: level2,
      onChange: onLevel2Change,
      placeHolder: `Select ${props.filterLabels?.level2}`,
      options: level2Options?.length ? level2Options : [],
      isRequired: true,
    },
    {
      id: 3,
      label: "Class",
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${
        props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
      }`,
      optionsArray: level3Options?.length ? level3Options : [],
      hide: shouldHideForCurrentClient("l3_name"),
      style: { marginBottom: "10px" },
      isStandAlone: false,
      level: "l3_name",
      isMultiSelect: true,
    },
    {
      id: 4,
      isRequired: false,
      label: "Subclass Collection",
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4 || ""}`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
    },
    // {
    //   id: 5,
    //   isRequired: false,
    //   label: "Product Grade",
    //   name: "level5",
    //   dropdownValue: level5,
    //   onDropDownChange: onLevel5Change,
    //   placeHolder: `Select ${props.filterLabels?.level5 || ""}`,
    //   optionsArray: level5Options?.length ? level5Options : [],
    //   isMultiSelect: true,
    //   hide: shouldHideForCurrentClient("l5_name")
    // },
  ];
  const saveProductStatusData = (uniqueUpdatedIds, setAllRows,editrule) => {
    // Update the table instance updated_by to current user
    // setAllRows.map((item) => {
    //     RTInstance?.updateMyData(item, "updated_by", localStorage?.getItem("email"));
    // })
    let data = RTInstance?.data?.filter((val) =>
    uniqueUpdatedIds.includes(val.article)
  );

    let autoAllocationInline = [];
    // Create updated data for all the articles.
    autoAllocationInline = data.map(
      ({
        article,
        from_date,
        to_date,
        config_status,
        transfer_strategy,
        dc_inv_threshold,
        dc_it_week,
        rule_id,
      }) => {
        return {
          ...(level1 ? { l1_name: storefilters?.level1?.value } : {}),
          article,
          transfer_strategy,
          transfer_rule: rule_id,
          dc_inv_threshold:Math.round(dc_inv_threshold),
          dc_intransit: dc_it_week,
          "status": config_status,
          from_date: from_date === "-" ? null : from_date,
          to_date: to_date === "-" ? null : to_date,
          is_rule_edited:(editrule[article] || false)
        };
      }
    );

    let req = {
      data: autoAllocationInline,
    };
    // if (!isEmpty(searchTermReq)) {
    //     req = {...req, searchColumns: searchTermReq}
    // }
    // if (!isEmpty(sortReq)) {
    //     req = {...req, sortColumn: sortReq}
    // }
    props.updateAutoAllocationRequired(req);
  };

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if(split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
        setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old)
            var id = p_term.split(split_type ? split_type : " ")
            if (p_term?.trim() == 0) {
                l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
            }
            else {

                l_searchTermReq[p_id] = {
                    "type": p_type,
                    "values": id,
                    "search": p_term
                }
            }
            return {
                ...l_searchTermReq
            }
        })
    }
    else if (Array.isArray(p_term)) {
        setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old)
            let min = p_term[0] == "" ? null : p_term[0]
            let max = p_term[1] == "" ? null : p_term[1]
            if (min == null && max == null) {
                l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
            }
            else {
                l_searchTermReq[p_id] = {
                    "type": p_type,
                    "value": {
                        "min": min,
                        "max": max,
                    }
                }
            }
            return {
                ...l_searchTermReq,
            }
        })
    }
    else {
        setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old)
            //For Transfer rule send different accessor of rule_name
            let p_id_value = (p_id == "rule_id" ? "rule_name" : p_id);
            if (p_term?.trim() == 0) {
                l_searchTermReq?.[p_id_value] && delete l_searchTermReq[p_id_value]
            }
            else {
                l_searchTermReq[p_id_value] = {
                    "type": p_type,
                    "value": p_term
                }
            }
            // console.log("the value of l_searchTermReq",l_searchTermReq);
            return {
                ...l_searchTermReq,
            }
        })
    }
    isFirstCall && setisFirstCall(false)

  }
  

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) {
        isFirstCall && setisFirstCall(false)
    }
    setSortReq(p_sort[0])
} 

  const updateArticleData = (
    setAllRows,
    allocationStatus,
    fromDate,
    uptoDate,
    dcInventory,
    transferStrategy,
    req
  ) => {

    setArticleConfigData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach((setAllRow) => {
        newData[setAllRow]["config_status"] = allocationStatus;
        newData[setAllRow]["from_date"] = fromDate;
        newData[setAllRow]["to_date"] = uptoDate;
        newData[setAllRow]["transfer_strategy"] = transferStrategy;
        newData[setAllRow]["dc_inv_threshold"] = dcInventory;
        // newData[setAllRow]["dc_it_week"] = dcInTransit;
      });
      return newData;
    });
    // if (!isEmpty(searchTermReq)) {
    //     req = {...req, searchColumns: searchTermReq}
    // }
    // if (!isEmpty(sortReq)) {
    //     req = {...req, sortColumn: sortReq}
    // }
    if (req?.update_type === "set_all") {
      req = {
        ...req,
        ...(level1 ? { l1_name: storefilters?.level1?.value } : {}),
      };
    }
    else if(req?.update_type === "bulk_edit"){
      // console.log("the value of storefilters",storefilters);
      req={
        ...req,
        "filters":{
          ...(level1 ? { l1_name: [storefilters?.level1?.value ] } : {}),
          ...(level2 ? { l2_name: [...(Array.isArray(storefilters?.level2) ? (storefilters?.level2?.map((val) => val.value)) : storefilters?.level2?.value ? [storefilters?.level2?.value] : [])] } : {}),
          ...(level3 ? { l3_name: [...(storefilters?.level3?.map((val) => val.value).filter((val) => val !== "*"))] } : {}),
          ...(level4 ? { l4_name: [...(storefilters?.level4?.map((val) => val.value).filter((val) => val !== "*"))] } : {}),
          ...(searchTermReq ? {searchColumns :searchTermReq} : {}),
          ...(sortReq ? {sortColumn : sortReq} : {})
        }
      }
    }
    props.updateAutoAllocationRequired(req);
  };

  const fetchData = (index) => {
    if (prevIndex >= index || nextIndex >= totalCount)
        return;
    setPrevIndex(index)
    setInitialIndex(Math.floor(nextIndex / 10))
    props.fetchUserCreatedDataStoreToStore({ ...storefilters, row_index: nextIndex, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq })
}
  return (
    <div className="container__wrapper pt-0 m-4">
      <div className="container__header m-0 p-4 align-items-end">
        <h3 className="fnt-bold m-0">Store Transfer Configuration</h3>
      </div>
      <hr className="m-0 fnt-bold" />
      <section className="row section">
        <Filter data={prep} />
        <div className="col-md-3" style={{ margin: 0, marginTop: "1rem" }}>
          <button
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            onClick={() => resetFilters("All")}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div style={{ marginTop: "3rem" }} className="col-md-3">
          <div className="required fnt-md pt-2" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </div>
      </section>

      <StoreTransferConfigTable
        data={articleConfigData}
        baseTableError={props.userCreatedError}
        baseTableloading={props.userCreatedLoading}
        setRTInstance={setRTInstance}
        saveProductStatusData={saveProductStatusData}
        updateArticleData={updateArticleData}
        fecthDataWithSearchTerm={fecthDataWithSearchTerm}
        fecthDataWithSorting={fecthDataWithSorting}
        searchTermReq={searchTermReq}
        fetchData={fetchData}
        initialPageIndex={initialIndex}
        totalCount={totalCount}
        checkAllReset={checkAllReset}
        transfer_strategy={transfer_strategy}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { storeTransfer } = state;
  return {
    level1Options: storeTransfer.level1Options,
    level2Options: storeTransfer.level2Options,
    level3Options: storeTransfer.level3Options,
    level4Options: storeTransfer.level4Options,
    level5Options: storeTransfer.level5Options,
    filterLabels: storeTransfer.filterlabels,
    userCreatedData: storeTransfer.userCreatedData,
    userCreatedError: storeTransfer.userCreatedError,
    userCreatedLoading: storeTransfer.userCreatedloading,
    updateAutoAllocationSuccess: storeTransfer.updateAutoAllocationSuccess,
    updateAutoAllocationError: storeTransfer.updateAutoAllocationError,
    totalCount: storeTransfer.totalCount,
    nextIndex: storeTransfer.nextIndex,
    transfer_strategy:storeTransfer.transfer_strategy,
    // storeLoading: storeTransfer.storeloading,
    // deleteSuccess: storeTransfer.deleteSuccess,
    // deleteError: storeTransfer.deleteError,
    // storeData: storeTransfer.storeData,
    // storeDataError: storeTransfer.storeError,
    // tabColorSizeData: storeTransfer.colourStyleData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload, filterType) =>
    dispatch(fetchFilterData(payload, filterType)),
  fetchUserCreatedDataStoreToStore: (payload) =>
    dispatch(fetchUserCreatedDataStoreToStore(payload)),
  updateAutoAllocationRequired: (payload) =>
    dispatch(updateAutoAllocationRequired(payload)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  resetAll: () => dispatch(resetAll()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreTransferConfigView);
