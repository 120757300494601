export const initialState = {
    rules: [],
    rulesLoading: false,
    rulesError: null,
    rulesSuccess: false,
    deleteRuleLoading: false,
    deleteRuleError: null,
    deleteRuleSuccess: false,
    ruleMappingData: [],
    ruleMappingLoading: false,
    ruleMappingError: null
}