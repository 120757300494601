import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageLoader from "../../../../../components/Loader/PageLoader";
import ColorIndicator from "../../../../../components/Table/Cellrenderer/ColorIndicator";
import ReactTable from "../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../../UserManagement/ColumnFilter";
import { getStores } from "../Actions/CreateStoreTransferRuleActions";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const Stores = (props) => {
  const {
    getStores,
    basicDetails,
    stores,
    selectedStoreGroups,
    storesLoading,
    selectedRows,
    setRTInstance,
    id,
  } = props;
  const [initialSelectedRows, setInitialSelectedRows] = useState({});

  const tableColumns = React.useMemo(() => [
    {
      id: "expander", // Make sure it has an ID
      Header: "Store Group",
      accessor: "store_group_name",
      Cell: ({ value, row }) => {
        const isAdded = row?.subRows?.some((item) => item.original?.is_added) ? "success" : "";
        const isDeleted = row?.subRows?.some((item) => item.original?.is_deleted) ? "danger":  "";
        const color = (isAdded && isDeleted) ? "warning" : (isAdded || isDeleted);
        return row.canExpand ? (
          <div className={`row${ color ? ` highlight_row bg-${color}`:  ""}`} {...row.getToggleRowExpandedProps()}>
            {/* <div
            className="d-flex justify-content-center align-items-center w-100 h-100"
             {...row.getToggleRowExpandedProps()}
           > */}
            <span className="expand__text">{value}</span>
            {/* {flag ? (
              <ColorIndicator
                value={value}
                flag={flag}
                color={"green"}
                className="expand__text"
              />
            ) : (
              <span className="expand__text">{value}</span>
            )} */}

            {row.isExpanded ? (
              <div className="cursor-pointer">
                <i
                  // style={flag ? { color: "white" } : {}}
                  className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                  title="Collpase"
                ></i>
              </div>
            ) : (
              <div className="cursor-pointer">
                <i
                  // style={flag ? { color: "white" } : {}}
                  className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                  title="Expand"
                ></i>
              </div>
            )}
          </div>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        );
      },
      width: 250,
      Filter: ColumnFilter,
      // disableFilters: true,
    },
    {
      Header: "#Stores",
      accessor: "store_count",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 100,
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Store Number",
      accessor: "store_code",
      Cell: ({ value, row }) => (
        <ColorIndicator
          value={value}
          flag={row?.original?.is_added || row?.original?.is_deleted}
          color={row?.original?.is_added ? "#28a745" : "#dc3545"}
        />
      ),
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Store Name",
      accessor: "store_name",
      Cell: ({ value, row }) => (
        <ColorIndicator
          value={value}
          flag={row?.original?.is_added || row?.original?.is_deleted}
          color={row?.original?.is_added ? "#28a745" : "#dc3545"}
        />
      ),
      Filter: ColumnFilter,
      width: 200,
      disableFilters: true,
    },
    {
      Header: "Region Description",
      Cell: ({ value, row }) => (
        <ColorIndicator
          value={value}
          flag={row?.original?.is_added || row?.original?.is_deleted}
          color={row?.original?.is_added ? "#28a745" : "#dc3545"}
        />
      ),
      accessor: "region",
      width: 250,
      Filter: ColumnFilter,
    },
  ]);

  useEffect(() => {
    const req = {
      ...basicDetails,
    };
    if (id) {
      id && (req["rule_id"] = id);
    }
    !stores?.length && getStores(req);
  }, []);

  useEffect(() => {
    setInitialSelectedRows(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    let selectedGroups = [];
    let allGroupCodes = [];
    let groupsMap = {};
    let selectedIds = {};
    if (selectedStoreGroups?.length && isEmpty(selectedRows)) {
      selectedStoreGroups.forEach((item) => {
        selectedGroups.push({
          store_group_code: item.store_group_code,
          stores: item.subRows.map((store) => store.store_code),
        });
      });
      stores.forEach((item) => {
        allGroupCodes.push(item.store_group_code);
        groupsMap[item.store_group_code] = item.subRows.map(
          (store) => store.store_code
        );
      });

      selectedGroups.forEach((group) => {
        let index = allGroupCodes.indexOf(group.store_group_code);
        if (index >= 0) {
          selectedIds[index] = true;
          group.stores.forEach((store) => {
            let subIndex = groupsMap[group.store_group_code].indexOf(store);
            if (subIndex >= 0) {
              selectedIds[`${index}.${subIndex}`] = true;
            }
          });
        }
      });
      setInitialSelectedRows(selectedIds);
    }
  }, [selectedStoreGroups]);

  // useEffect(() => {
  //   return () => {
  //     const { getSelectedRows, rowsById } = RTInstance;
  //     let stores = {};
  //     getSelectedRows?.forEach((id) => {
  //       const { store_group_code, store_code } = rowsById[id].original;
  //       if (rowsById[id].depth === 1) {
  //         if (stores[store_group_code]) {
  //           stores[store_group_code] = [
  //             ...stores[store_group_code],
  //             store_code,
  //           ];
  //         } else {
  //           stores[store_group_code] = [store_code];
  //         }
  //       }
  //     });
  //     storeValues({ stores });
  //   };
  // });

  return (
    <PageLoader loader={storesLoading} gridLoader={true}>
      {/* <section className="row section"> */}
      <ReactTableWithPlugins
        // shouldPagination
        hideColumnsFilter
        totalRecordsLen={stores ? stores.length : 0}
        data={stores ? stores : []}
        columns={tableColumns}
        // features={!props?.isAddActionColumn ? ["CHECKBOX_FOR_FIRSTCOLUMN"] : []}
        features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
        renderMarkup="TableMarkup"
        keyRT={"storeMapping"}
        containSubRow={true}
        initialSelectedRows={initialSelectedRows}
        tableWrapperStyle={{ height: "fit-content", maxHeight: "30rem" }}
        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: 4 }}
        getInstanceOnMount={(instance) => {
          setRTInstance(instance);
        }}
        tableId="store_transfer_rule_store_group"
        showCheckboxAllRows={true}
      />
      {/* </section> */}
      {id && stores?.length ? (
        <div>
          <div className="mt-3 d-flex align-items-center">
            <div className="indicate-div bg-success"></div>
            <span className="ml-2 font-italic mb-0"> Newly Added Store</span>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <div className="indicate-div bg-danger"></div>
            <span className="ml-2 font-italic mb-0"> Deleted Store</span>
          </div>
        </div>
      ) : ""}
    </PageLoader>
  );
};

const mapStateToProps = ({ createRule }) => ({
  basicDetails: createRule.basicDetails,
  stores: createRule.stores?.store_groups,
  storesLoading: createRule.storesLoading,
  selectedRows: createRule.selectedRows,
  selectedStoreGroups: createRule.stores?.selected_store_groups,
});

const mapDispatchToProps = (dispatch) => ({
  getStores: (payload) => dispatch(getStores(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stores);
