import React, { useState, useEffect, useRef } from "react";
import ReactTable from "../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../components/Table/WithPlugins";
import { connect } from "react-redux";
import { scrollIntoView } from "../../../../../utils/commonUtilities";
import { ERROR_MSG } from "../../../../../constants/globalConstant";
import NumberRangeColumnFilter from "../../../../../components/Filters/NumberRangeColumnFilter.jsx";
import PageLoader from "../../../../../components/Loader/PageLoader.jsx";
import { levelFiltersLabelFormatter } from "../../../../../utils/filterLevelMapping";
import { fetchStoreViewDetailsS2S } from "./Actions/StoreViewS2SActions";
import ColumnFilter from "../../../../UserManagement/ColumnFilter.jsx";
import ExportFile from "../../../../../components/ExportFile/ExportFile.jsx";
import {RELEASE_DATE_FORMAT} from "../DailySummaryConstants"
import moment from "moment";
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function StoreViewDetailsS2S(props) {
  const {storeDetails,fetchStoreViewDetailsS2S,setSelectedDestStore,selectedDestStore,clickedArticle,loading,error,storeExcelData} = props;
  const tableRef = useRef();
  const StoreTableColumnsS2S = [
    {
      Header: "Filters",
      identify: "dcSplitParent",
      columns: [
        {
          Header: levelFiltersLabelFormatter("level1"),
          accessor: "l1_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
          width: 200,
        },
        {
          Header: levelFiltersLabelFormatter("level2"),
          accessor: "l2_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
          width: 200,
        },
        {
          Header: levelFiltersLabelFormatter("level3"),
          accessor: "l3_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
        },
        {
          Header: levelFiltersLabelFormatter("level4"),
          accessor: "l4_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
        },
        {
          Header: levelFiltersLabelFormatter("level5"),
          width: 300,
          accessor: "l5_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
        },
        {
          Header: levelFiltersLabelFormatter("level6"),
          accessor: "l6_name",
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
            row.canExpand ? (
              <span>{value}</span>
            ) : (
              <span
                style={{
                  backgroundColor: "rgb(224,224,224)",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              >
                {" "}
              </span>
            ),
        },
      ],
    },
    {
      Header: "Style",
      accessor: "style",
      Filter: ColumnFilter,
      width: 200,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "Color",
      accessor: "color",
      Filter: ColumnFilter,
      width: 200,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "Style Description",
      accessor: "style_description",
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 250,
    },
    {
      Header: "Style Color",
      accessor: "style_color",
      Filter: ColumnFilter,
      width: 200,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "Global Color",
      accessor: "global_color",
      Filter: ColumnFilter,
      width: 200,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "Source Store",
      accessor: "source_store",
      Filter: ColumnFilter,
      width: 250,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      Header: "Source Store Name",
      accessor: "source_store_name",
      Filter: ColumnFilter,
      width: 250,
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
    },
    {
      id: "expander",
      Header: "Style Color ID",
      accessor: "article",
      width: 200,
      Cell: ({ value, row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <div className="row" {...row.getToggleRowExpandedProps()}>
            <span className="expand__text__number">{value}</span>
            {row.isExpanded ? (
              <div className="cursor-pointer">
                <i
                  className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                  title="Collpase"
                ></i>
              </div>
            ) : (
              <div className="cursor-pointer">
                <i
                  className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                  title="Expand"
                ></i>
              </div>
            )}
          </div>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      Filter: ColumnFilter,
    },
    {
      Header: "Sizes",
      accessor: "size",
      width: 200,
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
    },
    {
      Header: "Plan Name",
      accessor: "plan_name",

      width: 250,
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows",
    },
    {
      Header: "Released at",
      accessor: "released_at",
      Cell: ({ value, row }) => {
        return row.depth === 1
          ? moment(value).format(RELEASE_DATE_FORMAT)
          : "";
      },
      width: 300,
      disableFilters: true,
    },
    {
      Header: "Units Transferred",
      columns: [
        {
          Header: "Transfer Units",
          accessor: "transfer_units",
          width: 200,
          Filter: (instance) => (
            <NumberRangeColumnFilter {...instance} containSubRows={true} />
          ),
          filter: "filterNumberWithSubRows",
        },
      ],
    },
    {
      Header: "Pre Transfer Status",
      columns: [
        {
          Header: "Source Inventory",
          accessor: "source_inventory",
          width: 150,
          Filter: (instance) => (
            <NumberRangeColumnFilter {...instance} containSubRows={true} />
          ),
          filter: "filterNumberWithSubRows",
        },
        {
          Header: "Source WOS",
          accessor: "source_wos",
          width: 150,
          Filter: (instance) => (
            <NumberRangeColumnFilter {...instance} containSubRows={true} />
          ),
          filter: "filterNumberWithSubRows",
        },
        {
          Header: "Dest. Inventory",
          accessor: "destination_inventory",
          width: 150,
          Filter: (instance) => (
            <NumberRangeColumnFilter {...instance} containSubRows={true} />
          ),
          filter: "filterNumberWithSubRows",
        },
        {
          Header: "Dest. WOS",
          accessor: "dest_wos",
          width: 150,
          Filter: (instance) => (
            <NumberRangeColumnFilter {...instance} containSubRows={true} />
          ),
          filter: "filterNumberWithSubRows",
        },
      ],
    },
  ];
  const validateData = (data) => Array.isArray(data) && data.length > 0;
  useEffect(() => {
    if (storeDetails?.length) scrollIntoView(tableRef);
    // setStoreDetails(props.storeDetails);
  }, [storeDetails]);

  useEffect(() => {
    if (selectedDestStore) {
      const req = {
        ...props.req,
        destination_store: [
          { label: clickedArticle, value: clickedArticle },
        ],
      };
      fetchStoreViewDetailsS2S({ request: req });
      setSelectedDestStore(false);
    }
  }, [props.req, selectedDestStore]);
  return (
    <>
      <div>
        <PageLoader loader={loading} gridLoader={true}>
          {error ? (
            <div className="error">{error || ERROR_MSG}</div>
          ) : (
            validateData(storeDetails) && (
              <>
                <div style={{ paddingTop: "3rem" }} ref={tableRef}>
                  {storeDetails.length ? (
                    <div className="deepdive__export__wrapper">
                      <ExportFile
                        text={"Excel"}
                        data={storeExcelData}
                        sheetName={"Daily Allocation Summary S2S"}
                        fileName={`daily_summary_S2S_Store_details_${new Date().getTime()}`}
                        type={1}
                      />
                    </div>
                  ) : null}
                  <ReactTableWithPlugins
                    columns={StoreTableColumnsS2S}
                    data={storeDetails.length ? storeDetails : []}
                    renderMarkup="TableMarkup"
                    keyRT="sortAndSearch"
                    totalRecordsLen={storeDetails?.length || 0}
                    tableId="StoreDailySummaryS2S"
                    tableWrapperStyle={{
                      height: "fit-content",
                      maxHeight: "50rem",
                    }}
                    headerWrapperStyle={{
                      position: "sticky",
                      top: 0,
                      zIndex: "4",
                    }}
                    containSubRow={true}
                    embededScroll
                  />
                </div>
              </>
            )
          )}
        </PageLoader>
      </div>
    </>
  );
}

const mapStateToProps = ({ dailyStoreViewS2S }) => ({
  error: dailyStoreViewS2S.storeDetailsError,
  loading: dailyStoreViewS2S.storeDetailsLoading,
  storeDetails: dailyStoreViewS2S.storeDetails,
  storeExcelData: dailyStoreViewS2S.storeExcelData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStoreViewDetailsS2S: (payload) =>
    dispatch(fetchStoreViewDetailsS2S(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreViewDetailsS2S);
