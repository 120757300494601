export const initialState = {
    productSummaryData: [],
    productSummaryLoading: false,
    productSummaryError: null,
    productSummarySuccess: false,
    productStoreData: [],
    productStoreLoading: false,
    productStoreError: null,
    productStoreSuccess: false,
    updateTransferUnitsLoading: false,
    updateTransferUnitsData: false,
    updateTransferUnitsError: false,
    allocationCode:null,
}