import React, { useState, useEffect } from "react";
import ReactTable from "../../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../../components/Table/WithPlugins";
import PageLoader from "../../../../../../components/Loader/PageLoader";
import ColumnFilter from "../../../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../../../components/Filters/NumberRangeColumnFilter";
import InputCell from "../../../../../../components/Table/Cellrenderer/InputCell";
import { connect } from "react-redux";
import { updateTransferUnitsForMultipleStores, resetAll } from "../Actions/Actions";
import * as Notify from "../../../../../../components/Notification/Notifications";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreProductViewTable = (props) => {
  const { data, loading, store, viewAllocationCallS2S,showFinalizeButton } = props;
  const [RTInstance, setRTInstance] = useState(null);
  const [EditedAllocationIds, setEditedAllocationIds] = useState({});


  const COLUMNS = [
    {
      Header: " ",
      sticky: "left",
      columns: [{
      Header: "Source",
      accessor: "source_store",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
      filter: "bulkFilterCommaSeperated"
    },
    {
      Header: "Source Name",
      accessor: "source_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 250,
      Filter: ColumnFilter
      }]
    },
    {
      Header: "Style Description",
      accessor: "style_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 200,
      Filter: ColumnFilter
    },
    {
      Header: "Channel",
      accessor: "l1_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      width: 200,
      Filter: ColumnFilter
    },
    {
      Header: "Department",
      accessor: "l2_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      Filter: ColumnFilter
    },
    {
      Header: "Class",
      accessor: "l3_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span>{value}</span>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        ),
      Filter: ColumnFilter,
    },
    {
      id: "expander", // Make sure it has an ID
      Header: "Style Color",
      accessor: "article",
      Cell: ({ value, row }) => {
        return row.canExpand ? (
          <div className="row" {...row.getToggleRowExpandedProps()}>
            <span className="expand__text">{value}</span>
            {row.isExpanded ? (
              <div className="cursor-pointer" style={{ color: "#50778e" }}>
                <i
                  className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                  title="Collpase"
                ></i>
              </div>
            ) : (
              <div className="cursor-pointer" style={{ color: "#50778e" }}>
                <i
                  className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                  title="Expand"
                ></i>
              </div>
            )}
          </div>
        ) : (
          <span
            style={{
              backgroundColor: "rgb(224,224,224)",
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            {" "}
          </span>
        );
      },
      width: 200,
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
      filter: "bulkFilterCommaSeperated"
    },
    {
      Header: "Size",
      accessor: "size",
      width: 100,
      Filter: ColumnFilter,
      filter: "filterRowsWithSubrows"
    },
    {
      Header: "Pre Transfer Status",
      columns: [
        {
          Header: "Source WOS",
          accessor: "pre_source_wos",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Destination WOS",
          accessor: "pre_dest_wos",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Source Inv(OH)",
          accessor: "pre_source_inv",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Destination Inv(OH+OO+IT)",
          accessor: "pre_dest_inv",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
      ],
    },
    {
      Header: "Transfer Units",
      accessor: "transfer_units",
      Cell: (instance) => {
        // return instance?.row?.depth ? (
        if (instance?.row?.depth) {
          const row_id = instance.row.id.split(".");
          const { post_source_inv } = instance.row.original;
          if (!viewAllocationCallS2S && showFinalizeButton) {
            return <InputCell
              {...instance}
              style={{ textAlign: "center" }}
              min={0}
              max={data[Number(row_id[0])]?.subRows[Number(row_id[1])]?.transfer_units + post_source_inv}
              type="number"
              step={1}
              roundOffOnChange
              // blurHandler={(
              //   rowIdx,
              //   columnId,
              //   values,
              //   row_id,
              //   temp_id,
              //   row,
              //   focusedValue
              // ) => {
              //   setAvailableData((old) => {
              //     return [
              //       {
              //         ...old[0],
              //         packs_available:
              //           (Number(old[0]["packs_available"]) || 0) -
              //           (Number(values) - Number(focusedValue)),
              //       },
              //     ];
              //   });
              // }}
              // changeHandler={(rowIdx, columnId, values, row_id) => {
              //   console.log("🚀 ~ StoreProductViewTable ~ rowIdx:", rowIdx, columnId, values, row_id)


              //   instance.updateMyData(
              //     rowIdx,
              //     "net_avai_to_transfer",
              //     net_avai_to_transfer - (Number(values) - transfer_units),
              //     row_id
              //   );
              // }}

              blurHandler={(rowIdx, columnId, values, row_id) => {
                setEditedAllocationIds(old => {
                  if (old[parseInt(row_id)]) {
                    return {
                      ...old,
                      [parseInt(row_id)]: [...new Set([...old[parseInt(row_id)], row_id])]
                    }
                  } else {
                    return {
                      ...old,
                      [parseInt(row_id)]: [row_id]
                    }
                  }
                });
              }

              }
            />
          } else {
            // Render non-editable cell when viewAllocationCallS2S is true
            return <div style={{ textAlign: "center" }}>{instance.value}</div>;
          }
        } else {
          return instance.value;
        }

      },
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Net Available",
      accessor: "net_avai_to_transfer",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Net Required",
      accessor: "net_req_in_dest",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Post Transfer Status",
      columns: [
        {
          Header: "Source WOS",
          accessor: "post_source_wos",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Destination WOS",
          accessor: "post_dest_wos",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Source Inv",
          accessor: "post_source_inv",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
        {
          Header: "Destination Inv",
          accessor: "post_dest_inv",
          Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
          filter: "filterNumberWithSubRows"
        },
      ],
    },
    {
      Header: "Destination Min",
      accessor: "dest_min",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Destination Max",
      accessor: "dest_max",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Source Min",
      accessor: "source_min",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
    {
      Header: "Source Max",
      accessor: "source_max",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} containSubRows={true} />,
      filter: "filterNumberWithSubRows"
    },
  ];

  const onSave = () => {
    let request = {};
    const requestPayload = Object.keys(EditedAllocationIds).map((id) => {
      let article_index = RTInstance.flatRows.findIndex((item) => item.id === id);     
      const allocatedQuantityObj = EditedAllocationIds[id].reduce((acc, i) => {
        const indexOfEditedItem = Number(article_index) + Number(i.split(".").slice(1)[0]) + 1;
        return {
          ...acc,
          [RTInstance.flatRows?.[indexOfEditedItem].original?.size]: Number(RTInstance.flatRows?.[indexOfEditedItem].original?.transfer_units)
        }
      }, {})

      const availableQuantityObj = EditedAllocationIds[id].reduce((acc, i) => {
        const indexOfEditedItem = Number(article_index) + Number(i.split(".").slice(1)[0]) + 1;
        return {
          ...acc,
          [RTInstance.flatRows?.[indexOfEditedItem].original?.size]: RTInstance.flatRows?.[indexOfEditedItem].original?.net_avai_to_transfer
        }
      }, {})


      return {
        "source_store": RTInstance.flatRows?.[article_index].original?.source_store,
        "destination_store": RTInstance.flatRows?.[article_index].original?.destination_store,
        "article": RTInstance.flatRows?.[article_index].original?.article,
        "allocated_quantity": {
          ...allocatedQuantityObj
        },
        "available_quantity": {
          ...availableQuantityObj
        }
      }
    })
    let { originalAllocationCode, allocationCode } = props
    let l_original_allocation_code = allocationCode === originalAllocationCode ? "" : originalAllocationCode
    request["original_allocation_code"] = l_original_allocation_code;
    request["allocation_code"] = allocationCode;
    request["updated_transfer_units"] = [ ...requestPayload ];
    props.updateTransferUnitsForMultipleStores(request);
  }

  useEffect(() => {
    props.resetAll();
  },[])

  useEffect(() => {
    if (props.updateTransferUnitsData) {
      setEditedAllocationIds({})
      Notify.success("Allocated Units Edited Sucessfully!!");
      props.resetAll();
      props.callAllApis();
    }
  }, [props.updateTransferUnitsData])

  useEffect(() => {
    if (props.updateTransferUnitsError) {
      setEditedAllocationIds({})
      Notify.error("Error in Editing Allocated Units")
    }
  }, [props.updateTransferUnitsError])


  return (
    <PageLoader loader={loading || props.updateTransferUnitsLoading} gridLoader={true}>
      <div className="storesStore__heading">
        <h1 className="fnt-md fnt-bold">Destination - {store}</h1>
      </div>
      <ReactTableWithPlugins
        containSubRow
        // shouldPagination
        embededScroll
        totalRecordsLen={data ? data.length : 0}
        data={data ? data : []}
        columns={COLUMNS}
        renderMarkup="TableMarkup"
        keyRT={"sortAndSearch"}
        tableId="sts_store_product_view"
        getInstanceOnMount={(instance) => {
          setRTInstance(instance)
        }}
        tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
        isHideCount={true}
        hideDropdown
      />

      {
      !viewAllocationCallS2S &&  showFinalizeButton && (<div className="row justify-content-center">
        <button
          onClick={onSave}
          className="btn btn-primary products_lower_Div_button"
          disabled={!(Object.keys(EditedAllocationIds).length > 0)}
        >
          Save Edit
        </button>
      </div>)
      }
    </PageLoader>
  );
};

const mapStateToProps = ({ stsStoreView }) => {
  return {
    updateTransferUnitsLoading: stsStoreView.updateTransferUnitsLoading,
    updateTransferUnitsData: stsStoreView.updateTransferUnitsData,
    updateTransferUnitsError: stsStoreView.updateTransferUnitsError,
    // allocationStatus: finalize.allocationStatus
  }
}

const mapDispatchToProps = (dispatch) => ({
  // getPackDetails: (payload) => dispatch(getPackDetails(payload)),
  updateTransferUnitsForMultipleStores: (payload) => dispatch(updateTransferUnitsForMultipleStores(payload)),
  resetAll:() => dispatch(resetAll()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreProductViewTable);
