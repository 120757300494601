export const FETCH_FILTER_DATA = "@@storetransferrule/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@storetransferrule/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@storetransferrule/FETCH_FILTER_ERROR";

export const GET_STORES = "@@storetransferrule/GET_STORES";
export const GET_STORES_SUCCESS = "@@storetransferrule/GET_STORES_SUCCESS";
export const GET_STORES_ERROR = "@@storetransferrule/GET_STORES_ERROR";

export const GET_STORE_MAPPING = "@@storetransferrule/GET_STORE_MAPPING";
export const GET_STORE_MAPPING_SUCCESS = "@@storetransferrule/GET_STORE_MAPPING_SUCCESS";
export const GET_STORE_MAPPING_ERROR = "@@storetransferrule/GET_STORE_MAPPING_ERROR";

export const CREATE_STORE_TRANSFER_RULE = "@@storetransferrule/CREATE_STORE_TRANSFER_RULE";
export const CREATE_STORE_TRANSFER_RULE_SUCCESS = "@@storetransferrule/CREATE_STORE_TRANSFER_RULE_SUCCESS";
export const CREATE_STORE_TRANSFER_RULE_ERROR = "@@storetransferrule/CREATE_STORE_TRANSFER_RULE_ERROR";

export const VALIDATE_RULE_NAME = "@@storetransferrule/VALIDATE_RULE_NAME";
export const VALIDATE_RULE_NAME_SUCCESS = "@@storetransferrule/VALIDATE_RULE_NAME_SUCCESS";
export const VALIDATE_RULE_NAME_ERROR = "@@storetransferrule/VALIDATE_RULE_NAME_ERROR";

export const STORE_VALUES = "@@storetransferrule/STORE_VALUES";

export const RESET_STEP_1 = "@@storetransferrule/RESET_STEP_1";

export const RESET_STEP_2 = "@@storetransferrule/RESET_STEP_2";

export const RESET_ALL = "@@storetransferrule/RESET_ALL";
