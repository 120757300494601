import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProductViewSummary, getProductStoreView } from "../Actions/Actions";
import ProductViewTable from "./ProductViewTable";
import ProductStoreViewTable from "./ProductStoreViewTable";

const ProductView = (props) => {

  const [article, setArticle] = useState();
  const [selectedStyleColor, setStyleColor] = useState();

  const {
    getProductViewSummary,
    getProductStoreView,
    activeTab,
    productStoreData,
    sizes,
    productStoreLoading,
    productStoreError,
    allocationCode,
    originalAllocationCode,
    viewAllocationCallS2S,
    showFinalizeButton
  } = props;

  useEffect(() => {
    activeTab === "product-view" &&
    getProductViewSummary({
        allocationcode: allocationCode
      });
  }, [activeTab]);

  const getStores = (article) => {
    setArticle(article);
    getProductStoreView({
      allocationcode: allocationCode,
      article,
    });
  };

  const callAllProductApis = () => {
    getProductStoreView({
      allocationcode: allocationCode,
      article: selectedStyleColor,
    });
    getProductViewSummary({
      allocationcode: allocationCode
    });
}
  return (
    <>
      <ProductViewTable {...props} getStores={getStores} setStyleColor={setStyleColor} />
      {productStoreError ? (
        <div className="error">{productStoreError}</div>
      ) : productStoreData?.length || productStoreLoading ? (
        <ProductStoreViewTable
          data={productStoreData}
          loading={productStoreLoading}
          article={article}
          allocationCode={allocationCode}
          originalAllocationCode={originalAllocationCode}
          callAllApis={callAllProductApis}
          viewAllocationCallS2S={viewAllocationCallS2S}
          showFinalizeButton={showFinalizeButton}
          sizes={sizes}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ stsProductView }) => ({
  data: stsProductView.productSummaryData,
  loading: stsProductView.productSummaryLoading,
  error: stsProductView.productSummaryError,
  success: stsProductView.productSummarySuccess,
  productStoreData: stsProductView.productStoreData?.data,
  sizes: stsProductView.productStoreData?.size_data,
  productStoreLoading: stsProductView.productStoreLoading,
  productStoreError: stsProductView.productStoreError,
  productStoreSuccess: stsProductView.productStoreSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProductViewSummary: (payload) => dispatch(getProductViewSummary(payload)),
  getProductStoreView: (payload) => dispatch(getProductStoreView(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
