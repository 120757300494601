import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";
import * as actionTypes from "../Actions/ActionTypes";

export default {
  createRule: handleActions(
    {
      [actionTypes.FETCH_FILTER_DATA]: (state, { payload }) => {
        return immutable(state, {
          isFilterLoading: { $set: true },
          // filters: { $set: {} },
          // filterLabels: { $set: payload.data && payload.filterMapping ? payload.filterMapping : state.filterMapping },
        });
      },
      [actionTypes.FETCH_FILTER_SUCCESS]: (state, payload) => {
        return immutable(state, {
          isFilterLoading: { $set: true },
          filters: { $set: { ...state.filters, ...payload?.data } },
          filterLabels: {
            $set:
              payload?.data && payload?.filterMapping
                ? payload.filterMapping
                : state.filterMapping,
          },
        });
      },
      [actionTypes.GET_STORES]: (state, payload) => {
        return immutable(state, {
          storesLoading: { $set: true },
          storesError: { $set: null },
          stores: { $set: [] },
        });
      },
      [actionTypes.GET_STORES_SUCCESS]: (state, payload) => {
        return immutable(state, {
          storesLoading: { $set: false },
          storesError: { $set: null },
          stores: { $set: payload?.data },
        });
      },
      [actionTypes.GET_STORES_ERROR]: (state, payload) => {
        return immutable(state, {
          storesLoading: { $set: false },
          storesError: { $set: (payload && payload.error) || null },
          stores: { $set: [] },
        });
      },
      [actionTypes.STORE_VALUES]: (state, { payload }) => {
        return immutable(state, {
          basicDetails: { $set: payload?.data || state.basicDetails },
          selectedStores: { $set: payload?.stores || state.selectedStores },
          selectedRows: { $set: payload?.selectedRows || state.selectedRows },
          stores: { $set: payload?.data ? [] : state.stores },
        });
      },
      [actionTypes.GET_STORE_MAPPING]: (state, payload) => {
        return immutable(state, {
          storeMappingLoading: { $set: true },
          storeMappingError: { $set: null },
          storeMapping: { $set: [] },
        });
      },
      [actionTypes.GET_STORE_MAPPING_SUCCESS]: (state, payload) => {
        return immutable(state, {
          storeMappingLoading: { $set: false },
          storeMappingError: { $set: null },
          storeMapping: { $set: payload?.data },
        });
      },
      [actionTypes.GET_STORE_MAPPING_ERROR]: (state, payload) => {
        return immutable(state, {
          storeMappingLoading: { $set: false },
          storeMappingError: { $set: (payload && payload.error) || null },
          storeMapping: { $set: [] },
        });
      },
      [actionTypes.CREATE_STORE_TRANSFER_RULE]: (state, payload) => {
        return immutable(state, {
          createRuleLoading: { $set: true },
          createRuleError: { $set: null },
          createRuleSuccess: { $set: false },
        });
      },
      [actionTypes.CREATE_STORE_TRANSFER_RULE_SUCCESS]: (state, payload) => {
        return immutable(state, {
          createRuleLoading: { $set: false },
          createRuleError: { $set: null },
          createRuleSuccess: { $set: true },
        });
      },
      [actionTypes.CREATE_STORE_TRANSFER_RULE_ERROR]: (state, payload) => {
        return immutable(state, {
          createRuleLoading: { $set: false },
          createRuleError: { $set: (payload && payload.error) || null },
          createRuleSuccess: { $set: false },
        });
      },
      [actionTypes.VALIDATE_RULE_NAME]: (state) => {
        return immutable(state, {
          validateLoading: { $set: true },
          validateError: { $set: null },
          validateSuccess: { $set: false },
        });
      },
      [actionTypes.VALIDATE_RULE_NAME_SUCCESS]: (state, payload) => {
        return immutable(state, {
          validateLoading: { $set: false },
          validateError: { $set: null },
          validateSuccess: { $set: true },
        });
      },
      [actionTypes.VALIDATE_RULE_NAME_ERROR]: (state, payload) => {
        return immutable(state, {
          validateLoading: { $set: false },
          validateError: { $set: (payload && payload.error) || null },
          validateSuccess: { $set: false },
        });
      },
      [actionTypes.RESET_STEP_1]: (state) => {
        return immutable(state, {
          stores: { $set: [] },
          selectedRows: { $set: {} },
          storesLoading: { $set: false },
          storesError: { $set: null },
          selectedStores: { $set: {} },
          storeMapping: { $set: [] },
          storeMappingLoading: { $set: false },
          storeMappingError: { $set: null },
          validateLoading: { $set: false },
          validateSuccess: { $set: false },
          validateError: { $set: null }
        });
      },
      [actionTypes.RESET_STEP_2]: (state) => {
        return immutable(state, {
          storeMapping: { $set: [] },
          storeMappingLoading: { $set: false },
          storeMappingError: { $set: null },
          createRuleLoading: { $set: false },
          createRuleError: { $set: null },
          createRuleSuccess: { $set: false },
        });
      },
      [actionTypes.RESET_ALL]: (state) => {
        return immutable(state, {
          isFilterLoading: { $set: false },
          filters: { $set: {} },
          stores: { $set: [] },
          storesLoading: { $set: false },
          storesError: { $set: null },
          basicDetails: { $set: {} },
          transfer: { $set: null },
          distance: { $set: null },
          ruleName: { $set: "" },
          selectedStores: { $set: {} },
          storeMapping: { $set: [] },
          storeMappingLoading: { $set: false },
          storeMappingError: { $set: null },
          createRuleLoading: { $set: false },
          createRuleError: { $set: null },
          createRuleSuccess: { $set: false },
          validateLoading: { $set: false },
          validateSuccess: { $set: false },
          validateError: { $set: null },
          selectedRows: { $set: {} }
        });
      },
    },
    initialState
  ),
};
