import * as actionTypes from "../Actions/ActionTypes";
import { dailySummaryStoreS2SInitState } from "./InitialState";
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    dailyStoreViewS2S: handleActions(
        {
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW]: (state) => {
                return immutable(state, {
                    storesData: { $set: [] },
                    storesDataLoading: { $set: true },
                    storesDataError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storesData: { $set: payload.data },
                    storesDataLoading: { $set: false },
                    storesDataError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR]: (state, payload) => {
                return immutable(state, {
                    storesData: { $set: [] },
                    storesDataLoading: { $set: false },
                    storesDataError: { $set: (payload && payload.error) || null },
                })
            },

            [actionTypes.FETCH_STORE_DETAILS_TABLE_DATA]: (state) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: true },
                    storeDetailsError: { $set: null },
                    storeExcelData: { $set: [] },
                })
            },
            [actionTypes.FETCH_STORE_DETAILS_TABLE_DATA_SUCCESS]: (state, {payload}) => {
                return immutable(state, {
                    storeDetails: { $set: payload.data },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: null },
                    storeExcelData: { $set: (payload && payload?.excelData) || [] },
                })
            },
            [actionTypes.FETCH_STORE_DETAILS_TABLE_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: (payload && payload.error) || null },
                    storeExcelData: { $set: [] },
                })
            },
            [actionTypes.RESET_DAILY_SUMMARY_STORE_VIEW_DATA]: (state) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: null },
                    storesData: { $set: [] },
                    storesDataLoading: { $set: false },
                    storesDataError: { $set: null },
                    storeExcelData: { $set: [] },
                })
            },
        },
        dailySummaryStoreS2SInitState
    )
}

