export const FETCH_FILTER_DATA = "@@DAILYSUMMARYS2S/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@DAILYSUMMARYS2S/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@DAILYSUMMARYS2S/FETCH_FILTER_ERROR";

export const FETCH_TABLE_DATA = "@@DAILYSUMMARYS2S/FETCH_TABLE_DATA";
export const FETCH_TABLE_DATA_SUCCESS = "@@DAILYSUMMARYS2S/FETCH_TABLE_DATA_SUCCESS";
export const FETCH_TABLE_DATA_ERROR = "@@DAILYSUMMARYS2S/FETCH_TABLE_DATA_ERROR";


export const FETCH_SKU_TABLE_DATA = "@@DAILYSUMMARYS2S/FETCH_SKU_TABLE_DATA";
export const FETCH_SKU_TABLE_DATA_SUCCESS = "@@DAILYSUMMARYS2S/FETCH_SKU_TABLE_DATA_SUCCESS";
export const FETCH_SKU_TABLE_DATA_ERROR = "@@DAILYSUMMARYS2S/FETCH_SKU_TABLE_DATA_ERROR";

export const RESET_FILTERS_DATA = "@@DAILYSUMMARYS2S/RESET_FILTERS_DATA";



export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
})

export const fetchDailySummaryData = (payload) => ({
  type: FETCH_TABLE_DATA,
  payload,
});

export const fetchDailySummaryDataSuccess = (payload) => ({
  type: FETCH_TABLE_DATA_SUCCESS,
  payload,
});

export const fetchDailySummaryDataError = (payload) => ({
  type: FETCH_TABLE_DATA_ERROR,
  payload,
});


export const fetchFilterData = (payload,filterKey,filterType) => ({
    type: FETCH_FILTER_DATA,
    payload,
    filterKey,
    filterType
  });

export const fetchFilterDataSuccesss = (payload) => ({
    type: FETCH_FILTER_SUCCESS,
    payload,
  });
  
export const fetchFilterDataError = (payload) => ({
    type: FETCH_FILTER_ERROR,
    payload,
  });

export const fetchSKUDataS2S = (payload) => ({
    type: FETCH_SKU_TABLE_DATA,
    payload,
  });
  
export const fetchSKUDataS2SSuccess = (payload) => ({
    type: FETCH_SKU_TABLE_DATA_SUCCESS,
    payload,
  });
  
export const fetchSKUDataS2SError = (payload) => ({
    type: FETCH_SKU_TABLE_DATA_ERROR,
    payload,
  });