import {
  DOWNLOAD_CSV_BEFORE_FINALIZE,
  DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS,
  DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
  RESET_ALL,
  UPDATE_S2S_FINALIZE_SUCCESS,
  UPDATE_S2S_FINALIZE,
  UPDATE_S2S_FINALIZE_ERROR,
  RESET_ALL_FINALIZE,
  GET_PAST_ALLOCATION_PO_S2S,
  GET_PAST_ALLOCATION_PO_SUCCESS_S2S,
  GET_PAST_ALLOCATION_PO_ERROR_S2S,
  DOWNLOAD_CSV_SUCCESS,
  DOWNLOAD_CSV_ERROR,
  DOWNLOAD_CSV,
  DISABLE_EDIT,
  DISABLE_EDIT_ERROR,
  DISABLE_EDIT_SUCCESS,
  FINALIZE_ALLOCATION,
  FINALIZE_ALLOCATION_SUCCESS,
  FINALIZE_ALLOCATION_ERROR,
  UPDATE_STORE_TRANSFER_QTY_ERROR,
  UPDATE_STORE_TRANSFER_QTY_SUCCESS,
  UPDATE_STORE_TRANSFER_QTY,
} from "../Actions/ActionTypes";
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";

export default {
  stsFinalize: handleActions(
    {
      [DOWNLOAD_CSV_BEFORE_FINALIZE]: (state) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: true },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeHeader: { $set: [] },
        });
      },
      [DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS]: (state, payload) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeError: { $set: null },
          csvBeforeFinalizeData: { $set: (payload && payload.data) || [] },
          csvBeforeFinalizeHeader: { $set: (payload && payload.headers) || [] },
        });
      },
      [DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR]: (state, payload) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeError: { $set: (payload && payload.error) || null },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeHeader: { $set: [] },
        });
      },
      [RESET_ALL]: (state) => {
        return immutable(state, {
          csvBeforeFinalizeLoading: { $set: false },
          csvBeforeFinalizeData: { $set: [] },
          csvBeforeFinalizeError: { $set: null },
          csvBeforeFinalizeHeader: { $set: [] },
        });
      },
      [RESET_ALL_FINALIZE]: (state) => {
        return immutable(state, {
          updateS2SFinalizeLoading: { $set: false },
          updateS2SFinalizeError: { $set: false },
          updateS2SFinalizeSuccess: { $set: false },
          downloadCsvData:{ $set: null },
          disableEditLoading: { $set: false },
          disableEditData: { $set: false },
          disableEditError: { $set: false },
          finalizeLoading: { $set: false },
          finalizeError: { $set: null },
        });
      },
      [UPDATE_S2S_FINALIZE]: (state) => {
        return immutable(state, {
          updateS2SFinalizeLoading: { $set: true },
        });
      },
      [UPDATE_S2S_FINALIZE_SUCCESS]: (state) => {
        return immutable(state, {
          updateS2SFinalizeLoading: { $set: false },
          updateS2SFinalizeSuccess:{ $set: true },
        });
      },
      [UPDATE_S2S_FINALIZE_ERROR]: (state) => {
        return immutable(state, {
          updateS2SFinalizeLoading: { $set: false },
          updateS2SFinalizeSuccess:{ $set: false },
          updateS2SFinalizeError: { $set: true },
        });
      },
      [GET_PAST_ALLOCATION_PO_S2S]:(state) =>{
        return immutable (state,{
          pastAllocationPoLoading:{$set: true},
        })
      },
      [GET_PAST_ALLOCATION_PO_SUCCESS_S2S]:(state,{payload}) => {
        console.log("🚀 ~ payload:", payload)
        return immutable (state,{
          pastAllocationPoLoading:{$set: false},
          pastAllocationPoData: { $set: (payload && payload.data) || {} },
          pastAllocationPoError: { $set: null },
        })
      },
      [GET_PAST_ALLOCATION_PO_ERROR_S2S]:(state,payload) =>{
        return immutable (state,{
          pastAllocationPoLoading:{$set: false},
          pastAllocationPoError: { $set: (payload && payload.error) || null },
        })
      },
      [DOWNLOAD_CSV]: (state) => {
        return immutable(state, {
            downloadCsvLoading: { $set: true },
            downloadCsvData: { $set: [] },
        })
      },
      [DOWNLOAD_CSV_SUCCESS]: (state, payload) => {
        return immutable(state, {
            downloadCsvLoading: { $set: false },
            downloadCsvError: { $set: null },
            downloadCsvData: { $set: (payload && payload.data) || [] },

        })
      },
      [DOWNLOAD_CSV_ERROR]: (state, payload) => {
        return immutable(state, {
            downloadCsvLoading: { $set: false },
            downloadCsvError: { $set: (payload && payload.error) || null },
            downloadCsvData: { $set: [] }
        })
      },
      [DISABLE_EDIT]: (state) => {
        return immutable(state, {
            disableEditLoading: { $set: true },
            disableEditData: { $set: false },
            disableEditError: { $set: false },
        });
      },
      [DISABLE_EDIT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
            disableEditLoading: { $set: false },
            disableEditData: { $set: true },
            disableEditError: { $set: false },
        })
      },
      [DISABLE_EDIT_ERROR]: (state, { payload }) => {
        return immutable(state, {
            disableEditLoading: { $set: false },
            disableEditData: { $set: false },
            disableEditError: { $set: true },
        })
      },
      [FINALIZE_ALLOCATION]: (state, { payload }) => {
        return immutable(state, {
            finalizeLoading: { $set: true },
            finalizeError: { $set: null },
            finalizeData: { $set: {} }
        })
      },
      [FINALIZE_ALLOCATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
            finalizeLoading: { $set: false },
            finalizeError: { $set: null },
            finalizeData: { $set: (payload && payload.data) || {} },
            csvHeadersAfterFinalize:{$set: (payload && payload.csvHeadersAfterFinalize)}
            // allocationStatus: { $set: true }
        })
      },
      [FINALIZE_ALLOCATION_ERROR]: (state, { payload }) => {
        return immutable(state, {
            finalizeLoading: { $set: false },
            finalizeError: { $set: (payload && payload.error) || null },
            finalizeData: { $set: (payload && payload.data) || {} }
        })
      },
      [UPDATE_STORE_TRANSFER_QTY]: (state,payload ) => {
        return immutable(state,{
          updateStoreTransferQtyLoading: { $set: true },
        })
      },
      [UPDATE_STORE_TRANSFER_QTY_SUCCESS]: (state,payload ) => {
        return immutable(state,{
          updateStoreTransferQtyLoading: { $set: false },
          updateStoreTransferQtySuccess: { $set: true },
        })
      },
      [UPDATE_STORE_TRANSFER_QTY_ERROR]: (state,payload ) => {
        return immutable(state,{
          updateStoreTransferQtyLoading: { $set: false },
          updateStoreTransferQtyError: { $set: true },
        })
      }
    },
    initialState
  ),
};
