export const RESET_FILTERS_DATA = "@@store2store/RESET_FILTERS_DATA";

export const FETCH_FILTER_DATA = "@@store2store/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@store2store/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@store2store/FETCH_FILTER_ERROR";



export const resetFiltersOptions = (payload) => ({
    type: RESET_FILTERS_DATA,
    payload,
  });
  
  export const fetchFilterData = (payload, filterType) => ({
    type: FETCH_FILTER_DATA,
    payload,
    filterType,
  });
  
  export const fetchFilterDataSuccesss = (payload) => ({
    type: FETCH_FILTER_SUCCESS,
    payload,
  });
  
  export const fetchFilterDataError = (payload) => ({
    type: FETCH_FILTER_ERROR,
    payload,
  });
  