import React, { useEffect, useRef } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { datadogRum } from '@datadog/browser-rum';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";

// FE routes
import {
  BASE_ROUTE,
  PREFIX_NAV,
  LOGIN_ROUTE,
  STORE_PAGE,
  STRATEGY_PAGE,
  CONSTRAINTS_PAGE,
  PROFILE_PAGE,
  CREATEEDIT_PROFILE_PAGE,
  EDIT_PROFILE_PAGE,
  DASHBOARD_PAGE,
  USER_MANAGEMENT_PAGE,
  CREATE_USER_MANAGEMENT_PAGE,
  EDIT_USER_MANAGEMENT_PAGE,
  FINALIZE_PAGE,
  VIEW_ALLOCATION_PAGE,
  ADA_VISUAL_PAGE,
  PRODUCT_STORE_STATUS,
  REPORTING_PAGE,
  CREATE_CONFIGURATION,
  ADD_STORE_GROUP,
  NEW_STORE_SETUP,
  NEW_STORE_RESERVED_ALLOCATIONS,
  STRATEGY_CONFIGURAION_PATH,
  SET_STRATEGY_CONFIGURATION,
  PAGE_LOADING,
  INVALID_USER,
  CREATE_STORE_TRANSFER_RULE,
  EDIT_STORE_TRANSFER_RULE,
  STORE_TO_STORE_FINALIZE,
  STORE2STORE_ALLOCATION
} from "../constants/routeConstants";

import SidebarComponent from "../components/Sidebar/SidebarComponent";
import NavbarComponent, { logoutHandler } from "../components/Navbar/NavbarComponent";
import StoreGroup from "./StoreGroup/StoreGroup.jsx";
import StrategyContainer from "../containers/Strategy/Strategy";
import ConstraintsContainer from "./Constraints/ConstraintsContainer.jsx";

import ProductProfile from "./ProductProfile/ProductProfile";
import CreateEditProductprofile from "./ProductProfile/CreateEditProductProfile/CreateEditProductprofile";
import Dashboard from "./Dashboard/Dashboard";
import UserManagement from "./UserManagement/UserManagement";
import CreateEditUserManagement from "./UserManagement/CreateEditUserManagement/CreateEditUserManagement";
import Login from "./Login/Login"
import PrivateRoutes from "../routes/PrivateRoutes";
import Finalize from "./Finalize/Finalize";
import ViewAllocation from "./ViewAllocation/ViewAllocation";
import ViewAllocationParentContainer from "./ViewAllocation/ViewAllocationParentContainer.jsx"
import StandAloneContainer from "./AdaVisual/StandAloneContainer";
import Reporting from "./Reporting/Reporting"
import ProductStoreStatus from "./ProductStoreStatus/ProductStoreStatus";
import CreateConfig from "./ProductStoreStatus/ProductStatus/Configuration/CreateConfig"
import AddStoreGroupModal from "./ProductStoreStatus/ProductStatus/Popups/AddStoreGroupModal"
import NewStoreSetUpContainer from "./NewStoreSetUp/NewStoreSetUpContainer";
import NewStoreList from "./NewStoreSetUp/views/NewStoreSetupList/NewStoresList";
import StrategyConfigurationContainer from "./StrategyConfiguration/StrategyConfigurationContainer"
import SetStrategyContainer from "./StrategyConfiguration/views/SetStrategy/SetStrategyContainer"


// import { initGA, setGAPageView } from '../utils/GoogleAnalytics/googleAnalytics'
import { USER_ROLE_CONSTANTS, getUserRole, callRefreshTokenApi, setLocalStorage, nextStep, logoutUser, getWhatFixURL, logoutRedirect } from "../utils/commonUtilities"
import IntermediatePage from "./Login/IntermediatePage";
import InvalidUserPage from "./Login/InvalidUser";
import IdleTimerContainer from "../components/IdleTimer/IdleTimer";
import WindowFocusHandler from "../components/WindowFocusHandler/WindowFocusHandler";
import { INTERVAL_TO_REFRESH_TOKEN } from "../components/Table/CustomHooks/Constants";
import CreateEditNewStore from "./NewStoreSetUp/views/CreateEditNewStore/CreateEditNewStore";
import NewStoreApproveContainer from "./NewStoreApproveRelease/NewStoreApproveContainer";
import useScript from "../customHooks/useScript";
import Notification from "../components/Notification/Notifications";
import { init as initPostHog, reset as resetPostHog, trackPageView } from "../utils/PostHog/postHog";
import { createBrowserHistory } from "history";
import CreateStoreTransferRuleContainer from "./ProductStoreStatus/StoreToStoreConfig/CreateStoreTransferRule/CreateStoreTransferRuleContainer";
import STSFinalize from "./ProductStoreStatus/StoreToStoreConfig/StoreToStoreFinalize/STSFinalize.jsx";
import Store2StoreContainer from "./Store2Store/Store2StoreContainer.jsx"


function content() {
  const { SUPER_USER, ADMIN, ALLOCATOR } = USER_ROLE_CONSTANTS
  return (
    <>
    <div className="wrapper">
      <SidebarComponent />
      <div id="content">
        <NavbarComponent />
        <div className="sub-content">
          {/* TODO: Replace with public routes once authentication is complete */}
          <Switch>
            <Route path={PAGE_LOADING} component={IntermediatePage} />
            <PrivateRoutes path={STORE_PAGE} component={StoreGroup} groupId={getUserRole(SUPER_USER, ALLOCATOR)} />
            <PrivateRoutes path={CONSTRAINTS_PAGE} component={ConstraintsContainer} groupId={getUserRole(SUPER_USER, ALLOCATOR)} />
            <PrivateRoutes exact path={PROFILE_PAGE} component={ProductProfile} groupId={getUserRole(SUPER_USER, ALLOCATOR)} />
            <PrivateRoutes
              path={CREATEEDIT_PROFILE_PAGE}
              component={CreateEditProductprofile}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={EDIT_PROFILE_PAGE}
              component={CreateEditProductprofile}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes path={DASHBOARD_PAGE} component={Dashboard} groupId={getUserRole(SUPER_USER, ALLOCATOR)} />
            {/* Remove this and link to internal component */}
            <PrivateRoutes
              path={STRATEGY_PAGE}
              component={StrategyContainer}
              isAuthenticated={false}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              exact
              path={USER_MANAGEMENT_PAGE}
              component={UserManagement}
              groupId={getUserRole(SUPER_USER, ADMIN)}
            />
            <PrivateRoutes
              path={CREATE_USER_MANAGEMENT_PAGE}
              component={CreateEditUserManagement}
              groupId={getUserRole(SUPER_USER, ADMIN)}
            />
            <PrivateRoutes
              path={EDIT_USER_MANAGEMENT_PAGE}
              component={CreateEditUserManagement}
              groupId={getUserRole(SUPER_USER, ADMIN)} 
            />
            <PrivateRoutes
              path={FINALIZE_PAGE}
              component={Finalize}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={VIEW_ALLOCATION_PAGE}
              component={ViewAllocationParentContainer}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={REPORTING_PAGE}
              component={Reporting}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={ADA_VISUAL_PAGE}
              component={StandAloneContainer}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={PRODUCT_STORE_STATUS}
              component={ProductStoreStatus}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={CREATE_CONFIGURATION}
              component={CreateConfig}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={ADD_STORE_GROUP}
              component={AddStoreGroupModal}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={NEW_STORE_SETUP}
              component={CreateEditNewStore}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={NEW_STORE_RESERVED_ALLOCATIONS}
              component={NewStoreApproveContainer}
              groupId={[1, 3]}
            />
            <PrivateRoutes
              path={STRATEGY_CONFIGURAION_PATH}
              component={StrategyConfigurationContainer}
              groupId={[1, 3]}
            />
            <PrivateRoutes
              path={SET_STRATEGY_CONFIGURATION}
              component={SetStrategyContainer}
              groupId={[1, 3]}
            />
            <PrivateRoutes
              path={CREATE_STORE_TRANSFER_RULE}
              component={CreateStoreTransferRuleContainer}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={EDIT_STORE_TRANSFER_RULE}
              component={CreateStoreTransferRuleContainer}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
              path={STORE_TO_STORE_FINALIZE}
              component={STSFinalize}
              groupId={getUserRole(SUPER_USER, ALLOCATOR)}
            />
            <PrivateRoutes
                path={STORE2STORE_ALLOCATION}
                component={Store2StoreContainer}
                isAuthenticated={false}
                groupId={getUserRole(SUPER_USER, ALLOCATOR)}
              />
          </Switch>
          {/* <div className="wrapper">
            <div style={{ textAlign: "right", width: "100%", padding: "1rem" }}>
              {process.env.REACT_APP_FE_VERSION}
            </div>
          </div> */}
        </div>
      </div>
    </div>
    </>
  );
}

const Pages = withRouter(content);

function App() {
  useScript(getWhatFixURL());
  const history = createBrowserHistory();
  const email = localStorage.getItem("email");

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATA_DOG_ID,
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'allocatesmart',
    // env:'sample_env',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
  });
  //Initiating the posthog
  initPostHog()
  // useEffect(() => {
  //   initGA()
  //   setGAPageView(window.location.pathname + window.location.search)
  // }, [])


  useEffect(() => {
    startInterval()
    sessionStorage.setItem('isActive',true)
    return () => {
      clearInterval(ref.current);
    };
  },[]);

  useEffect(() => {
    trackPageView(history.location.pathname, email);
  }, [history]);

  const ref = useRef(null);

  const redirectToLogin = () => {
    resetPostHog()
    setTimeout(() => {
      logoutRedirect();
    }, 2500);
    clearInterval(ref.current);
    logoutUser();
  }

  const refreshToken = async () => {
    let next = nextStep();
    switch (next) {
      case 'refresh':
        clearInterval(ref.current);
        try{
          let res = await callRefreshTokenApi();
          if(res.data.status){
            setLocalStorage(res?.data?.token)
            startInterval();
          }
          else{
            redirectToLogin();
          }
        }
        catch{
          redirectToLogin();
        }
        break;

      case 'logout':
        redirectToLogin();
        break;

      case 'doNothing':
        break;
    }
  }

  const startInterval = () => {
    ref.current = setInterval(refreshToken, INTERVAL_TO_REFRESH_TOKEN);
  }

  return (
    <>
    {/* <WindowFocusHandler redirectToLogin={redirectToLogin}/> */}
    <IdleTimerContainer redirectToLogin={redirectToLogin}/>
      <Router>
        <Notification />
        <Switch>
          {/* Login component */}
          <Route exact path={LOGIN_ROUTE}
          // component={Login}
          render={(props) => (<Login {...props} startInterval={startInterval}/>)} />
          <Route path={INVALID_USER} component={InvalidUserPage} />

          {/* Redirection to login route */}
          <Redirect exact from={BASE_ROUTE} to={LOGIN_ROUTE} />
          {/* Rest of the pages */}
          <Route path={PREFIX_NAV} component={Pages} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
