import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../../../routes/api";
import * as actionTypes from "./Actions/ActionTypes";
import { ERROR_MSG } from "../../../../constants/globalConstant";

function* fetchFilterWorker(action) {
  const { payload } = action;
  let req = {};
  const selectedFilters = Object.keys(payload);
  try {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key]?.length &&
          (req[key] = payload[key].map((ele) =>
            ele?.value ? ele.value : ele
          ));
      }
    }
    const response = yield call(Api.getStoreTransferFilters, req);
    if (response.status) {
      const { filterMapping, data, transfer_within } = response?.data;
      let reponse_data = {...data?.[0], transfer_within};
      let filterData = {};
      for (let key in reponse_data) {
        if (!selectedFilters.includes(key)) {
          filterData[key] = reponse_data[key]?.map((value) => {
            return {
              label: value,
              value,
            };
          });
        }
      }
      yield put({
        type: actionTypes.FETCH_FILTER_SUCCESS,
        data: filterData,
        filterMapping,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_FILTER_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: ERROR_MSG });
  }
}

function* fetchStores(action) {
  const { payload } = action;
  let req = {};
  try {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key]?.length &&
          (req[key] = payload[key].map((ele) =>
            ele?.value ? ele.value : ele
          ));
      }
      else if(payload[key]?.value) {
        req[key] = [payload[key]?.value];
      }
    }
    if(payload.rule_id) {
      req["rule_id"] = payload["rule_id"];
    }
    const response = yield call(Api.getStoreTransferRuleStores, req);
    if (response.status) {
      yield put({
        type: actionTypes.GET_STORES_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_STORES_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STORES_ERROR, error: ERROR_MSG });
  }
}

function* fetchStoreMapping(action) {
  const { payload } = action;
  let req = {};
  try {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key]?.length &&
          (req[key] = payload[key].map((ele) =>
            ele?.value ? ele.value : ele
          ));
      }
      else if(typeof payload[key] === "object" && payload[key]?.value) {
        req[key] = [payload[key]?.value];
      }
      else {
        req[key] = payload[key];
      }
    }
    const response = yield call(Api.getStoreRuleMappings, req);
    if (response.status) {
      yield put({
        type: actionTypes.GET_STORE_MAPPING_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_STORE_MAPPING_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STORE_MAPPING_ERROR, error: ERROR_MSG });
  }
}

function* createStoreTransferRule(action) {
  const { payload } = action;
  try {
    
    const response = yield call(Api.createStoreTransferRule, payload);
    if (response.status) {
      yield put({
        type: actionTypes.CREATE_STORE_TRANSFER_RULE_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.CREATE_STORE_TRANSFER_RULE_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.CREATE_STORE_TRANSFER_RULE_ERROR, error: ERROR_MSG });
  }
}

function* validateRuleName(action) {
  const { payload } = action;
  try {
    
    const response = yield call(Api.validateRuleName, payload);
    if (response?.data?.status) {
      yield put({
        type: actionTypes.VALIDATE_RULE_NAME_SUCCESS,
        data: response?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.VALIDATE_RULE_NAME_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.VALIDATE_RULE_NAME_ERROR, error: ERROR_MSG });
  }
}


function* fetchFilterWatcher() {
  yield takeLatest(actionTypes.FETCH_FILTER_DATA, fetchFilterWorker);
}

function* fetchStoresWatcher() {
  yield takeLatest(actionTypes.GET_STORES, fetchStores);
}

function* fetchStoreMappingWatcher() {
  yield takeLatest(actionTypes.GET_STORE_MAPPING, fetchStoreMapping);
}

function* createStoreTransferRuleWatcher() {
  yield takeLatest(actionTypes.CREATE_STORE_TRANSFER_RULE, createStoreTransferRule);
}

function* validateRuleNameWatcher() {
  yield takeLatest(actionTypes.VALIDATE_RULE_NAME, validateRuleName);
}

export function* createRuleSaga() {
  yield all([
    fetchFilterWatcher(), 
    fetchStoresWatcher(),
    fetchStoreMappingWatcher(),
    createStoreTransferRuleWatcher(),
    validateRuleNameWatcher()
  ]);
}
