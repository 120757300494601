import * as actionTypes from "./ActionTypes"

export const getDailySummaryStoreViewS2S = (payload) => ({
    type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW,
    payload
})

export const fetchStoreViewDetailsS2S = (payload) => ({
    type: actionTypes.FETCH_STORE_DETAILS_TABLE_DATA,
    payload,
  });
  
export const fetchStoreViewDetailsS2SSuccess = (payload) => ({
    type: actionTypes.FETCH_STORE_DETAILS_TABLE_DATA_SUCCESS,
    payload,
  });
  
export const fetchStoreViewDetailsS2SError = (payload) => ({
    type: actionTypes.FETCH_STORE_DETAILS_TABLE_DATA_ERROR,
    payload,
  });

export const resetStoreViewData = (payload) => ({
    type: actionTypes.RESET_DAILY_SUMMARY_STORE_VIEW_DATA,
    payload,
  });