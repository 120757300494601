import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import ReactTable from "../../components/Table/ReactTable"
import "./ViewAllocation.css";
import DashboardFilters from "../Dashboard/DashboardFilters";
import ColumnFilter from "../UserManagement/ColumnFilter";
import Notification from '../../components/Notification/Notifications';
import PageLoader from "../../components/Loader/PageLoader";
import { getAllocationPlans, resetAll } from "./ViewAllocationActions"
import Finalize from '../Finalize/Finalize'
import { isEmpty } from "lodash";
import { clearChartData } from "../Dashboard/DashboardActions";
import ReactTollTip from "../../components/Table/Cellrenderer/ReactToolTip"
import { ERROR_MSG } from "../../constants/globalConstant";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const ViewAllocation = (props) => {
    const { isNewStore, data, showDetailedView, store, styleIndex, outofData ,setDetailedView ,setSelectedAllocation,isBackClicked, setIsBackclicked} = props

    // const [detailedView, setDetailedView] = useState(false)
    const [selectedAllocationCode, setAllocationCode] = useState(null)
    // const [selectedAllocation, setSelectedAllocation] = useState({})
    const [selectedFilters, setSelectedFilters] = useState({})
    // const [isBackClicked, setIsBackclicked] = useState(false)
    const [allocationPlans, setAllocationPlans] = useState([])
    const [RTinstance, setRTinstance] = useState({})
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [isFirstCall, setisFirstCall] = useState(true)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [filterReq, setFilterReq] = useState({})
    const [pageIndex, setPageIndex] = useState(0)

    const getAllocationPlans = (req) => {
        setFilterReq(req)
        props.getAllocationPlans(req)
    }

    const handleDetailedViewClick = (row) => {
        isNewStore && showDetailedView(true)
        // setSelectedPlan(row)
        // setAllocationCode(row.plan_id)
        setSelectedAllocation({ ...row, allocationCode: row.plan_id, allocationName: row.plan_description })
        setDetailedView(true)
    }

    // useEffect(() => {
    //     return () => {
    //         props.clearChartData();
    //         if (hasTabChangedDC) {
    //             console.log("reset is being called");
    //             console.log("First unmount, resetting");
    //             props.resetAll();
    //           }
    //     }
    // }, [])

    const getFilters = (filters) => {
        setSelectedFilters(filters)
    }

    const backFromDetailedView = (isDetailedView) => {
        isNewStore && showDetailedView(false)
        setDetailedView(isDetailedView)
        setIsBackclicked(true)
    }

    const allocationPlansColumns = React.useMemo(() => [
        // {
        //     Header: 'Plan ID',
        //     accessor: 'plan_id',
        //     Filter: ColumnFilter,
        //     width: 300
        // },
        {
            Header: 'Plan Name',
            accessor: 'plan_description',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 250,
        },
        // {
        //     Header: 'Pack ID',
        //     accessor: 'packs',
        //     Cell: (instance) => <ReactTollTip {...instance} />,
        //     Filter: ColumnFilter,
        //     width: 250,
        // },
        {
            Header: 'Style Color ID',
            accessor: 'article',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 250,
        },
        {
            Header: 'PO ID',
            accessor: 'po_number',
            Filter: ColumnFilter
        },
        {
            Header: 'ASN ID',
            accessor: 'asn',
            Filter: ColumnFilter
        },
        {
            Header: 'Inventory',
            accessor: 'inventory_source',
            Filter: ColumnFilter
        },
        {
            Header: 'Created By',
            accessor: 'created_by',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: 'Released By',
            accessor: 'released_by',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: 'Creation Date',
            accessor: 'creation_date',
            Filter: ColumnFilter,
            sortType: (rowA, rowB) => new Date(rowA?.original?.creation_date) > new Date(rowB?.original?.creation_date)
        },
        {
            Header: 'Released Date',
            accessor: 'released_date_picked',
            Filter: ColumnFilter,
            sortType: (rowA, rowB) => {
                if (new Date(rowA?.original?.released_date_picked) > new Date(rowB?.original?.released_date_picked))
                    return 1

                if (new Date(rowB?.original?.released_date_picked) > new Date(rowA?.original?.released_date_picked))
                    return -1
            }
        },
        {
            Header: 'Creation Time',
            accessor: 'creation_time',
            Filter: ColumnFilter,
            sortType: "basic"
        },
        {
            Header: 'Released Time',
            accessor: 'released_time',
            Filter: ColumnFilter,
            sortType: "basic"
        },
        {
            Header: '# Styles Allocated',
            accessor: 'style_count',
            Filter: ColumnFilter
        },
        {
            Header: '# Stores Allocated',
            accessor: 'store_count',
            id: "store_cnt",
            Filter: ColumnFilter
        },
        {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
                <div>
                    <button
                        className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
                        onClick={() => { handleDetailedViewClick(instance.row?.original) }}
                    >
                        Detailed View
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            ),
            // width: 150,
            disableFilters: true
        },
    ])

    useEffect(() => {
        // if (props.allocationPlans?.length > 0) {
        //     if (RTinstance && RTinstance.data) {
        //         setAllocationPlans([...RTinstance.data, ...props.allocationPlans]);
        //     }
        //     else {
        setAllocationPlans(props.allocationPlans);
        //     }
        // }
    }, [props.allocationPlans])

    const resetFilters = () => {
        setAllocationPlans([])
    }

    const fetchData = (index) => {
        if((prevIndex >= index  || index < pageIndex))
            return;
        // if (prevIndex >= index || index < nextIndex || nextIndex >= totalCount)
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / 10))

        props.getAllocationPlans({ ...filterReq, styleIndex, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq)) {
            isFirstCall && setisFirstCall(false)
        }
        setSortReq(p_sort[0])
    }

    // useEffect(() => {
    //     if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(filterReq)) {
    //         setAllocationPlans([])
    //         setRTinstance(null)
    //         setInitialIndex(0)
    //         setPrevIndex(0)
    //         props.getAllocationPlans({ ...filterReq, rowIndex: 0, rowCount: 100, styleIndex: 0, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
    //     }
    // }, [searchTermReq, sortReq])

    // useEffect(() => {
    //     setPageIndex(props.pageIndex)
    //     setNextIndex(props.nextIndex)
    // }, [props.pageIndex, props.nextIndex])


    return (
        <>
            {
                // !detailedView &&
                <div className={`container__wrapper ${isNewStore ? "pt-0" : ""}`}>
                    {/* <Notification /> */}
                    {
                        !isNewStore &&
                        <>
                            <div className="container__header__with__button config-header">
                                <h1 className="fnt-lg fnt-bold">View Past DC Allocations</h1>
                            </div>
                            <div className="filter row">
                                <DashboardFilters viewAllocationFilters={selectedFilters} 
                                    resetFilters={resetFilters}
                                    isViewAllocation={true} getAllocations={getAllocationPlans}
                                    getFilters={getFilters} isBackClicked={isBackClicked} setIsBackclicked={setIsBackclicked} />
                                <div style={{ marginTop: "3rem" }} className="col-md-3">
                                    <div className="required fnt-md pt-2" style={{ color: "red" }}>
                                        <label></label>Fields are mandatory
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="view_allocation__tables">
                        <div className="view_allocation__table">
                            <PageLoader loader={props.allocationPlansLoading}>
                                {props.allocationPlansError ? (
                                    <div className="error">{ERROR_MSG}</div>
                                ) : (

                                    <ReactTableWithPlugins
                                        shouldPagination
                                        sortBy={[{ id: 'creation_date', desc: true }, { id: 'creation_time', desc: true }]}
                                        totalRecordsLen={allocationPlans ? allocationPlans.length : 0}
                                        data={isNewStore ? data : (allocationPlans ? allocationPlans : [])}
                                        columns={allocationPlansColumns}
                                        renderMarkup='TableMarkup'
                                        keyRT="sortAndSearch"
                                        tableId="view_past_allocations"
                                        // fetchData={(data) => fetchData(data)}
                                        initialPageIndex={initialIndex}
                                        getInstanceOnMount={(instance) => {
                                            setRTinstance(instance)
                                        }}
                                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                        // getManualSortByOptions={(instance) => {
                                        //     fecthDataWithSorting(instance)
                                        // }}
                                        // manualSortBy={true}
                                    />
                                )}
                            </PageLoader>
                        </div>
                    </div>
                </div>
            }
            {   
                // detailedView &&
                // (
                //     console.log("Rendering Finalize component with:", {
                //         detailedView,
                //         selectedAllocation,
                //         store
                //       }),
                //     <Finalize 
                //       planDetails={selectedAllocation} 
                //       goBack={backFromDetailedView} 
                //       store={store} 
                //     />
                //   )
            }
        </>
    )
}

const mapStateToProps = ({ viewAllocation }) => {
    return {
        allocationPlansLoading: viewAllocation.allocationPlansLoading,
        allocationPlans: viewAllocation.allocationPlans,
        allocationPlansError: viewAllocation.allocationPlansError,
        styleIndex: viewAllocation.style_index,
        outofData: viewAllocation.out_of_data,
        pageIndex: viewAllocation.pageIndex,
        nextIndex: viewAllocation.nextIndex
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAllocationPlans: (payload) => dispatch(getAllocationPlans(payload)),
    resetAll: () => dispatch(resetAll()),
    clearChartData: (payload) => dispatch(clearChartData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllocation)