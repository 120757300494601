import React from 'react';

const ErrorModalBodyList = (props) => {
  return (
    <ul
      style={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: 0,
        margin: 0,
        gap: "10px",
      }}
    >
      {props.message.map((article, index) => (
        <li
          key={index}
          style={{
            flexBasis: "calc(33.33% - 10px)", // For 3 columns
            backgroundColor: "#f0f0f0",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          {article}
        </li>
      ))}
    </ul>
  );
};

export default ErrorModalBodyList;
