import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import StepperComponent from "../../../../components/StepperComponent/StepperComponent";
import BasicDetails from "./BasicDetails/BasicDetails";
import Stores from "./Stores/Stores";
import ReviewStores from "./ReviewStores/ReviewStores";
import * as Notify from "../../../../components/Notification/Notifications";
import { connect } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader";
// import { STEPS } from "./constants";
import {
  PRODUCT_STORE_STATUS,
} from "../../../../constants/routeConstants";
import { isEmpty } from "lodash";
import {
  createStoreTransferRule,
  resetAll,
  resetStep1,
  resetStep2,
  storeValues,
  validateRuleName,
} from "./Actions/CreateStoreTransferRuleActions";
import StoreCombination from "./StoresCombination/StoreCombination";

const CreateStoreTransferRuleContainer = (props) => {
  const {
    basicDetails,
    selectedStores,
    createStoreTransferRule,
    storeValues,
    resetStep2,
    resetAll,
    createRuleLoading,
    createRuleSuccess,
    createRuleError,
    validateName,
    validateSuccess,
    validateError,
    allStoreGroups,
    resetStep1,
    savedStoreTypes
  } = props;
  const history = useHistory();
  const params = useParams();
  const isEdit = params?.ruleId;
  const nameInputRef = useRef();
  const distanceInputRef = useRef();

  const activeStep = Number(
    new URLSearchParams(window.location.search).get("step")
  );

  const [storesRTInstance, setStoresRTInstance] = useState({});
  const [storeMappingRTInstance, setStoreMappingRTInstance] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [storeTypes, setStoreTypes] = useState([]);

  const saveStep2Details = () => {
    let count = 0;
    const { getSelectedRows, preFilteredRowsById: rows } = storesRTInstance;
    if (getSelectedRows?.length) {
      let stores = {};
      getSelectedRows?.forEach((id) => {
        const { store_group_code, store_code } = rows[id].original;
        if (rows[id].depth === 1) {
          count++;
          if (stores[store_group_code]) {
            stores[store_group_code] = [
              ...stores[store_group_code],
              store_code,
            ];
          } else {
            stores[store_group_code] = [store_code];
          }
        }
      });
      if (count > 1) {
        storeValues({
          stores,
          selectedRows: storesRTInstance?.state?.selectedRowIds,
        });
        resetStep2();
      } else {
        return 0;
      }
      return 1;
    } else {
      return 0;
    }
  };

  const saveFirstStep = (data) => {
    storeValues(data);
  };

  const STEPS = [
    {
      label: "Basic Details",
      component: (
        <BasicDetails
          isEdit={isEdit}
          storeValues={saveFirstStep}
          nameInputRef={nameInputRef}
          refs={{ distanceInputRef, nameInputRef }}
        />
      ),
      nextButtonText: "Next",
    },
    {
      label: "Select Stores",
      component: (
        <Stores setRTInstance={setStoresRTInstance} id={params?.ruleId} />
      ),
      nextButtonText: "Next",
    },
    {
      label: "Review Store Mapping",
      component: (
        <ReviewStores
          setRTInstance={setStoreMappingRTInstance}
          id={params?.ruleId}
          storeTypes={storeTypes}
        />
      ),
      nextButtonText: isEdit ? "Update" : "Create" + " Rule",
    },
  ];

  const isStep1Details = () => {
    if (nameInputRef?.current?.value) {
      const keys = ["l1_name", "l2_name", "l3_name"];
      for (let key of keys) {
        if (!basicDetails[key]?.length && !basicDetails[key]?.value) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const isMoveToNextStep = () => {
    let step = activeStep;
    if (activeStep === 0) {
      if (isStep1Details()) {
        !isEdit && validateName({ rule_name: nameInputRef?.current?.value });
        isEdit && saveStep1(true);
      } else {
        notifyError("Please enter all the mandatory fields!!");
      }
      // if (!isSaved) {
      //   notifyError("Please click save to continue!!");
      return 0;
      // }
    } else if (activeStep === 1) {
      if (!saveStep2Details()) {
        notifyError("Please select minimum two stores!!");
        return 0;
      }
      else if(!showPopup) {
        setTimeout(() => {
          setShowPopup(true);
        }, 100)
        return 0;
      }
      else {
        setShowPopup(false);
      }
    } else if (activeStep === 2) {
      if (storeMappingRTInstance?.selectedFlatRows?.length) {
        createRule();
      } else {
        notifyError("Please select atleast one row!!");
      }
      return 0;
    }
    return step + 1;
  };

  const goBack = () => {
    if (activeStep === 0) {
      history.push(`${PRODUCT_STORE_STATUS}?tab=store-to-store`);
    } else {
      // history.push(`${CREATE_STORE_TRANSFER_RULE}?step=${activeStep - 1}`);
      history.push({
        search: `?step=${activeStep - 1}`,
      });
    }
  };

  const moveForward = (data, isMove) => {
    isMove && setStoreTypes(data);
    if (isMoveToNextStep() || isMove === true) {
      history.push({
        search: `?step=${isMoveToNextStep()}`,
      });
    }
  };

  const notifyError = (text) => {
    Notify.error(text);
  };

  useEffect(() => {
    return () => {
      resetAll();
    };
  }, []);

  useEffect(() => {
    if (createRuleSuccess) {
      Notify.success(`Rule ${isEdit ? "Updated" : "Created"} Successfully!!`);
      setTimeout(() => {
        history.push(`${PRODUCT_STORE_STATUS}?tab=store-to-store`);
      }, 3000);
    } else if (createRuleError) {
      Notify.error("Error in Saving rule!!");
    }
  }, [createRuleError, createRuleSuccess]);

  const createRule = () => {
    const { ruleName, l1_name, l2_name, l3_name, transfer, distance } =
      basicDetails;
    const { selectedFlatRows, data } = storeMappingRTInstance;
    const { selectedRowIds } = storeMappingRTInstance?.state;
    if (selectedFlatRows?.length) {
      let selectedRows = [];
      for (let [index, value] of data.entries()) {
        if (selectedRowIds[index]) {
          selectedRows.push(value);
        }
      }
      let store_groups = {};
      let all_stores = {};
      for (let group of allStoreGroups) {
        all_stores[group.store_group_code] = {
          store_codes: group.subRows?.filter(store => !store.is_deleted)?.map((item) => item.store_code),
        };
      }
      for (let code in selectedStores) {
        store_groups[code] = { store_codes: selectedStores[code] };
      }
      let source_dest_data = selectedRows?.map((item) => {
        return {
          source_store: item.source,
          destination_store: item.destination,
          source_name: item.source_name,
          destination_name: item.destination_name,
          country: item.source_country,
          source_grade: item.source_grade,
          destination_grade: item.destination_grade,
          source_district: item.source_district,
          destination_district: item.destination_district,
          distance: item.distance,
        };
      });
      let req = {
        rule_name: ruleName,
        created_by: localStorage?.getItem("user_id"),
        created_by_username: localStorage?.getItem("email"),
        product_filters: {
          l1_name: [Array.isArray(l1_name) ? l1_name[0].value : l1_name.value],
          l2_name: [Array.isArray(l2_name) ? l2_name[0].value : l2_name.value],
          l3_name: l3_name
            ?.map((item) => item.value)
            .filter((val) => val !== "*"),
        },
        transfer_within: transfer?.value,
        store_selection: store_groups,
        all_stores,
        source_dest_data,
        rule_id: params?.ruleId,
        store_type_selected: storeTypes
      };
      distance && (req["distance_filter"] = distance);
      createStoreTransferRule(req);
    } else {
      Notify.error("Select atleast one row!!");
    }
  };

  useEffect(() => {
    if (validateError) {
      Notify.error(validateError);
    } else if (validateSuccess) {
      if (distanceInputRef?.current?.value || nameInputRef?.current?.value) {
        saveStep1();
      }
      history.push({
        search: `?step=1`,
      });
      resetStep1();
      // !isEdit && Notify.success("Changes saved, click next to continue!!");
    }
  }, [validateSuccess, validateError]);

  const saveStep1 = (move) => {
    storeValues({
      data: {
        ...basicDetails,
        ruleName: nameInputRef?.current?.value,
        distance: distanceInputRef?.current?.value,
      },
    });
    setStoreTypes([]);
    move && history.push({ search: `?step=1` });
  };

  const closePopup = () => {
    setShowPopup(false);
  }

  useEffect(() => {
    if(savedStoreTypes?.length) {
      setStoreTypes(savedStoreTypes);
    }
  }, [savedStoreTypes])

  return (
    <PageLoader loader={createRuleLoading} gridLoader={true}>
      {showPopup && (
        <StoreCombination
          showPopup={showPopup}
          closePopup={closePopup}
          RTInstance={storesRTInstance}
          moveForward={moveForward}
          isEdit={isEdit}
          data={storeTypes}
          selectedStores={selectedStores}
        />
      )}
      <StepperComponent
        activeStep={activeStep}
        steps={STEPS}
        heading={`Store to Store Transfer/${isEdit ? "Edit" : "Create"}`}
        goBack={goBack}
        moveForward={moveForward}
      />
    </PageLoader>
  );
};

const mapStateToProps = ({ createRule }) => ({
  basicDetails: createRule.basicDetails,
  selectedStores: createRule.selectedStores,
  saveLoading: createRule.saveLoading,
  createRuleLoading: createRule.createRuleLoading,
  createRuleSuccess: createRule.createRuleSuccess,
  createRuleError: createRule.createRuleError,
  validateSuccess: createRule.validateSuccess,
  validateError: createRule.validateError,
  allStoreGroups: createRule.stores?.store_groups,
  savedStoreTypes: createRule.stores?.store_type_selected
});

const mapDispatchToProps = (dispatch) => ({
  storeValues: (payload) => dispatch(storeValues(payload)),
  resetAll: (payload) => dispatch(resetAll(payload)),
  resetStep2: () => dispatch(resetStep2()),
  createStoreTransferRule: (payload) =>
    dispatch(createStoreTransferRule(payload)),
  resetStep1: () => dispatch(resetStep1()),
  validateName: (payload) => dispatch(validateRuleName(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateStoreTransferRuleContainer);
