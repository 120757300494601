export const GET_PRODUCT_VIEW_SUMMARY = "@@storetransferrule/GET_PRODUCT_VIEW_SUMMARY";
export const GET_PRODUCT_VIEW_SUMMARY_SUCCESS = "@@storetransferrule/GET_PRODUCT_VIEW_SUMMARY_SUCCESS";
export const GET_PRODUCT_VIEW_SUMMARY_ERROR = "@@storetransferrule/GET_PRODUCT_VIEW_SUMMARY_ERROR";

export const GET_PRODUCT_STORE_VIEW = "@@storetransferrule/GET_PRODUCT_STORE_VIEW";
export const GET_PRODUCT_STORE_VIEW_SUCCESS = "@@storetransferrule/GET_PRODUCT_STORE_VIEW_SUCCESS";
export const GET_PRODUCT_STORE_VIEW_ERROR = "@@storetransferrule/GET_PRODUCT_STORE_VIEW_ERROR";

export const UPDATE_TRANSFER_UNITS_FOR_PRODUCTS = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_PRODUCTS";
export const UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_SUCCESS = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_SUCCESS";
export const UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_ERROR = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_PRODUCTS_ERROR";
export const RESET_ALL = "@@storetransferrule/RESET_ALL";
