import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import StoreView from "./StoreView/Views/StoreView";
import { STORE_TO_STORE_FINALIZE } from "../../../../constants/routeConstants";
import { useHistory } from "react-router";
import ProductView from "./ProductView/Views/ProductView";
import { connect } from "react-redux";
import * as Notify from "../../../../components/Notification/Notifications";
import ErrorModal from "../../../../components/ErrorModal/ErrorModal.jsx";
import PageLoader from "../../../../components/Loader/PageLoader.jsx";
import ExportFile from "../../../../components/ExportFile/ExportFile.jsx";
import ConfirmationAlert from "../../../../components/ConfirmationAlert/ConfirmationAlert.jsx";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { downloadCsvBeforeFinalize,updateS2SFinalize ,resetAllFinalizeS2S,getPastAllocationPOS2S,downloadCsv,disableEdit,finalizeAllocation,updateStoreTransferQuantity} from "./Actions/Actions.js";
import axios from "axios";

const STSFinalize = (props) => {
  let csvRef;
  const [activeTab, setActiveTab] = useState("store-view");
  const [allocationCode, setAllocationCode] = useState(props.planDetails?.plan_id ? props.planDetails?.plan_id : (props.history?.location?.state?.planDetails?.allocationCode));
  const [originalAllocationCode, setOriginalAllocationCode] = useState();
  const [viewAllocationCallS2S, setViewAllocationCallS2S] = useState(props.planDetails?.plan_id ? true : false);
  const [showBack, setShowBack] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(null);
  const [errorModalHeader, setErrorModalHeader] = useState(null);
  const [finalized, setFinalized] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [csvHeader, setCsvHeader] = useState("");
  const [showFinalizeButton, setShowFinalizeButton] = useState(true);
  const [planDetails, setPlanDetails] = useState(props.planDetails ? props.planDetails : props.history?.location?.state?.planDetails);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFinalizeBtnClicked, setFinalizeBtn] = useState(false);
  const [csvDownloadAfterFinalize,setCsvDownloadAfterFinalize] = useState(false); 

  const history = useHistory();

  const handleTabSelect = (key) => {
    !viewAllocationCallS2S && history.push(`${STORE_TO_STORE_FINALIZE}?tab=${key}`);
    setActiveTab(key);
  };

  useEffect(() => {
    return () => props.resetAllFinalizeS2S();
  },[]);

  useEffect(() => {
    if (props?.location?.state?.allocationCode) {
      setAllocationCode(props?.location?.state?.allocationCode);
      setOriginalAllocationCode(props?.location?.state?.allocationCode);
    } else if (props.planDetails?.plan_id) {
      setAllocationCode(props.planDetails?.plan_id);
      setOriginalAllocationCode(props.planDetails?.plan_id);
      setShowBack(true);
    }
  }, [props?.location?.state?.allocationCode, props?.planDetails]);

  useEffect(() => {
    if(viewAllocationCallS2S ) {
      props.getPastAllocationPOS2S({ allocationcode: allocationCode, articles: planDetails?.articles})
    }
  }, [])

  useEffect(() => {
    if (props.alloacationCode) {
      setAllocationCode(props.alloacationCode);
      setIsUpdated(true);
    }
    if (props.alloacationCodeProduct) {
      setAllocationCode(props.alloacationCodeProduct);
      setIsUpdated(true);
    }
  }, [props.alloacationCode, props.alloacationCodeProduct]);

  useEffect(() => {
    if (props?.history?.location?.state) {
      const { warning } = props?.history?.location?.state;
      if (warning) {
        let errorHeader =  "No Source/Destination stores found based on current inventory levels for the below articles.";
        let errorMessage = warning.split(":")?.[1].toString().trim().split(",");
        setErrorModalMessage(errorMessage);
        setErrorModalHeader(errorHeader);
      }
    }
  }, [props?.history?.location?.state?.warning]);

  useEffect(() => {
    if(props.pastAllocationPoData){
      setFinalized(true);
      setCsvData(props.pastAllocationPoData?.Output ? props.pastAllocationPoData?.Output : props.pastAllocationPoData?.PO);
    }
  },[props.pastAllocationPoData])
  useEffect(() => {
    if(props.downloadCsvData){
      setCsvHeader(props.downloadCsvData);
    }
  },[props.downloadCsvData])

  const validCsvData = () => {
    return (
      props.finalizeData?.Output?.length ||
      props.finalizeData?.PO?.length ||
      props?.pastAllocationPoData?.Output?.length ||
      props?.pastAllocationPoData?.PO?.length)
  }

  const LoaderState = () => {
   return (
    props.csvBeforeFinalizeLoading || 
    props.updateS2SFinalizeLoading || 
    props.pastAllocationPoLoading || 
    props.disableEditLoading ||
    props.finalizeLoading || 
    props.updateStoreTransferQtyLoading
   )
  }

  const downloadCsv = () => {
    if (!viewAllocationCallS2S && !csvDownloadAfterFinalize) {
      let req = {
        allocationcode: allocationCode,
      };
      props.downloadCsvBeforeFinalize(req);
    } else {
      //view past allocation flow for csv download and after finalize csv download
      if (validCsvData()) {
        csvDownloadAfterFinalize ? props.downloadCsv(props.finalizeData) : props.downloadCsv(props.pastAllocationPoData)
      } else {
        Notify.error("No data to download.");
      }
    }

  };

  const updateFinalizeInfo = () => {
    const finalizeRequest = {
      "allocation_code": originalAllocationCode,
      "user_email": localStorage?.getItem("email"),
      "user_id":Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
    }
    props.updateS2SFinalize(finalizeRequest);
  }

  const preFinalizeCheck = () => {
    if(isUpdated) {
      props.disableEdit({
        allocation_code: allocationCode,
        original_allocation_code: originalAllocationCode,
        articles: props?.location?.state?.articles?.length ? props?.location?.state?.articles : planDetails?.articles
      })
    }
    else {
      finalizeAllocation();
    }
  } 

  const handleAlertOk = () => {
    preFinalizeCheck();
    setIsShowAlert(false);
  };

  const handleAlertCancel = () => {
    setIsShowAlert(false);
  };

  const handleAlertClose = () => {
    setIsShowAlert(false);
  };

  const finalizeAllocation = () => {
    setFinalizeBtn(true)
  }

  useEffect(() => {
    if(Object.keys(props.finalizeData).length > 0) {
      !viewAllocationCallS2S && setCsvData(
        props.finalizeData?.Output
        ? props.finalizeData?.Output
        : props.finalizeData?.PO
      );
      setCsvHeader([]);
      // !viewAllocationCallS2S && setCsvHeader(props.csvHeadersAfterFinalize);
      setCsvDownloadAfterFinalize(true);
      props.updateStoreTransferQuantity(props.finalizeData);
      updateFinalizeInfo();
    }
    
  }, [props.finalizeData]);

  useEffect(() => {
    if (!isEmpty(props.csvBeforeFinalizeData))
      setCsvData(props.csvBeforeFinalizeData);
  }, [props.csvBeforeFinalizeData]);

  useEffect(() => {
    !isEmpty(props.csvBeforeFinalizeHeader) &&
      setCsvHeader(props.csvBeforeFinalizeHeader);
  }, [props.csvBeforeFinalizeHeader]);

  useEffect(() => {
    if (!isEmpty(csvHeader) && !isEmpty(csvData)) {
      setTimeout(() => {
        csvRef?.link?.click();
      }, 200);
    }
  }, [csvHeader, csvData]);

  useEffect(() => {
    if (props.csvBeforeFinalizeError ) {
      Notify.error("Something Went Wrong");
    }
    if(props.updateS2SFinalizeSuccess && props.updateStoreTransferQtySuccess){
      Notify.success("Allocation Finalized Successfully");
      props.resetAllFinalizeS2S();
      setShowFinalizeButton(false);
    }
    if(props.updateS2SFinalizeError || props.updateStoreTransferQtyError){
      Notify.error("Error in Finalize");
      props.resetAllFinalizeS2S();
    }
  }, [props.csvBeforeFinalizeError,props.updateS2SFinalizeSuccess,props.updateS2SFinalizeError,props.updateStoreTransferQtySuccess ,props.updateStoreTransferQtyError]);

  useEffect(() => {
    if (props.disableEditData && !viewAllocationCallS2S){
      finalizeAllocation(originalAllocationCode) 
    }
    if(props.disableEditError && !viewAllocationCallS2S){
      setFinalizeBtn(false);
      Notify.error("Error in Finalizing the Alocation !!")
    }
  }, [props.disableEditData, props.disableEditError])

  useEffect(() => {
    const source = axios.CancelToken.source();
   //calling the api store-transfer-po-creation
    if(isFinalizeBtnClicked) {
      props.finalizeAllocation({
        allocation_code: originalAllocationCode,
      }, source)
    }

    return () => {
        if (source) {
          source.cancel("Landing Component got unmounted");
        }
    }
  }, [isFinalizeBtnClicked])

  useEffect(() => {
    if(props.finalizeError) {
      setFinalizeBtn(false)
      Notify.error(props.finalizeError)
    }
  },[props.finalizeError])
  
  return (
    <PageLoader loader={LoaderState()}>
      {
            isShowAlert && (
              <ConfirmationAlert
                modalStyle={{ width: "45rem" }}
                message="You will not be able to modify the allocation plan post Finalize. Are you sure you want to continue ?"
                buttonLabel="Yes"
                handleOk={handleAlertOk}
                handleCancel={handleAlertCancel}
                handleClose={() => handleAlertClose()}
              />
            )
      }
      {errorModalMessage && (
        <ErrorModal
          message={errorModalMessage}
          header={errorModalHeader}
          secondaryButtonLabel={"OK"}
          handleCancel={() => {
            setErrorModalMessage(null);
          }}
          handleClose={() => setErrorModalMessage(null)}
          stsFinalizeModal
          primaryButton
        />
      )}
      <div className="container__header py-4">
        <h1 className="fnt-lg fnt-bold">{viewAllocationCallS2S ? "Detailed View" : "Finalize"}</h1>
      </div>
      <div className="row justify-content-center mb-4 plan__details">
        <div className="mr-4">
          <span className="fnt-bold mr-2">Plan Name : </span>
          <span>{originalAllocationCode || "-"}</span>
        </div>
        {props.planDetails?.creation_date && (
          <div className="mr-4">
            <span className="fnt-bold mr-2">Created on : </span>
            <span>{props.planDetails?.creation_date}</span>
          </div>
        )}
      </div>
      <div className="container__wrapper container__body">
        {showBack && (
          <button
            className="btn btn-primary back__btn"
            onClick={() => {
              viewAllocationCallS2S && props.goBack(false);
            }}
          >
            {"<< Back"}
          </button>
        )}
        <div className="container__tabs">
          <Tabs
            defaultActiveKey="store-view"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="store-view" title="Store View">
              {activeTab === "store-view" && (
                <StoreView
                  activeTab={activeTab}
                  allocationCode={allocationCode}
                  originalAllocationCode={originalAllocationCode}
                  viewAllocationCallS2S={viewAllocationCallS2S}
                  showFinalizeButton={showFinalizeButton}
                />
              )}
            </Tab>
            <Tab eventKey="product-view" title="Product View">
              {activeTab === "product-view" && (
                <ProductView
                  activeTab={activeTab}
                  allocationCode={allocationCode}
                  originalAllocationCode={originalAllocationCode}
                  viewAllocationCallS2S={viewAllocationCallS2S}
                  showFinalizeButton={showFinalizeButton}
                />
              )}
            </Tab>
          </Tabs>
        </div>
        <div className="ml-4 mt-4 text-center d-flex justify-content-center align-items-center">
        {showFinalizeButton && !viewAllocationCallS2S && <button
            className="btn btn-primary px-2 mx-4"
            onClick={() => setIsShowAlert(true)} 
          >
            Finalize
        </button>
      }
          {
            ((viewAllocationCallS2S && finalized) || !viewAllocationCallS2S) && <>
              <CSVLink
                data={!isEmpty(csvData) ? csvData : ""}
                headers={!isEmpty(csvHeader) ? csvHeader : ""}
                filename={`${allocationCode}.csv`}
                ref={(r) => (csvRef = r)}
              ></CSVLink>
              <ExportFile
                text={"Download Allocation in CSV"}
                callFunc={downloadCsv}
                type={3}
              />
            </>
          }
      </div>
      </div>
    </PageLoader>
  );
};

const mapStateToProps = ({ stsStoreView, stsProductView, stsFinalize }) => {
  return {
    alloacationCode: stsStoreView.alloacationCode,
    alloacationCodeProduct: stsProductView.alloacationCode,
    csvBeforeFinalizeData: stsFinalize.csvBeforeFinalizeData,
    csvBeforeFinalizeLoading: stsFinalize.csvBeforeFinalizeLoading,
    csvBeforeFinalizeError: stsFinalize.csvBeforeFinalizeError,
    csvBeforeFinalizeHeader: stsFinalize.csvBeforeFinalizeHeader,
    updateS2SFinalizeLoading: stsFinalize.updateS2SFinalizeLoading,
    updateS2SFinalizeError: stsFinalize.updateS2SFinalizeError,
    updateS2SFinalizeSuccess:stsFinalize.updateS2SFinalizeSuccess,
    pastAllocationPoLoading:stsFinalize.pastAllocationPoLoading,
    pastAllocationPoData:stsFinalize.pastAllocationPoData,
    pastAllocationPoError:stsFinalize.pastAllocationPoError,
    downloadCsvData: stsFinalize.downloadCsvData,
    disableEditLoading: stsFinalize.disableEditLoading,
    disableEditData: stsFinalize.disableEditData,
    disableEditError: stsFinalize.disableEditError,
    finalizeLoading: stsFinalize.finalizeLoading,
    finalizeError: stsFinalize.finalizeError,
    finalizeData: stsFinalize.finalizeData,
    updateStoreTransferQtyLoading:stsFinalize.updateStoreTransferQtyLoading,
    updateStoreTransferQtySuccess:stsFinalize.updateStoreTransferQtySuccess,
    updateStoreTransferQtyError:stsFinalize.updateStoreTransferQtyError,
    csvHeadersAfterFinalize:stsFinalize.csvHeadersAfterFinalize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  downloadCsvBeforeFinalize: (payload) => dispatch(downloadCsvBeforeFinalize(payload)),
  updateS2SFinalize:(payload) => dispatch(updateS2SFinalize(payload)),
  resetAllFinalizeS2S:() => dispatch(resetAllFinalizeS2S()),
  getPastAllocationPOS2S:(payload) => dispatch(getPastAllocationPOS2S(payload)),
  downloadCsv: (payload) => dispatch(downloadCsv(payload)),
  disableEdit: (payload) => dispatch(disableEdit(payload)),
  finalizeAllocation: (payload, source) => dispatch(finalizeAllocation(payload, source)),
  updateStoreTransferQuantity: (payload) => dispatch(updateStoreTransferQuantity(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(STSFinalize);
