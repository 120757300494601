import React from "react";
import ReactTable from "../../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../../components/Table/WithPlugins";
import PageLoader from "../../../../../../components/Loader/PageLoader";
import ColumnFilter from "../../../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../../../components/Filters/NumberRangeColumnFilter";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductViewTable = (props) => {
  const { data, loading, getStores,setStyleColor } = props;


  const COLUMNS = [
    {
      Header: "Style Color",
      accessor: "article",
      Cell: ({ value }) => (
        <div>
          <div className="clickable" onClick={() => {
            setStyleColor(value);
            getStores(value)
            }}>
            {value}
          </div>
        </div>
      ),
      Filter: (instance) => (
        <ColumnFilter {...instance} placeholder="Search in bulk by ','" />
      ),
      filter: "bulkFilterCommaSeperated",
      sticky: "left",
    },
    {
      Header: "Style Description",
      accessor: "style_name",
      Filter: ColumnFilter,
    },
    {
      Header: "Channel",
      accessor: "l1_name",
      Filter: ColumnFilter,
    },
    {
      Header: "Department",
      accessor: "l2_name",
      Filter: ColumnFilter,
    },
    {
      Header: "Class",
      accessor: "l3_name",
      Filter: ColumnFilter,
    },
    {
      Header: "Total Size Count",
      accessor: "total_size_count",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Allocated Size Count",
      accessor: "size_count",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Source Count",
      accessor: "source_count",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Destination Count",
      accessor: "destination_count",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Transfer Units",
      accessor: "transfer_units",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Net Available",
      accessor: "net_available_to_transfer",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
    {
      Header: "Net Requirement",
      accessor: "net_req_in_dest",
      Filter: NumberRangeColumnFilter,
      filter: 'between'
    },
  ];

  return (
    <PageLoader loader={loading} gridLoader={true}>
      <section className="section mt-4 m-0">
        <ReactTableWithPlugins
          shouldPagination
          totalRecordsLen={data ? data.length : 0}
          data={data ? data : []}
          columns={COLUMNS}
          renderMarkup="TableMarkup"
          keyRT={"sortAndSearch"}
          tableId="sts_product_view"
        />
      </section>
    </PageLoader>
  );
};

export default ProductViewTable;
