import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import WithDynamicPlugins from "../../../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../../../UserManagement/ColumnFilter";
import ReactTable from "../../../../../../components/Table/ReactTable";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const AvailableTable = forwardRef((props, ref) => {
  const { data, sizes } = props;

  const [availableData, setAvailableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [RTInstance, setRTInstance] = useState({});

  const generateData = useCallback(() => {
    const stores = {};
    let availableCols = [
      {
        Header: "Source Store",
        accessor: "store",
        Filter: (instance) => (
          <ColumnFilter {...instance} placeholder="Search in bulk by ','" />
        ),
        filter: "bulkFilterCommaSeperated",
        sticky: "left"
      },
    ];
    data.forEach((item) => {
      item.subRows.forEach((sub) => {
        if (!stores[item?.source_store]) {
          stores[item?.source_store] = {
            store: item?.source_store,
            [sub.size]: sub.post_source_inv,
          };
        } 
        // else if (sub.size in stores[item?.source_store]) {
        //   stores[item?.source_store][sub.size] -= sub.transfer_units;
        // } 
        else {
          stores[item?.source_store][sub.size] = sub.post_source_inv;
        }
      });
    });
    availableCols = [
        ...availableCols,
        ...sizes.map((size) => {
          return {
            Header: size,
            accessor: (data) => data[size],
            disableFilters: true,
          };
        }),
      ];
    setColumns(availableCols);
    setAvailableData(Object.values(stores));
  }, [data]);

  useEffect(() => {
    if (data?.length) {
      generateData();
    }
  }, [data]);

  useImperativeHandle(ref, () => ({
    handleUnitsOnChange(source_store, size, value) {
      const modified = [];
      availableData.forEach((val) => {
        if (val.store === source_store) {
          val[size] = Number(val[size]) - Number(value);
        } else {
          val[size] = val[size];
        }
        modified.push(val);
      });
      setAvailableData(modified);
    },
    getAvailableData() {
      return availableData;
    }
  }));

  return (
    <>
      <div className="text-center mt-5">
        <h1 className="fnt-md fnt-bold mb-4">Available Units</h1>
      </div>
      <ReactTableWithPlugins
        embededScroll
        data={availableData ? availableData : []}
        columns={columns}
        renderMarkup="TableMarkup"
        keyRT={"sortAndSearch"}
        tableId="sts_product_store_view"
        getInstanceOnMount={(instance) => {
          setRTInstance(instance);
        }}
        tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
        style={{minWidth: "unset", maxWidth:'fit-content', margin:'auto'}}
        hideDropdown
        hideColumnsFilter
      />
    </>
  );
});

export default AvailableTable;
