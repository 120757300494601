export const DEMAND_TYPE_OPTIONS = [
  // { label: "Fixed", value: "Fixed" },
  { label: "IA Recommended", value: "IA" },
];

export const TRANSFER_STRATEGY_OPTIONS = [
  {
    label: "Min and demand",
    value: "Min and demand",
  },
  {
    label: "Demand only",
    value: "Demand only",
  },
  {
    label: "Min only",
    value: "Min only",
  },
];
