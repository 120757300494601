import styled from "styled-components";
const Styles = styled.div`
  overflow-x: auto;
  overflow-y: auto;

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rt-btn-prev {
      margin-right: 1.8rem;
    }

    .rt-btn {
      margin-right: 5px;
      margin-left: 5px;
    }

    input {
      height: 2.7rem;
      padding: 1rem;
      font-weight: 600;
      width: 6.4rem !important;
      background: transparent;
      border: 1px solid gray;
      font-size: 1.6rem;
    }
  }

  input[type="checkbox"] {
    width: 16px; /*Desired width*/
    height: 16px; /*Desired height*/
    cursor: pointer;
  }

  table {
    border: 1px solid #ddd;
    font-size: 1.3rem;
    ${'' /* user-select: none; */}
    ${"" /* overflow: scroll; */}
    max-height: 500px;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
          border-bottom: 1px solid #ddd;
        }
      }
    }

    .th {
      font-weight: bold;
      color: white !important;
      background-color: var(--color-primary) !important;
      position: sticky;
    }

    .td {
      color: black !important;
      font-weight: 400;
      border: 1px solid #ddd;
    }

    .no-border-bottom {
      border: none !important;
    }

    .td > input {
      background: #dcefff !important;
      border: 1px solid gray;
    }

    .ExapandCollapseBtn {
      padding-right: 8px;
      padding-left: 6px;
    }

    .th,
    .td {
      display: flex !important;
      justify-content: center;
      align-items: center;
      ${'' /* min-width: 150px; */}
      min-height: 38px;
      line-height: 1.8;
      text-align: center;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;
      white-space: break-spaces;
      :last-child {
        border-right: 0;
      }
    }

    .td_green {
      background-color: #28a745 !important;
      color: white !important;
    }

    .rt-grouped-td {
      ${"" /* justify-content: flex-start; */}
      text-align: left;
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky !important;
        width: fit-content;
      }
      .header {
        top: 0;
        ${"" /* box-shadow: 0px 3px 3px #ccc; */}
        border-bottom: 2px solid #ddd;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .loaderRT {
        height: 90%;
        color: #44677b;
        ${"" /* display: flex;
        justify-content: center;
        align-items: center; */}
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        text-align: left !important;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

export default Styles;
