import { all, call } from "redux-saga/effects";
import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import middleware, { sagaMiddleware } from "./middleware";
import demoReducer from "../../containers/Demo/reducer";
import { demoSaga } from "../../containers/Demo/saga";
import StoreGroupReducer from "../../containers/StoreGroup/StoreGroupReducer";
import { storeGroupSaga } from "../../containers/StoreGroup/StoreGroupSaga";

import strategyTableReducer from "../../containers/Strategy/reducer";
import { strategySaga } from "../../containers/Strategy/saga";

import constraintsReducer from "../../containers/Constraints/ConstraintsReducer";
import { constraintsSaga } from "../../containers/Constraints/ConstraintsSaga";

import productProfileReducer from "../../containers/ProductProfile/ProductProfileReducer";
import { productProfileSaga } from "../../containers/ProductProfile/ProductProfileSaga";

import dashboardReducer from "../../containers/Dashboard/DashboardReducer";
import { dashboardSaga } from "../../containers/Dashboard/DashboardSaga";

import userManagementReducer from "../../containers/UserManagement/UserManagementReducer"
import { userManagementSaga } from "../../containers/UserManagement/UserManagementSaga"
import { loginSaga } from "../../containers/Login/LoginSaga";
import loginReducer from "../../containers/Login/LoginReducer";

import finalizeReducer from "../../containers/Finalize/FinalizeReducer";
import { finalizeSaga } from "../../containers/Finalize/FinalizeSaga";

import viewAllocationReducer from "../../containers/ViewAllocation/ViewAllocationReducer";
import { viewAllocationSaga } from "../../containers/ViewAllocation/ViewAllocationSaga";

import adaVisualReducer from "../../containers/AdaVisual/reducers/AdaVisualReducer";
import { adaVisualSagas } from "../../containers/AdaVisual/sagas/AdaVisualSagas";

import draftsReducer from "../../containers/Drafts/DraftsReducer";
import { draftsSaga } from "../../containers/Drafts/DraftsSaga";

import reportingReducer from "../../containers/Reporting/ReportingReducer";
import { reportingSaga } from "../../containers/Reporting/ReportingSaga"

import excessReportReducer from "../../containers/Reporting/ExcessReport/Reducer/ExcessReducer";
import { excessSaga } from "../../containers/Reporting/ExcessReport/ExcessSaga"

import deepDiveReducer from "../../containers/Reporting/DeepDive/DeepDiveReducers";
import { deepDiveSaga } from "../../containers/Reporting/DeepDive/DeepDiveSaga";

import dailySummaryReducers from "../../containers/Reporting/DailySummary/CombinedDailySummaryreducers";
import { dailySummarySagas } from "../../containers/Reporting/DailySummary/DailyCombinedSagas";

import productConfigReducers from "../../containers/ProductStoreStatus/ProductStatus/Reducers/ProductConfigtReducers"
import { productStatusSaga } from "../../containers/ProductStoreStatus/ProductStatus/ProductStatusSaga"
import productStoreReducer from "../../containers/ProductStoreStatus/ProductStoreReducer"
import { productStoreStatusSaga } from "../../containers/ProductStoreStatus/ProductStoreSaga";

import lostSalesReportReducer from "../../containers/Reporting/LostSalesReport/reducers/LostSalesReportReducers";
import { lostSalesReportSagas } from "../../containers/Reporting/LostSalesReport/sagas/LostSalesReportSagas";

import newStoreSetUpReducer from "../../containers/NewStoreSetUp/reducers/NewStoreReducers";
import { newStoreSetUpSagas } from "../../containers/NewStoreSetUp/sagas/NewStoreSagas";

import strategyConfigReducers from "../../containers/StrategyConfiguration/reducers/StrategyConfigReducers";
import { strategyConfigSagas } from "../../containers/StrategyConfiguration/sagas/StrategyConfigurationSagas";

import poAllocationReducer from "../../containers/Dashboard/POAllocation/POAllocationReducer/POAllocaionReducer";
import { InventoryReservationSagas } from "../../containers/ProductStoreStatus/InventoryReservation/Sagas/InventoryReservationSagas";
import InventoryReservationReducers from "../../containers/ProductStoreStatus/InventoryReservation/Reducers/InventoryReservationReducers";

import nsApproveReleaseReducers from "../../containers/NewStoreApproveRelease/reducers/CombineReducer"
import { nsApproveSaga } from "../../containers/NewStoreApproveRelease/sagas/NewStoreApproveSaga";

import storeCapacityReducer from "../../containers/Finalize/StoreCapacity/reducer/StoreCapacityReducer"
import { storeCapacitySaga } from "../../containers/Finalize/StoreCapacity/saga";

import excelDownloadReducer from "../../components/ParallelExcelDownload/Reducer/ExcelReducer"
import { downloadExcelSaga } from "../../components/ParallelExcelDownload/DownloadExcelSaga";

import storeTransfer from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreTransferConfiguration/StoreTransferConfigurationReducer"
import {StoreTransferConfigurationSaga} from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreTransferConfiguration/StoreTransferConfigurationSaga"

import storeTransferRuleReducer from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreTransferReducers"
import { storeTransferSagas } from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreTransferSagas"

import { storeToStoreFinalizeSaga } from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreToStoreFinalize/saga";
import STSFinalizeReducer from "../../containers/ProductStoreStatus/StoreToStoreConfig/StoreToStoreFinalize/reducer";

import {storeToStoreSaga} from "../../containers/Store2Store/StoretoStoreSaga";
import storeToStoreReducer from "../../containers/Store2Store/StoretoStoreReducer";


const rootSaga = function* () {
  yield all([
    demoSaga(),
    strategySaga(),
    storeGroupSaga(),
    constraintsSaga(),
    productProfileSaga(),
    dashboardSaga(),
    userManagementSaga(),
    loginSaga(),
    finalizeSaga(),
    viewAllocationSaga(),
    adaVisualSagas(),
    draftsSaga(),
    reportingSaga(),
    productStatusSaga(),
    productStoreStatusSaga(),
    excessSaga(),
    lostSalesReportSagas(),
    deepDiveSaga(),
    dailySummarySagas(),
    newStoreSetUpSagas(),
    strategyConfigSagas(),
    InventoryReservationSagas(),
    nsApproveSaga(),
    storeCapacitySaga(),
    downloadExcelSaga(),
    StoreTransferConfigurationSaga(),
    storeTransferSagas(),
    storeToStoreFinalizeSaga(),
    storeToStoreSaga(),
  ]);
};

const rootReducer = {
  ...StoreGroupReducer,
  ...demoReducer,
  ...strategyTableReducer,
  ...constraintsReducer,
  ...productProfileReducer,
  ...dashboardReducer,
  ...userManagementReducer,
  ...loginReducer,
  ...finalizeReducer,
  ...viewAllocationReducer,
  ...adaVisualReducer,
  ...draftsReducer,
  ...reportingReducer,
  ...productConfigReducers,
  ...productStoreReducer,
  ...excessReportReducer,
  ...lostSalesReportReducer,
  ...deepDiveReducer,
  ...dailySummaryReducers,
  ...newStoreSetUpReducer,
  ...strategyConfigReducers,
  ...poAllocationReducer,
  ...InventoryReservationReducers,
  ...nsApproveReleaseReducers,
  ...storeCapacityReducer,
  ...excelDownloadReducer,
  ...storeTransfer,
  ...storeTransferRuleReducer,
  ...STSFinalizeReducer,
  ...storeToStoreReducer,
};

const reducer = persistReducer(
  {
    key: "rrsb-allocation", // key is required
    storage, // storage is now required
    whitelist: ["demo"],
  },
  combineReducers({ ...rootReducer })
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};
const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
