import React from "react";
import ReactTable from "../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../components/Table/WithPlugins";
import { COLUMNS } from "./Constants";
import { isEmpty } from "lodash";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ReviewStoresTable = (props) => {
  const { data, initialSelectedRows, setRTInstance } = props;
  return (
    <ReactTableWithPlugins
      shouldPagination
      totalRecordsLen={data ? data.length : 0}
      data={data ? data : []}
      columns={COLUMNS}
      // features={!props?.isAddActionColumn ? ["CHECKBOX_FOR_FIRSTCOLUMN"] : []}
      features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
      renderMarkup="TableMarkup"
      keyRT={setRTInstance ? "StyleInvTable" : "sortAndSearch"}
      {...(!isEmpty(initialSelectedRows) ? { initialSelectedRows: initialSelectedRows} : {})}
      getInstanceOnMount={(instance) => {
        setRTInstance && setRTInstance(instance);
      }}
      tableId="store_transfer_rule_store_mapping"
      conditionalClassName="td_green"
      conditionalClassKey="new_comb_flag"
    />
  );
};

export default ReviewStoresTable;
