import * as actionTypes from "./ActionTypes"


export const getRules = (payload) => ({
    type: actionTypes.GET_STORE_TRANSFER_RULES,
    payload
})

export const getRuleMapping = (payload) => ({
    type: actionTypes.GET_RULE_MAPPING,
    payload
})

export const deleteRule = (payload) => ({
    type: actionTypes.DELETE_STORE_TRANSFER_RULE,
    payload
})