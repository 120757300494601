import { all } from "redux-saga/effects";
import { createRuleSaga } from "./CreateStoreTransferRule/saga"
import { storeTransferRulesSaga } from "./StoreTransferRules/saga"

/**
 * Root saga
 */
export function* storeTransferSagas() {
    yield all([
        createRuleSaga(),
        storeTransferRulesSaga()
    ]);
}