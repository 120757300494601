import * as actionTypes from "./ActionTypes"

export const getStoreViewSummary = (payload) => ({
    type: actionTypes.GET_STORE_VIEW_SUMMARY,
    payload
})

export const getStoreProductView = (payload) => ({
    type: actionTypes.GET_STORE_PRODUCT_VIEW,
    payload
})

export const updateTransferUnitsForMultipleStores = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES,
    payload
})

export const updateTransferUnitsForMultipleStoresSuccess = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES_SUCCESS,
    payload
})

export const updateTransferUnitsForMultipleStoresError = (payload) => ({
    type: actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES_ERROR,
    payload
})

export const resetAll = () => ({
    type: actionTypes.RESET_ALL,
  });