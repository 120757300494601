import React, { useState, useEffect } from "react";

const SelectCellComponent = React.memo(({ ...instance }) => {
  let { options, defaultValue, updateMyData, row, column, changeHandler, disabled } = instance;
  
  const [dropdownValue, setDropdownValue] = useState();

  return (
    <>
      <select
        onChange={(e) => {
          if (changeHandler) {
            changeHandler(
              row.index,
              column.id,
              e.target.value,
              row.id,
              e.target.idTemp,
              row
            );
          }
          setDropdownValue(e.target.value);
          updateMyData(
            row.index,
            column.id,
            e.target.value,
            row.id,
            e.target.idTemp,
            row
          );
        }}
        className="custom-select"
        value={dropdownValue}
        disabled={disabled || false}
      >
        {options && options.length > 0 && (
          <option selected disabled className="items">
            Select Option
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((opt) => {
            if (opt.value === defaultValue) {
              return (
                <option selected value={opt.value} className="items">
                  {opt.label}
                </option>
              );
            } else {
              return (
                <option value={opt.value} className="items">
                  {opt.label}
                </option>
              );
            }
          })}
      </select>
    </>
  );
});

export default SelectCellComponent;
