import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";
import * as actionTypes from "../Actions/ActionTypes";

export default {
  stsStoreView: handleActions(
    {
      [actionTypes.GET_STORE_VIEW_SUMMARY]: (state, payload) => {
        return immutable(state, {
          storeSummaryData: { $set: [] },
          storeSummaryLoading: { $set: true },
          storeSummaryError: { $set: null },
          storeSummarySuccess: { $set: false },
          storeProductData: { $set: [] },
          storeProductLoading: { $set: false },
          storeProductError: { $set: null },
          storeProductSuccess: { $set: false },
        });
      },
      [actionTypes.GET_STORE_VIEW_SUMMARY_SUCCESS]: (state, payload) => {
        return immutable(state, {
          storeSummaryData: { $set: payload?.data },
          storeSummaryLoading: { $set: false },
          storeSummaryError: { $set: null },
          storeSummarySuccess: { $set: true },
        });
      },
      [actionTypes.GET_STORE_VIEW_SUMMARY_ERROR]: (state, payload) => {
        return immutable(state, {
          storeSummaryData: { $set: [] },
          storeSummaryLoading: { $set: false },
          storeSummaryError: { $set: (payload && payload.error) || null },
          storeSummarySuccess: { $set: false },
        });
      },
      [actionTypes.GET_STORE_PRODUCT_VIEW]: (state, payload) => {
        return immutable(state, {
          storeProductData: { $set: [] },
          storeProductLoading: { $set: true },
          storeProductError: { $set: null },
          storeProductSuccess: { $set: false },
        });
      },
      [actionTypes.GET_STORE_PRODUCT_VIEW_SUCCESS]: (state, payload) => {
        return immutable(state, {
          storeProductData: { $set: payload?.data },
          storeProductLoading: { $set: false },
          storeProductError: { $set: null },
          storeProductSuccess: { $set: true },
        });
      },
      [actionTypes.GET_STORE_PRODUCT_VIEW_ERROR]: (state, payload) => {
        return immutable(state, {
          storeProductData: { $set: [] },
          storeProductLoading: { $set: false },
          storeProductError: { $set: (payload && payload.error) || null },
          storeProductSuccess: { $set: false },
        });
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES]: (state) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: true },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: false },
        });
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
            alloacationCode: { $set: (payload && payload.allocationCode) || state.allocationCode},
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: (payload && payload.data) || false },
            updateTransferUnitsError: { $set: false },
        })
      },
      [actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES_ERROR]: (state, { payload }) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: true },
        })
      },
      [actionTypes.RESET_ALL]: (state, { payload }) => {
        return immutable(state, {
            updateTransferUnitsLoading: { $set: false },
            updateTransferUnitsData: { $set: false },
            updateTransferUnitsError: { $set: false },
        })
      },
    },
    initialState
  ),
};
