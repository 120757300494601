import React, { useState, useEffect } from "react";
import Filter from "../ProductProfile/ProductProfileTable/Filter";
import {
  shouldHideForCurrentClient,
  getLevelFiltersForUserPreference,
  USER_PREFERENCE_FILTERS,
  fetchFiltersOnReset,
  currentClientName,
  flattenArray,
} from "../../utils/commonUtilities";
import useSelectFilterStoretoSore from "./useSelectFilterStoretoStore";
import { getReviewRecommendData } from "../Dashboard/DashboardActions";
import * as Notify from "../../components/Notification/Notifications";
import {
  getUserPreferenceFilter,
  getFilterForClients,
  getDefaultFiltersCondition,
  setUserPreferenceFilter,
} from "../../utils/commonUtilities";
import { isEmpty } from "lodash";
import {
  // fetchFilterData,
  // resetFiltersOptions,
  resetPageIndex,
} from "../Dashboard/DashboardActions";
import { fetchFilterData, resetFiltersOptions } from "./StoretoStoreActions";
import { connect } from "react-redux";

const Store2StoreFilters = (props) => {
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [level1Options, setlevel1Options] = useState(null);
  const [level2Options, setlevel2Options] = useState(null);
  const [level3Options, setlevel3Options] = useState(null);
  const [level4Options, setlevel4Options] = useState(null);
  const [level5Options, setlevel5Options] = useState(null);
  const [level6Options, setlevel6Options] = useState(null);
  const [level7Options, setlevel7Options] = useState(null);

  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    request,
    resestRequest,
    level5,
    setLevel5,
    onLevel5Change,
    level6,
    setLevel6,
    onLevel6Change,
    level7,
    setLevel7,
    onLevel7Change,
  ] = useSelectFilterStoretoSore(
    props.fetchFilterData,
    true,
    level2Options,
    level3Options,
    level4Options,
    setlevel2Options,
    setlevel3Options,
    setlevel4Options,
    level5Options,
    setlevel5Options,
    level6Options,
    setlevel6Options,
    level7Options,
    setlevel7Options
  );
  useEffect(() => {
    setlevel1Options(() => props.level1Options);
  }, [props.level1Options]);

  useEffect(() => {
    setlevel2Options(() => props.level2Options);
  }, [props.level2Options]);

  useEffect(() => {
    setlevel3Options(() => props.level3Options);
  }, [props.level3Options]);

  useEffect(() => {
    setlevel4Options(() => props.level4Options);
  }, [props.level4Options]);

  useEffect(() => {
    setlevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    setlevel6Options(() => props.level6Options);
  }, [props.level6Options]);

  useEffect(() => {
    setlevel7Options(() => props.level7Options);
  }, [props.level7Options]);

  const prep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      style: { marginBottom: "10px" },
    },
    getFilterForClients(
      {
        id: 2,
        label: props.filterLabels?.level2,
        name: "level2",
        dropdownValue: level2,
        onDropDownChange: onLevel2Change,
        placeHolder: `Select ${
          props.filterLabels?.level2 ? props.filterLabels?.level2 : ""
        }`,
        optionsArray: level2Options?.length ? level2Options : [],
        style: { marginBottom: "10px" },
        isStandAlone: false,
        level: "l2_name",
        isRequired: true,
      },
      "dashboard"
    ),
    getFilterForClients({
      id: 3,
      label: props.filterLabels?.level3,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${
        props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
      }`,
      optionsArray: level3Options?.length ? level3Options : [],
      hide: shouldHideForCurrentClient("l3_name"),
      style: { marginBottom: "10px" },
      isStandAlone: false,
      level: "l3_name",
    }),
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${
        props.filterLabels?.level4 ? props.filterLabels?.level4 : ""
      }`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
      style: { marginBottom: "10px" },
    },
    {
      id: 6,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${
        props.filterLabels?.level5 ? props.filterLabels?.level5 : ""
      }`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name"),
    },
    {
      id: 7,
      isRequired: false,
      label: props.filterLabels?.level6,
      name: "level6",
      dropdownValue: level6,
      onDropDownChange: onLevel6Change,
      placeHolder: `Select ${
        props.filterLabels?.level6 ? props.filterLabels?.level6 : ""
      }`,
      optionsArray: level6Options?.length ? level6Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l6_name"),
    },
    {
      id: 8,
      isRequired: false,
      label: props.filterLabels?.level7,
      name: "level7",
      dropdownValue: level7,
      onDropDownChange: onLevel7Change,
      placeHolder: `Select ${
        props.filterLabels?.level7 ? props.filterLabels?.level7 : ""
      }`,
      optionsArray: level7Options?.length ? level7Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l7_name"),
    },
  ];

  const applyFilters = () => {
    if (
      getDefaultFiltersCondition({
        l1_name: level1,
        l2_name: level2,
        l3_name: level3,
      })
    ) {
      let req = {
        l1_name: [level1?.value],
        l2_name: Array.isArray(request?.level2?.[0])
          ? request?.level2?.[0]?.map((val) => val.value)
          : request?.level2?.map((val) => val.value),
        l3_name: Array.isArray(request?.level3?.[0])
          ? request?.level3?.[0]?.map((val) => val.value)
          : request?.level3?.map((val) => val.value),
        l4_name: Array.isArray(request?.level4?.[0])
          ? request?.level4?.[0]?.map((val) => val.value)
          : request?.level4?.map((val) => val.value),
        l5_name: Array.isArray(request?.level5?.[0])
          ? request?.level5?.[0]?.map((val) => val.value)
          : request?.level5?.map((val) => val.value),
        l6_name: Array.isArray(request?.level6?.[0])
          ? request?.level6?.[0]?.map((val) => val.value)
          : request?.level6?.map((val) => val.value),
        storesFilter: "Store Transfer available",
        store_transfer: true,
      };
      setUserPreferenceFilter(
        { user_id: localStorage.getItem("user_id") },
        getLevelFiltersForUserPreference({ l1_name: level1, l2_name: level2 })
      );
      props.setFilters({ ...req });
      props.setStoreTransferDataCall(true);

      props.getStore2StoreData(req);
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  const resetFilters = () => {
    let l_restrictResetForLevels = USER_PREFERENCE_FILTERS[
      currentClientName
    ] || ["l1_name"];
    // setLevel1(null);
    !l_restrictResetForLevels.includes("l2_name") && setLevel2(null);
    !l_restrictResetForLevels.includes("l3_name") && setLevel3(null);
    getDefaultFiltersCondition({ setLevel2: setLevel2, setLevel3: setLevel3 });
    setLevel4(null);
    setLevel5(null);
    setLevel6(null);
    setLevel7(null);
    resestRequest();

    props.resetFiltersOptions([]);
    // let req = getRequestForChartAPIAfterReset({l1_name:[level1], l2_name: [level2], l3_name: [level3]})
    let isSetLevel2 = false;
    if (!props.isViewAllocation) {
      fetchFiltersOnReset({
        l1_name: level1,
        l2_name: level2,
        l3_name: level3,
        onl1Change: onLevel1Change,
        onl2Change: onLevel2Change,
        onl3Change: onLevel3Change,
      });
    } else {
      setLevel1(null);
      setLevel2(null);
      setLevel3(null);
    }
    props?.resetFilters();
  };

  useEffect(() => {
    getUserPreferenceFilter(
      { user_id: localStorage.getItem("user_id") },
      { onl1Change: onLevel1Change, onl2Change: onLevel2Change }
    );
    props.fetchFilterData({}, { key: "l1" });
  }, []);

  useEffect(() => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"));
    if (level1Options?.length && isEmpty(getFilter?.filters)) {
      setLevel1(level1Options[0]);
      props.fetchFilterData({ l1_name: [level1Options[0]] }, { key: "l2" });
      setIsFirstCall(false);
    }
  }, [level1Options]);
  return (
    <div className="row section">
      <Filter data={prep} />
      <div
        className="col-12 col-md-3 mt-2"
        style={{ margin: 0, marginTop: "1rem" }}
      >
        <button
          onClick={applyFilters}
          className="btn btn-primary filter-constraints-mode mr-2"
          title="Apply filters"
        >
          <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
        </button>
        <button
          onClick={() => resetFilters("All")}
          className="btn undo-constraints-mode"
          title="Reset filters"
        >
          <i className="fa fa-undo mr-2"></i>Reset
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ storetostore }) => ({
  level1Options: storetostore.level1Options,
  level2Options: storetostore.level2Options,
  level3Options: storetostore.level3Options,
  level4Options: storetostore.level4Options,
  level5Options: storetostore.level5Options,
  level6Options: storetostore.level6Options,
  level7Options: storetostore.level7Options,
  filterLabels: storetostore.filterlabels,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload, filterType) =>
    dispatch(fetchFilterData(payload, filterType)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  getReviewRecommendData: (payload) =>
    dispatch(getReviewRecommendData(payload)),
  resetPageIndex: () => dispatch(resetPageIndex()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Store2StoreFilters);
