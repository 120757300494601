import React from "react";

const ColorIndicator = (props) => {
  const { value, flag, color, className } = props;
  return (
    <div className={"row min-100"}>
      <span
        className={"min-100 pt-2 " + className}
        style={flag ? { background: color, color: "white" } : {}}
      >
        {value}
      </span>
    </div>
  );
};

export default ColorIndicator;
