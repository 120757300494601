import React from "react";
import { useState, useEffect } from "react";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ErrorModal from "../../../../components/ErrorModal/ErrorModal";
import { ERROR_MSG } from "../../../../constants/globalConstant";
import SelectCellComponent from "../../../../components/Table/Cellrenderer/SelectCellComponent";
import PageLoader from "../../../../components/Loader/PageLoader";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import DateInput from "../../../../components/Table/Cellrenderer/DateInput";
import * as Notify from "../../../../components/Notification/Notifications";
import { cloneDeep, forEach, isEmpty, isEqual } from 'lodash';
import moment from "moment";
import {
  ALLOCATION_REQUIRED_OPTIONS,
  TRANSFER_STRATEGY_OPTIONS,
} from "./StoretoStoreconfigConstants";
import DatePicker, {
  today,
} from "../../../../components/DatePicker/DatePicker.jsx";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal.jsx";
import { removeCheckedArticles } from "../../../../utils/paginationHelper";
import ColumnFilterServerSide from "../../../../components/Filters/ColumnFilterServerSide.jsx"
import NumberRangeColumnFilterServerSide from "../../../../components/Filters/NumberRangeColumnFilterServerSide.jsx"
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const getEndDate = (p_end, p_start) => {
  let d1 = moment(p_end);
  let d2 = moment(p_start);
  if (d1 > d2) {
    return moment(d1).format("YYYY-MM-DD");
  } else {
    return moment(d2).format("YYYY-MM-DD");
  }
};

const StoreTransferConfigTable = (props) => {
  const { setRTInstance,updateArticleData ,fecthDataWithSearchTerm ,fecthDataWithSorting ,searchTermReq,fetchData , totalCount,initialPageIndex,checkAllReset ,transfer_strategy} = props;

  const [disabled, setDisabled] = useState(true);
  const [updatedId, setUpdatedId] = useState([]);
  const [articleTableInstanse, setArticleTableInstanse] = useState({});
  const [showSetAll, setShowSetAll] = useState(false);
  const [allocationStatus, setallocationStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [uptoDate, setUptoDate] = useState(null);
  const [transferStrategy, setTransferStrategy] = useState(null);
  const [dcInventory, setDcInventory] = useState(null);
  const [dcInTransit, setDCInTransit] = useState(null);
  const [prevAction, setPrevAction] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkAll, setCheckAll] = useState([]);
  const [editrule,setEditRule]= useState({});
  useEffect(() => {
    setCheckAll([])
    // setInitialSelectedRows({})
  }, [checkAllReset])

  useEffect(() => {
   setDisabled(true);
   setUpdatedId([]);
  },[props.data])

  const styleFilter ={    
    flex: '0 0 45%',
    maxWidth: '45%',
    margin:'10px'
  }
  
  const setSelectedArticles = (instance) => {
    setArticleTableInstanse(instance);
    // setDisableBulkEdit(!(instance?.getSelectedRows?.length > 0))
    setRTInstance(instance);
  };

  const updateChanges = () => {
    let uniqueUpdatedIds = [...new Set(updatedId)];
    let setAllRows = articleTableInstanse?.flatRows
      ?.filter((val) => uniqueUpdatedIds.includes(val.original.article))
      .map((row) => row?.id);
    props.saveProductStatusData(uniqueUpdatedIds, setAllRows,editrule);
    setCheckAll([])
    setUpdatedId([]);
    setEditRule({});
    // setInitialSelectedRows({})
    setDisabled(true);
  };

  const onAllocationStatusChange = (val) => {
    setallocationStatus(val.value);
    if (val.value === "Paused") {
      setFromDate(moment(new Date()).format("MM/DD/YYYY"));
      setUptoDate(moment(new Date()).format("MM/DD/YYYY"));
    }
  };
  const onTransferStrategyChange = (val) => {
    setTransferStrategy(val.value);
  };

  const onDcInventoryChange = (val) => {
    setDcInventory(Number(val));
  };

  const onDcInTransitChange = (val) => {
    setDCInTransit(Number(val));
  };

  const storeTransferConfigRuleColumns = [
    {
      Header: " ",
      sticky: "left",
      columns: [
        {
          Header: "Style Color ID",
          accessor: "article",
          Filter: (instance) => (
            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
        ),
          filter: "bulkFilterCommaSeperated"
        },
        {
          Header: "Style",
          accessor: "style",
          Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
          // filter: 'bulkFilterCommaSeperated'
        },
        {
          Header: "Style Description",
          accessor: "style_name",
          Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
          minWidth:190
        },
      ],
    },

    {
      Header: "Color",
      accessor: "color",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
    },
    {
      Header: "Department",
      accessor: "l2_name",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
    },
    {
      Header: "Class",
      accessor: "l3_name",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
    },
    {
      Header: "Sub Class",
      accessor: "l4_name",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
    },
    {
      Header: "Transfer Strategy",
      accessor: "transfer_strategy",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.transfer_strategy?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
      filter: 'filterSingleSelectCell',
      Cell: (instance) => (
        <div>
          <SelectCellComponent
            defaultValue={instance.row.original.transfer_strategy}
            options={instance.row.original?.transfer_strategy_options}
            changeHandler={(rowIdx, columnId, values) => {
              setDisabled(false);
              setUpdatedId((old) => {
                return [...old, instance.row.original?.article];
              });
            }}
            {...instance}
          />
        </div>
      ),
      minWidth: 250,
      disableSortBy: true
    },
    {
      Header: "Transfer Rule",
      accessor: "rule_id",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.rule_id?.value || searchTermReq?.rule_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
      filter: 'filterSingleSelectCell',
      Cell: (instance) => (
        <div style={{minWidth: "80%"}}>
          
          <SelectCellComponent
            defaultValue={Number(instance.row.original?.rule_id)}
            options={instance.row.original?.transfer_rule?.options.length > 0 ? instance.row.original?.transfer_rule?.options : []}
            changeHandler={(rowIdx, columnId, value) => {
              // {console.log("the value of instance is", rowIdx, columnId)}
              setDisabled(false);
              setEditRule((old) => {
                if (props.data[rowIdx].rule_id == value) {
                  return { ...old, [instance.row.original.article]: false };
                } else {
                  return { ...old, [instance.row.original.article]: true };
                }
              });
              setUpdatedId((old) => {
                return [...old, instance.row.original?.article];
              });
            }}
            {...instance}
          />
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: `DC Inv Threshold (size level)`,
      accessor: "dc_inv_threshold",
      Cell: (instance) => (
        <InputCell
          shouldOnBlur
          roundOffOnChange
          min={0}
          step={1}
          type="number"
          debouncedChangeHandler={(
            rowIndex,
            columnId,
            value,
            rowId,
            subRow,
            p_row
          ) => {
            setDisabled(false);
            setUpdatedId((old) => {
              return [...old, instance.row.original?.article];
            });
          }}
          {...instance}
        />
      ),
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide
          {...instance}
          minSearchTermValue={searchTermReq?.dc_inv_threshold?.value?.min >= 0 ? searchTermReq?.dc_inv_threshold?.value?.min : 0 }
          maxSearchTermValue={searchTermReq?.dc_inv_threshold?.value?.max}
          clickHandler={(min_stock, max_stock, id) =>
            fecthDataWithSearchTerm([min_stock, max_stock], id, "range")
          }
        />
      ),
      filter: "between",
      disableSortBy: true,
      minWidth: 200,
    },
    // {
    //   Header: "DC Intransit(wks)",
    //   accessor: "dc_it_week",
    //   Cell: (instance) => (
    //     <InputCell
    //       shouldOnBlur
    //       roundOffOnChange
    //       min={0}
    //       step={1}
    //       type="number"
    //       debouncedChangeHandler={(
    //         rowIndex,
    //         columnId,
    //         value,
    //         rowId,
    //         subRow,
    //         p_row
    //       ) => {
    //         setDisabled(false);
    //         setUpdatedId((old) => {
    //           return [...old, instance.row.original?.article];
    //         });
    //       }}
    //       from_date
    //       {...instance}
    //     />
    //   ),
    //   disableFilters: true,
    // },
    // {
    //   Header: "Recommm. Store-Store Transfer?",
    //   accessor: "recommend_store_store_transfer",
    //   disableFilters: true,
    // },
    {
      Header: "Store Transfer Status",
      accessor: "config_status",
      Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.config_status?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
      filter: "filterSingleSelectCell",
      Cell: (instance) => (
        <div>
          <SelectCellComponent
            defaultValue={instance.row.original?.config_status}
            options={instance.row.original?.options}
            changeHandler={(rowIdx, columnId, value) => {
              setDisabled(false);
              setUpdatedId((old) => {
                return [...old, instance.row.original?.article];
              });
              setallocationStatus(value);
              let l_instance = instance.row.original;
              if (!l_instance.from_date && value === "Paused") {
                instance.updateMyData(
                  rowIdx,
                  "from_date",
                  moment(new Date()).format("YYYY-MM-DD")
                );
              }
              if (!l_instance.to_date && value === "Paused")
                instance.updateMyData(
                  rowIdx,
                  "to_date",
                  moment(new Date()).format("YYYY-MM-DD")
                );
              if (value === "Active" || value === "Deactivated") {
                instance.updateMyData(rowIdx, "from_date", "-");
                instance.updateMyData(rowIdx, "to_date", "-");
              }
            }}
            {...instance}
          />
        </div>
      ),
      disableSortBy: true,
      minWidth: 200,
    },
    {
      Header: "From Date",
      accessor: "from_date",
      disableFilters: true,
      Cell: (instance) =>
        instance.row.original.config_status === "Paused" ? (
          <DateInput
            {...instance}
            value={
              instance.row.original?.from_date == "-"
                ? moment(new Date()).format("YYYY-MM-DD")
                : instance.row.original.from_date
            }
            dateFormat={"MM-DD-YYYY"}
            changeHandler={(rowIdx, columnId, value) => {
              setUpdatedId((old) => {
                return [...old, instance.row.original?.article];
              });
              setDisabled(false);
              let l_date = getEndDate(instance.row.original.to_date, value);
              instance.updateMyData(rowIdx, "to_date", l_date);
            }}
          />
        ) : (
          "-"
        ),
      width: 170,
    },
    {
      Header: "Upto Date",
      accessor: "to_date",
      disableFilters: true,
      Cell: (instance) =>
        instance.row.original.config_status === "Paused" ? (
          <DateInput
            {...instance}
            min={
              instance.row.original?.from_date == "-"
                ? moment(new Date()).format("YYYY-MM-DD")
                : instance.row.original.from_date
            }
            value={
              instance.row.original?.to_date == "-"
                ? moment(new Date()).format("YYYY-MM-DD")
                : instance.row.original.to_date
            }
            changeHandler={(rowIdx, columnId, value) => {
              setDisabled(false);
              setUpdatedId((old) => {
                return [...old, instance.row.original?.article];
              });
            }}
            dateFormat={"MM-DD-YYYY"}
          />
        ) : (
          "-"
        ),
      width: 170,
    },
  ];
 
  const prepInput = React.useMemo(
    () => [
      {
        id: 1,
        isRequired: true,
        label: "Store Transfer Status",
        name: "status",
        className: "setAll__filter",
        onChange: onAllocationStatusChange,
        placeHolder: `Select Allocation Status`,
        options: ALLOCATION_REQUIRED_OPTIONS,
        isMultiSelect: false,
        hideSelectAll: true,
        // style:{styleFilter},
      },
      {
        id: 2,
        hide: allocationStatus !== "Paused",
        isRequired: true,
        label: "From",
        name: "from_date",
        className: "setAll__filter",
        input: true,
        // style:{styleFilter},
        Comp: (
          <DatePicker
            minOrmax={{ min: today }}
            defaultValue={fromDate}
            onChange={(val) => {
              setFromDate(moment(val).format("MM/DD/YYYY"));
              // If from-date is ahead of to-date reset the to-date to from-date
              if (
                new Date(moment(val).format("MM/DD/YYYY")).getTime() >
                new Date(uptoDate).getTime()
              ) {
                setUptoDate(moment(val).format("MM/DD/YYYY"));
              }
            }}
            dateFormat="MM/DD/YYYY"
          />
        ),
      },
      {
        id: 3,
        hide: allocationStatus !== "Paused",
        isRequired: true,
        label: "Upto Date",
        name: "upto_date",
        className: "setAll__filter",
        input: true,
        // style:{styleFilter},
        Comp: (
          <DatePicker
            minOrmax={{ min: moment(fromDate).format("YYYY-MM-DD") }}
            defaultValue={uptoDate}
            onChange={(val) => setUptoDate(moment(val).format("MM/DD/YYYY"))}
            dateFormat="MM/DD/YYYY"
          />
        ),
      },
      {
        id: 4,
        isRequired: true,
        label: "Transfer Strategy",
        name: "transfer_strategy",
        className: "setAll__filter",
        onChange: onTransferStrategyChange,
        placeHolder: `Select Transfer Strategy`,
        options: props.transfer_strategy,
        isMultiSelect: false,
        hideSelectAll: true,
        // style:{styleFilter},
      },
      {
        id: 5,
        isRequired: true,
        label: "DC Inv Threshold",
        name: "dc_inv_threshold",
        className: "setAll__input",
        value: dcInventory,
        // style:{styleFilter},
        onChange: (e) => {
          let val = e.target.value;
          if (val < 0) {
            val = 0;
          }
          onDcInventoryChange(val);
        },
        input: true,
        type: "number",
        min: 0,
      },
      // {
      //   id: 6,
      //   isRequired: true,
      //   label: "DC in Transit",
      //   name: "dc_intransit",
      //   className: "setAll__input",
      //   value: dcInTransit,
      //   // style:{styleFilter},
      //   onChange: (e) => {
      //     let val = e.target.value;
      //     if (val < 0) {
      //       val = 0;
      //     }
      //     onDcInTransitChange(val);
      //   },
      //   input: true,
      //   type: "number",
      //   min: 0,
      // },
    ],
    [allocationStatus, fromDate, uptoDate,dcInventory,transferStrategy,transfer_strategy]
  );

  const setAllHandler = () => {
    if ((articleTableInstanse?.selectedFlatRows).length > 0) {
      
      setallocationStatus(null);
      setTransferStrategy(null);
      setDcInventory(null);
      setDCInTransit(null);
      setFromDate(null);
      setUptoDate(null);
      setShowSetAll(true);
    } else {
      Notify.error(
        `Please select atleast 1 article to use Set All functionality!!`
      );
    }
  };

  const isValid = value => value !== null && value !== undefined && value !== '';

  const handleSetAllOk = () => {
    let l_lastValueIndex = (checkAll?.length - 1 === -1) ? 0 : checkAll?.length - 1;
    
    const update_data = Object.entries({
      transfer_strategy: transferStrategy,
      dc_inv_threshold: dcInventory,
      status: allocationStatus,
      from_date: fromDate,
      to_date: uptoDate
    }).reduce((acc, [key, value]) => {
      if (isValid(value)) acc[key] = value;
      return acc;
    }, {});


    // let req = {
    //   ...props.filters,
    //   auto_allocation_status: allocationStatus,
    //   article:
    //     checkAll.length > 0
    //       ? !checkAll[l_lastValueIndex]?.checkAll
    //         ? articleTableInstanse?.selectedFlatRows?.map(
    //             (row) => row?.original?.article
    //           )
    //         : []
    //       : articleTableInstanse?.selectedFlatRows?.map(
    //           (row) => row?.original?.article
    //         ),
    //   from_date: fromDate,
    //   to_date: uptoDate,
    // };
    let req ={}
    if(checkAll.length > 0 && !checkAll[l_lastValueIndex]?.checkAll){
      req = {
       update_data,
       "update_type":"set_all",
        articles: articleTableInstanse?.selectedFlatRows?.map(
          (row) => row?.original?.article
        ),
      };
    }
    else if (checkAll.length > 0 && checkAll[l_lastValueIndex]?.checkAll){
      req = {
        update_data,
        "update_type":"bulk_edit",
       };
    }
    else {
      req = {
        update_data,
        "update_type":"set_all",
         articles: articleTableInstanse?.selectedFlatRows?.map(
           (row) => row?.original?.article
         ),
       };
    }


    // find out index of all the data that needs to be updated.
    let setAllRows = articleTableInstanse?.selectedFlatRows?.map(
      (row) => row?.id
    );
    setCheckAll([])
    // setInitialSelectedRows({})
    updateArticleData(setAllRows, allocationStatus, fromDate, uptoDate,dcInventory,transferStrategy, req);
}

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  };
  const handleClose = () => {
    showSetAll && setShowSetAll(false);
  };

  const updateSetAll = (value) => {
    // let l_newAction = {
    //     "searchTermReq": searchTermReq
    // }
    let l_newAction = {};
    if (!value.checked) {
      setPrevAction("checkAll");
      l_newAction["checkAll"] = true;
    } else {
      setPrevAction("unCheckAll");
      l_newAction["unCheckAll"] = true;
      let l_updatedCheckedRows = removeCheckedArticles(checkedRows, {});
      setCheckedRows(l_updatedCheckedRows);
    }
    setCheckAll((old) => [...old, l_newAction]);
  };
  const updateCheckedRows = (value) => {
   
    let l_newAction = {},
      l_article = value.instance.article;

    if (!value.checked) {
      //case when a row is selected
      setPrevAction("checkRow");
      if (prevAction === "checkRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy?.[l_lastValueIndex];
        l_checekdRows && l_checekdRows["checkedRows"]?.push(l_article);
        checkAllCopy.pop();
        l_checekdRows
          ? setCheckAll([...checkAllCopy, l_checekdRows])
          : setCheckAll([...checkAllCopy]);
      } else {
        l_newAction["checkedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = { [l_article]: value.instance };
      setCheckedRows((old) => {
        return {
          ...old,
          ...l_checkedRow,
        };
      });
    } else {
      //case when a row is unselected
      setPrevAction("unCheckRow");
      if (prevAction === "unCheckRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy[l_lastValueIndex];
        l_checekdRows["unCheckedRows"].push(l_article);
        checkAllCopy.pop();
        setCheckAll([...checkAllCopy, l_checekdRows]);
        // checkAll[l_lastValueIndex]?.checkAll
      } else {
        l_newAction["unCheckedRows"] = [l_article];
        // l_newAction["checkAll"] = false;
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = cloneDeep(checkedRows);
      delete l_checkedRow[l_article];
      setCheckedRows((old) => {
        return {
          ...l_checkedRow,
        };
      });
    }
  };
  return (
    <>
      {showSetAll && (
        <SetAllModal
          modalBodyStyle={{ height: "30rem"}}
          filter={prepInput}
          messageStyle={{ margin: "auto", color: "red" }}
          handleOk={handleSetAllOk}
          handleCancel={handleSetAllCancel}
          handleClose={() => handleClose()}
          disableApply={
            allocationStatus || transferStrategy || (dcInventory || dcInventory == 0) 
              ? false
              : true
          }
        />
      )}
      <section className="section mt-4 m-0">
        <PageLoader loader={props.baseTableloading} gridLoader={true}>
          {props.baseTableError ? (
            <>
              <div className="error">{ERROR_MSG}</div>{" "}
            </>
          ) : (
            <ReactTableWithPlugins
              columns={storeTransferConfigRuleColumns}
              data={props.data}
              totalRecordsLen={props.data?.length}
              renderMarkup="TableMarkup"
              keyRT="StrategyPageTable"
              getInstanceOnMount={(instance) => {
                setSelectedArticles(instance);
              }}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              tableId="store_transfer_config_rules_view"
              shouldPagination
              tableWrapperStyle={{ height: "fit-content", maxHeight: "50rem" }}
              headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
              checkAllCallBack={(val) => updateSetAll(val)}
              checkCallBack={(val) => updateCheckedRows(val)}
              getManualSortByOptions={(instance) => {
                fecthDataWithSorting(instance)
              }}
              fetchData={(data) => fetchData(data)}
              manualSortBy={true}
              totalCount={totalCount}
              initialPageIndex={initialPageIndex}
              hideDropdown
            />
          )}
          <div className="text-center mt-4">
            <button
              style={{ margin: "2px 1rem" }}
              className="btn btn-primary"
              onClick={setAllHandler}
            >
              Set All
            </button>
            <button
              className="btn btn-primary"
              disabled={disabled}
              onClick={() => updateChanges()}
            >
              Save Changes
            </button>
          </div>
          
        </PageLoader>
      </section>
    </>
  );
};

export default StoreTransferConfigTable;
