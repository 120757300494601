import { handleActions } from "redux-actions";
import stsStoreView from "./StoreView/Reducers/reducer";
import stsProductView from "./ProductView/Reducers/reducer";
import stsFinalize from "./Reducers/reducer"

const STSFinalizeReducer = {
  stsFinalize: handleActions({}, {}),
  ...stsStoreView,
  ...stsProductView,
  ...stsFinalize
};

export default STSFinalizeReducer;
