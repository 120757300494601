import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { initialState } from "./initialState";
import * as actionTypes from "../Actions/ActionTypes";

export default {
  storeTransferRules: handleActions(
    {
      [actionTypes.GET_STORE_TRANSFER_RULES]: (state, payload) => {
        return immutable(state, {
          rules: { $set: [] },
          rulesLoading: { $set: true },
          rulesError: { $set: null },
          rulesSuccess: { $set: false },
          deleteRuleLoading: { $set: false },
          deleteRuleError: { $set: null },
          deleteRuleSuccess: { $set: false }
        });
      },
      [actionTypes.GET_STORE_TRANSFER_RULES_SUCCESS]: (state, payload) => {
        return immutable(state, {
          rules: { $set: payload?.data },
          rulesLoading: { $set: false },
          rulesError: { $set: null },
          rulesSuccess: { $set: true },
        });
      },
      [actionTypes.GET_STORE_TRANSFER_RULES_ERROR]: (state, payload) => {
        return immutable(state, {
          rules: { $set: [] },
          rulesLoading: { $set: false },
          rulesError: { $set: (payload && payload.error) || null },
          rulesSuccess: { $set: false },
        });
      },
      [actionTypes.DELETE_STORE_TRANSFER_RULE]: (state, payload) => {
        return immutable(state, {
          deleteRuleLoading: { $set: true },
          deleteRuleError: { $set: null },
          deleteRuleSuccess: { $set: false },
        });
      },
      [actionTypes.DELETE_STORE_TRANSFER_RULE_SUCCESS]: (state, payload) => {
        return immutable(state, {
          deleteRuleLoading: { $set: false },
          deleteRuleError: { $set: null },
          deleteRuleSuccess: { $set: true },
        });
      },
      [actionTypes.DELETE_STORE_TRANSFER_RULE_ERROR]: (state, payload) => {
        return immutable(state, {
          deleteRuleLoading: { $set: false },
          deleteRuleError: { $set: (payload && payload.error) || null },
          deleteRuleSuccess: { $set: false },
        });
      },
      [actionTypes.GET_RULE_MAPPING]: (state, payload) => {
        return immutable(state, {
          ruleMappingData: { $set: [] },
          ruleMappingLoading: { $set: true },
          ruleMappingError: { $set: null }
        });
      },
      [actionTypes.GET_RULE_MAPPING_SUCCESS]: (state, payload) => {
        return immutable(state, {
          ruleMappingData: { $set: payload?.data },
          ruleMappingLoading: { $set: false },
          ruleMappingError: { $set: null }
        });
      },
      [actionTypes.GET_RULE_MAPPING_ERROR]: (state, payload) => {
        return immutable(state, {
          ruleMappingData: { $set: [] },
          ruleMappingLoading: { $set: false },
          ruleMappingError: { $set: (payload && payload.error) || null }
        });
      },
    },
    initialState
  ),
};
