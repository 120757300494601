import { all } from "redux-saga/effects";
import { dailySummarySaga } from "./DailySummarySaga"
import { storeViewSaga } from "./StoreView/StoreViewSaga" 
import {dailySummaryS2SSaga} from "./DailySummaryS2S/DailySummaryS2SSaga"
import {storeViewSagaS2S} from "./DailySummaryS2S/StoreViewS2S/StoreViewSagaS2S"

/**
 * Root saga
 */
export function* dailySummarySagas() {
    yield all([
        dailySummarySaga(),
        storeViewSaga(),
        dailySummaryS2SSaga(),
        storeViewSagaS2S()
    ]);
}