export const BASE_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const SSO_ROUTE = "https://login.microsoftonline.com/logout.srf"
export const PREFIX_NAV = "/v1";
export const DEMO = PREFIX_NAV + "/demo";

export const STRATEGY_PAGE = PREFIX_NAV + "/strategy";
export const STORE_PAGE = PREFIX_NAV + "/store";
export const CONSTRAINTS_PAGE = PREFIX_NAV + "/constraints";
export const PROFILE_PAGE = PREFIX_NAV + "/product-profile";
export const CREATEEDIT_PROFILE_PAGE = PROFILE_PAGE + "/create";
export const EDIT_PROFILE_PAGE = PROFILE_PAGE + "/edit/:profileId";
export const DASHBOARD_PAGE = PREFIX_NAV + "/dashboard";
export const FINALIZE_PAGE = PREFIX_NAV + "/finalize"
export const VIEW_ALLOCATION_PAGE = PREFIX_NAV + "/view-allocations"

export const USER_MANAGEMENT_PAGE = PREFIX_NAV + "/user-management";
export const CREATE_USER_MANAGEMENT_PAGE = USER_MANAGEMENT_PAGE + "/create";
export const EDIT_USER_MANAGEMENT_PAGE = USER_MANAGEMENT_PAGE + "/edit/:userId";

export const ADA_VISUAL_PAGE = PREFIX_NAV + "/ada-visual";

export const PRODUCT_STORE_STATUS = PREFIX_NAV + "/configuration";
export const CREATE_CONFIGURATION = PREFIX_NAV + "/create-configuration"
export const ADD_STORE_GROUP = PREFIX_NAV + "/add-store-group"

export const REPORTING_PAGE = PREFIX_NAV + "/reporting"
export const CONTACT_US = "https://www.impactanalytics.co/contact-us/"
export const NEW_STORE_SETUP = PREFIX_NAV + "/new-store-setup"
export const NEW_STORE_RESERVED_ALLOCATIONS = PREFIX_NAV + "/new-store-reserved-allocations"
export const STRATEGY_CONFIGURAION_PATH = PREFIX_NAV + "/strategy-configuration";

export const STORE_TRANSFER_RULE_PAGE = PREFIX_NAV + "/store-transfer-rule"
export const CREATE_STORE_TRANSFER_RULE = STORE_TRANSFER_RULE_PAGE + "/create"
export const EDIT_STORE_TRANSFER_RULE = STORE_TRANSFER_RULE_PAGE + "/edit/:ruleId";
export const STORE_TO_STORE_FINALIZE = PREFIX_NAV + "/store-to-store/finalize";

export const SET_CONSTRAINTS = PREFIX_NAV + "/set-constraints"
export const SET_STRATEGY_CONFIGURATION = PREFIX_NAV + "/set-strategy-configuration"
export const PAGE_LOADING = PREFIX_NAV + "/page-loading";
export const INVALID_USER = PREFIX_NAV + "/invalid-user";

export const STORE2STORE_ALLOCATION = PREFIX_NAV + "/store-to-store";


export const RAISE_TICKET = "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/63618"
