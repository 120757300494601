export const initialState = {
  csvBeforeFinalizeLoading: false,
  csvBeforeFinalizeData: [],
  csvBeforeFinalizeHeader: [],
  csvBeforeFinalizeError: false,
  updateS2SFinalizeLoading: false,
  updateS2SFinalizeSuccess: false, 
  updateS2SFinalizeError: false,
  pastAllocationPoLoading:false,
  pastAllocationPoData:{},
  pastAllocationPoError:null,
  downloadCsvLoading: false,
  downloadCsvData: [],
  downloadCsvError: null,
  disableEditLoading: false,
  disableEditData: false,
  disableEditError: false,
  csvHeadersAfterFinalize:null,
  finalizeData:{},
  finalizeLoading:false,
  finalizeError:null,
  updateStoreTransferQtyLoading: false,
  updateStoreTransferQtySuccess: false, 
  updateStoreTransferQtyError: false,
};
