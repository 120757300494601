import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { VIEW_ALLOCATION_PAGE } from "../../constants/routeConstants";
import ViewAllocation from "./ViewAllocation";
import ViewAllocationS2S from "./S2SAllocation/ViewAllocationS2S";
import Finalize from "../Finalize/Finalize";
import STSFinalize from "../ProductStoreStatus/StoreToStoreConfig/StoreToStoreFinalize/STSFinalize";
import "./ViewAllocation.css";
import { resetAll } from "./ViewAllocationActions";
import { connect } from "react-redux";

const ViewAllocationParentContainer = (props) => {
  const active = new URLSearchParams(window.location.search).get("tab");
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("dc");
  const [detailedView, setDetailedView] = useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState({});
  const [isBackClicked, setIsBackclicked] = useState(false);

  const handleTabSelect = (key) => {
    history.push(`${VIEW_ALLOCATION_PAGE}?tab=${key}`);
    setActiveTab(key);
    props.resetAll();
  };

  const backFromDetailedView = (isDetailedView) => {
    // isNewStore && showDetailedView(false)
    setDetailedView(isDetailedView);
    setIsBackclicked(true);
  };

  useEffect(() => {
    active && setActiveTab(active);
  }, [active]);

  useEffect(() => {
    setDetailedView(false);
  }, []);

  return (
    <>
      {!detailedView && (
        <div className="view_allocation_container">
          <div className="container__header">
            <h1 className="fnt-lg fnt-bold">View Past Allocation</h1>
          </div>
          <div className="container__body">
            <div className="container__tabs">
              <Tabs
                defaultActiveKey="dc"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                <Tab eventKey="dc" title="DC Allocation">
                  {activeTab === "dc" && (
                    <ViewAllocation
                      setDetailedView={setDetailedView}
                      setSelectedAllocation={setSelectedAllocation}
                      isBackClicked={isBackClicked}
                      setIsBackclicked={setIsBackclicked}
                    />
                  )}
                </Tab>
                {
                  <Tab eventKey="s2s" title="Store to Store Allocation">
                    {activeTab === "s2s" && (
                      <ViewAllocationS2S
                        setDetailedView={setDetailedView}
                        setSelectedAllocation={setSelectedAllocation}
                        isBackClicked={isBackClicked}
                        setIsBackclicked={setIsBackclicked}
                      />
                    )}
                  </Tab>
                }
              </Tabs>
            </div>
          </div>
        </div>
      )}
      {detailedView &&
        activeTab === "dc" &&
        (console.log("Rendering Finalize component with:", {
          detailedView,
          selectedAllocation,
          // store,
        }),
        (
          <Finalize
            planDetails={selectedAllocation}
            goBack={backFromDetailedView}
            // store={store}
          />
        ))}
      {detailedView && activeTab === "s2s" && (
        <STSFinalize
          planDetails={selectedAllocation}
          goBack={backFromDetailedView}
          // store={store}
        />
      )}
    </>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAllocationParentContainer);
