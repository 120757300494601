import * as actionTypes from "../Actions/ActionTypes"
import { dailySummaryStoreInitState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    dailyStoreView: handleActions(
        {
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW]: (state) => {
                return immutable(state, {
                    storesData: { $set: [] },
                    storesDataLoading: { $set: true },
                    storesDataError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storesData: { $set: payload.data },
                    storesDataLoading: { $set: false },
                    storesDataError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR]: (state, payload) => {
                return immutable(state, {
                    storesData: { $set: [] },
                    storesDataLoading: { $set: false },
                    storesDataError: { $set: (payload && payload.error) || null },
                })
            },

            [actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS]: (state) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: true },
                    storeDetailsError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeDetails: { $set: payload.data },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: null }
                })
            },
            [actionTypes.GET_DAILY_SUMMARY_STORE_DETAILS_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: (payload && payload.error) || null },
                })
            },
            [actionTypes.RESET_DAILY_SUMMARY_STORE_DETAILS]:(state) => {
                return immutable(state, {
                    storeDetails: { $set: [] },
                    storeDetailsLoading: { $set: false },
                    storeDetailsError: { $set: null },
                    storesData: { $set: [] },
                    storesDataLoading: { $set: false },
                    storesDataError: {$set:null},
                })
            }
        },
        dailySummaryStoreInitState
    )
}