import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import {
  FETCH_FILTER_DATA,
  FETCH_FILTER_SUCCESS,
  FETCH_FILTER_ERROR,
  RESET_FILTERS_DATA,
} from "./StoretoStoreActions";

export const store2storeState = {
  error: null,
  level1Options: [],
  level2Options: [],
  level3Options: [],
  level4Options: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  filterlabels: {},
  loading: false,
};

const reducer = {
  storetostore: handleActions(
    {
      [FETCH_FILTER_DATA]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
        });
      },
      [FETCH_FILTER_ERROR]: (state, { payload }) => {
        return immutable(state, {
          // error: { $set: (payload && payload.error) || null },
        });
      },
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              level1Options: {
                $set:
                  payload && payload.data.level1Options
                    ? payload.data.level1Options
                    : state.level1Options,
              },
              level2Options: [],
              level3Options: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l10":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l11":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l2":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l4":
            return immutable(state, {
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l6":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l7":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });

            return immutable(state, {
              styleColorIdOptions: {
                $set:
                  payload && payload.data.styleColorIdOptions
                    ? payload.data.styleColorIdOptions
                    : state.styleColorIdOptions,
              },
            });
          case "l8":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
        }
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          level6Options: { $set: [] },
          level7Options: { $set: [] },
          // dcOptions: { $set: [] },
        });
      },
    },
    store2storeState
  ),
};

export default reducer;
