import { takeLatest, put, all, call } from "redux-saga/effects";
import { storeViewSaga } from "./StoreView/saga";
import { productViewSaga } from "./ProductView/saga";
import * as Api from "../../../../routes/api";
import * as actionTypes from "./Actions/ActionTypes";
import * as s2sActions from "./Actions/Actions";
import {cloneDeep,isEmpty} from "lodash";
import { ERROR_MSG } from "../../../../constants/globalConstant";

function* downloadCsvBeforeFinalizeWorker(action) {
  try {
    const response = yield call(
      Api.allocationPoCreationBeforeFinalize,
      action?.payload
    );
    let headers = [];
    let objKeys = Object.keys(response?.data?.data[0]);
    objKeys.forEach((key) => {
      headers.push({
        label: key
          .split("_")
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
          .join(" "),
        key,
      });
    });
    if (response?.data?.status) {
      yield put({
        type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE_SUCCESS,
        data: response?.data?.data,
        headers: headers,
      });
    } else {
      yield put({
        type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
        error: "Something Went Wrong",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE_ERROR,
      error: "Something Went Wrong",
    });
  }
}

function* downloadCsvBeforeFinalizeWatcher() {
  yield takeLatest(
    actionTypes.DOWNLOAD_CSV_BEFORE_FINALIZE,
    downloadCsvBeforeFinalizeWorker
  );
}

function* updateFinalizeS2SWorker(action) {
  try {
    const response = yield call(
      Api.updateS2SFinalize,
      action?.payload
    );
    if (response?.data?.status) {
      yield put({
        type: actionTypes.UPDATE_S2S_FINALIZE_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_S2S_FINALIZE_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_S2S_FINALIZE_ERROR,
    });
  }
}

function* getPastAllocationPOWorkerS2S(action) {
  try {
      let { payload } = action
      const response = yield call(Api.pastAllocationPoCreationS2S, [payload])
      console.log("🚀 ~ function*getPastAllocationPOWorkerS2S ~ response:", response)
      if (response.status) {
          yield put(s2sActions.finalizeAllocationSuccessS2S({ 'data': response.data }))
      }
      else {
          yield put(s2sActions.finalizeAllocationErrorS2S({ 'error': "Something Went Wrong" }));
      }
  }
  catch (error) {
      yield put(s2sActions.finalizeAllocationErrorS2S(error));
  }
}

function* downloadCsvWorker(action) {
  let objKeys = Object.keys(cloneDeep(action?.payload?.Output?.[0] || action?.payload?.PO[0]))
  // let nonReqKeys = ["allocation_code_new", "created_at", "expediteFlag", "instore_date", "po_number", "asn", "reallocation", "store_sap", "dc_code_sap", "dc_sap_id", "sap_id"]


   try {
       let headers = []
       objKeys.forEach((key) => {
        headers.push({
          label: key
            .split("_")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(" "),
          key,
        });
      });
      console.log("🚀 ~ function*downloadCsvWorker ~ headers:", headers)
      yield put({ type: actionTypes.DOWNLOAD_CSV_SUCCESS, data: headers });
   }
   catch (error) {
       yield put({ type: actionTypes.DOWNLOAD_CSV_ERROR, 'error':  ERROR_MSG });
   }
}

function* disableEditWorker(action) {
  try {
      let { payload } = action
      const response = yield call(Api.disableEditApiS2S, payload)
      if (response.status) {
          yield put(s2sActions.disableEditSuccess({ 'data': response.data }))
      }
      else {
          yield put(s2sActions.disableEditError({ 'error': response.message }));
      }
  }
  catch (error) {
      yield put(s2sActions.disableEditError(error));
  }
}

function* finalizeAllocation(action) {
  let req = [{allocationcode:action.payload.allocation_code}] 
  const response = yield call(Api.allocationPoCreationS2S, req, action.source)
  console.log("🚀 ~ function*finalizeAllocation ~ response:", response)
  try {
      if (response?.data?.status) {
          if(!isEmpty(response.data)) {
            // console.log("🚀 ~ function*finalizeAllocation ~ objKeys:", response?.data?.data?.Output[0])
            let objKeys = Object.keys(response?.data?.Output[0]);
            let headers = []
            objKeys.forEach((key) => {
             headers.push({
               label: key
                 .split("_")
                 .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                 .join(" "),
               key,
             });
           });
              console.log("success triggered");
              yield put(s2sActions.finalizeAllocationSuccess({ 'data': response.data ,'csvHeadersAfterFinalize':headers }))
          }
          else {
              console.log("failure triggered");
              yield put(s2sActions.finalizeAllocationError({ 'error': "Error in finalize data!!" }));
          }
      }
      else {
        console.log("failure triggered 2");
          yield put(s2sActions.finalizeAllocationError({ 'error': response?.data?.message || "Error in finalize!!" }));
      }
  }
  catch (error) {
      console.log("Error in finalize is being triggered: " ,error)
      yield put(s2sActions.finalizeAllocationError({ 'error': "Something Went Wrong" }));
  }
}

function* updateStoreTransferQtyWorker(action) {
  const response = yield call(Api.updateStoreQty, { "data": action.payload })
  // let response = POSample
  try {
      if (response.status) {
          yield put(s2sActions.updateStoreTransferQuantitySuccess({ 'data': response.data }))
      }
      else {
          yield put(s2sActions.updateStoreTransferQuantityError({ 'error': "Something Went Wrong" }));
      }
  }
  catch (error) {
      yield put(s2sActions.updateStoreTransferQuantityError(error));
  }
}

function* downloadCsvWatcher() {
  yield takeLatest(actionTypes.DOWNLOAD_CSV, downloadCsvWorker)
}

function* updateFinalizeS2SWatcher() {
  yield takeLatest(
    actionTypes.UPDATE_S2S_FINALIZE,
    updateFinalizeS2SWorker
  );
}
function* getPastAllocationPOWatcher() {
  yield takeLatest(actionTypes.GET_PAST_ALLOCATION_PO_S2S, getPastAllocationPOWorkerS2S)
}

function* disableEditWatcher() {
  yield takeLatest(actionTypes.DISABLE_EDIT, disableEditWorker)
}

function* finalizeAllocationwatcher() {
  yield takeLatest(actionTypes.FINALIZE_ALLOCATION, finalizeAllocation)
}

function* updateStoreTransferQtyWatcher() {
  yield takeLatest(actionTypes.UPDATE_STORE_TRANSFER_QTY, updateStoreTransferQtyWorker)
}
export function* stsFinalizeSaga() {
  yield all([downloadCsvBeforeFinalizeWatcher(),
  updateFinalizeS2SWatcher(),
  getPastAllocationPOWatcher(),
  downloadCsvWatcher(), disableEditWatcher(),
  finalizeAllocationwatcher(),
  updateStoreTransferQtyWatcher()]);
}

/**
 * Root saga
 */
export function* storeToStoreFinalizeSaga() {
  yield all([storeViewSaga(), productViewSaga(), stsFinalizeSaga()]);
}
