import React, { useEffect, useState } from "react";
import { cloneDeep, isNumber, isObject } from "lodash";
import { useTable, useColumnOrder, useAbsoluteLayout } from "react-table";
import StateReducers from "./Shared/StateReducers";
import BlockLayoutMarkup from "./Markup/BlockLayoutMarkup";
import TableMarkup from "./Markup/TableMarkup";
import GlobalFilterMarkup from "./Markup/GlobalFilterMarkup";
import FooterMarkup from "./Markup/FooterMarkup";
import PaginationMarkup from "./Markup/PaginationMarkup";
import { getHideColumns } from "../../utils/commonUtilities";
import EmbededPaginationMarkup from "./Markup/EmbededPaginationMarkup";

let getMatch = (p_row,p_filterValue) => {
  return p_row?.some(val => p_filterValue.includes(val.value))
}

const ReactTable = React.memo((props) => {

  // to hide columns in the table for current client

  const {
    columns,
    data,
    tablePluginsArgs,
    features,
    keyRT,
    getInstanceOnMount,
    getManualSortByOptions,
    getRowId,
    checkAllCallBack,
    checkCallBack,
    renderMarkup,
    groupBy,
    // initialHiddenColumns: hideColumns,
    initialSelectedRows,
    hide,
    initialGlobalFilter,
    initialSelectedFilter,
    initialFilterOptions,
    shouldPagination,
    embededScroll,
    hideDropdown,
    containSubRow,
    prepareRequest,
    setIsDisabled,
    totalRecordsLen,
    sortBy,
    expanded,
    pageSize,
    initialPageIndex,
    manualSortBy,
    tableId,
    hideOptions,
    showCheckboxAllRows
  } = props;

  let hideColumns = getHideColumns(props.initialHiddenColumns, tableId,  props.hideColumnsFilter)

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );
  const [tabledata, setTableData] = React.useState([]);
  const skipPageResetRef = React.useRef();
  const [obje, setObject] = useState([]);

  const func = (arr, columnId, rowId, value) => {
    return arr.map((val, index) => {
      if (index == rowId) {
        return {
          ...val,
          [columnId]: value,
        };
      }
      return val;
    });
  };

  useEffect(() => {
  }, [obje]);

  const prepReq = (p_row, p_val, p_columnId) => {
    if (
      (!isNaN(parseFloat(p_val)) && isFinite(p_val)) ||
      (typeof p_val == "string" && p_val.length == 0) ||
      p_columnId === "ref_store"
    ) {
      let row = p_row && p_row?.original;
      let uniqueKey = `${row.store_code}:${row.store_name}:${row.store_group_code}:${row.title}:${row.store_grade}:${row.style_id}:${row.l1_name}:${row.l2_name}:${row.l3_name}:${row.color_desc}:${row.size_desc}`;
      let eachReq = {
        uniqueKey: uniqueKey,
        ...row,
      };
      eachReq[p_columnId] = p_val?.length ? p_val : 0;
      let filteredobje = [];
      if (obje.length) {
        filteredobje = obje.filter((val) => val.uniqueKey != eachReq.uniqueKey);
      }
      setObject((oldObject) => {
        return [...filteredobje, eachReq];
      });
    }
  };

  const subRowColumnDataChangeHandler = (
    rowIndex,
    columnId,
    value,
    rowId,
    subRow,
    p_row
  ) => {
    skipPageResetRef.current = true;
    let subRowIndex = ~~rowId;
    if (prepareRequest) {
      prepReq(p_row, value, columnId);
      if (setIsDisabled) {
        setIsDisabled((prev) => {
          if (prev) {
            return false;
          }
        });
      }
    }
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex && p_row?.subRows?.length) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        if (index == subRowIndex && !p_row?.subRows?.length) {
          let l_rowId = (rowId + "").split(".")[1];
          if(l_rowId){
            return {
              ...row,
              ["subRows"]: func(row.subRows, columnId, l_rowId, value),
            };
          }
          else{
            return {
              ...row,
              [columnId]: value,
            };
          }
        }
        return row;
      })
    );
  };

  const columnDataChangeHandler = (
    rowIdx,
    columnId,
    value,
    rowId,
    subRow,
    p_row
  ) => {
    skipPageResetRef.current = true;
    if (prepareRequest) {
      prepReq(p_row, value, columnId);
      if (setIsDisabled) {
        setIsDisabled((prev) => {
          if (prev) {
            return false;
          }
        });
      }
      if (columnId != "ref_store") {
        setTableData((oldData) => {
          let newData = cloneDeep(oldData);
          newData[rowIdx][columnId] = value;
          return newData;
        });
      } else {
        setTableData((oldData) => {
          let newData = cloneDeep(oldData);
          newData[rowIdx]['referStore'] = value;
          return newData;
        });
      }
    }
    else{
      setTableData((oldData) => {
        let newData = cloneDeep(oldData);
        newData[rowIdx][columnId] = value;
        return newData;
      });
    }

  };
  // const subRowColumnDataChangeHandler = (rowId, rowIdx, columnId, value) => {
  //   setTableData((oldData) => {
  //     oldData.map((row) => {
  //       if (rowId === row.uniqueId) {
  //         return {
  //           ...row,
  //           [columnId]: value,
  //         };
  //       }
  //       if (
  //         row.subRows &&
  //         row.subRows[rowIdx] &&
  //         rowId === row.subRows[rowIdx].uniqueId
  //       ) {
  //         let newRowData = cloneDeep(row);
  //         row.subRows[rowIdx][columnId] = value;
  //         return newRowData;
  //       }
  //       return row;
  //     });
  //   });
  // };
  /**
   *  callbacksToTable are callbacks sent to table via options,
   *  where our custom hooks uses it to send back some data
   *  for example: `getInstanceOnMount` is cb that will be called with `instance as argument`
   */
  const callbacksToTable = {
    // updateMyData: updateMyData,
    updateMyData: containSubRow
      ? subRowColumnDataChangeHandler
      : columnDataChangeHandler,
  };
  if (getInstanceOnMount) {
    callbacksToTable.getInstanceOnMount = getInstanceOnMount;
    callbacksToTable.settabledata = setTableData;
    callbacksToTable.obje = obje;
    callbacksToTable.setObject = setObject;
    callbacksToTable.getManualSortByOptions = getManualSortByOptions;
  }
  if (getRowId) {
    callbacksToTable.getRowId = getRowId;
  }
  if (checkAllCallBack) {
    callbacksToTable.checkAllCallBack = checkAllCallBack;
  }
  if (checkCallBack) {
    callbacksToTable.checkCallBack = checkCallBack;
  }
  if(showCheckboxAllRows) {
    callbacksToTable.showCheckboxAllRows = showCheckboxAllRows;
  }
  React.useEffect(() => {
    setTableData(() => data);
  }, [data]);
  React.useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  });
  // table instance
  const filterTypes = React.useMemo(() => ({
    filterRowsWithSubrows: (rows, id, filterValue) => {
      return rows.filter(row => {
        const subRows = row.subRows || [];
        const rowMatches = String(row?.values?.[id]).toLowerCase().includes(filterValue.toLowerCase());
        const subRowMatches = subRows.some(subRow => String(subRow?.values?.[id]).toLowerCase().includes(filterValue.toLowerCase()));
        return rowMatches || subRowMatches;
      });
      },

      filterNumberWithSubRows: (rows, id, filterValue) => {
        const [min, max] = filterValue;
        if (!min && !max) return rows;
        const isWithinRange = (value) => {
          if (min !== undefined && value < min) return false;
          if (max !== undefined && value > max) return false;
          return true;
        };

        return rows.filter((row) => {
          if (!row.subRows?.length) {
            return []; // No subrows, so filter out this row
          }
        
          // Filter subrows in place
          row.subRows = row.subRows.filter((subrow) => {
            const numberMatch =
              (!min || subrow.values[id] >= min) &&
              (!max || subrow.values[id] <= max);
            return numberMatch;
          });
        
          // Return true if there are any matching subrows
          return row.subRows.length > 0;
        });


        // const filterRows = (rows) => {
        //   return rows.filter(row => {
        //     const subRows = row.subRows || [];
        //     const filteredSubRows = filterSubRows(subRows);
        //     if (filteredSubRows.length > 0) {
        //       row.subRows = filteredSubRows;
        //       return true; // Retain the parent row if any subRows match
        //     }
        //     return false;
        //   });
        // };
      },      
      // filterRowsWithSubrows: (rows, id, filterValue) => {
      //   let newArray =  rows?.filter(row => {
      //     const rowValue = String(row?.values?.[id]).toLowerCase()
      //     return rowValue !== undefined
      //       ? rowValue.match(String(filterValue).toLowerCase())
      //       : true
      //   })

      //   let newArraySub =  rows.map ( row1 => row1.subRows.filter(row => {
      //     const rowValue = String(row?.values?.[id]).toLowerCase()
      //     return rowValue !== undefined
      //       ?rowValue.match(String(filterValue).toLowerCase())
      //       : true
      //   }))
      //   newArraySub = newArraySub.flat()
      //   let finalAray = [...newArray,...newArraySub]

      // // let changedarray = []
      //  let changedarray =  finalAray.map(ele => {
      //       return {
      //         ...ele,
      //         // 'subRows' : ele['originalSubRows'],
      //         // canExpand: true
      //         // ele['canExpand'] = true
      //       }
      //   })
      //   return changedarray
      // },
      filterSingleSelectCell: (rows, id, filterValue) => {
        let newArray =  rows?.filter(row => {
          let rowValue = isObject(row?.values?.[id]?.[0]) ? String(row?.values?.[id]?.[0]?.label).toLowerCase() : String(row?.values?.[id]).toLowerCase()
          return rowValue !== undefined
            ?rowValue.match(String(filterValue).toLowerCase())
            : true
        })
        return newArray
      },
      filterMultiSelectCell: (rows, id, filterValue) => {
        let result = filterValue?.map(({ value }) => value)
        let newArray = []
        if(result.length)
           newArray = rows.filter(val => getMatch(val?.values?.[id], result))
        else
          newArray = rows
        return newArray;
      },
      bulkFilterSpaceSeperated: (rows, id, filterValue) => { 
        let newArray =  rows?.filter(row => {
          return filterValue.replace(/\s/g, ' ').split(" ").includes(row?.values?.[id])
        })
        return newArray
      },
      bulkFilterCommaSeperated: (rows, id, filterValue) => {
        let newArray =  rows?.filter(row => {
          return filterValue.replace(/\s*,\s*/g, ",").split(",").includes(row?.values?.[id])
        })
        return newArray
      }
      }),[])

  const instance = useTable(
    {
      containSubRow,
      prepareRequest,
      setIsDisabled,
      columns,
      data: tabledata,
      filterTypes,
      getSubRows: row => row.subRows,
      manualSortBy: manualSortBy || false ,
      defaultColumn,
      features,
      keyRT,
      getInstanceOnMount,
      customFilterOptions: initialFilterOptions || [],
      customSelectedFilter: initialSelectedFilter || [],
      initialState: {
        sortBy: sortBy || [],
        expanded: expanded || {},
        pageSize: pageSize || 10,
        groupBy: groupBy || [],
        globalFilter: initialGlobalFilter || [],
        setSelectedFilter: initialSelectedFilter || {},
        pageIndex: initialPageIndex || 0,
        selectedRowIds: initialSelectedRows || [],
        hiddenColumns: hide ? ["actionValue"] : hideColumns || [],
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetGlobalFilter: false,
      autoResetRowState: !skipPageResetRef.current,
      stateReducer: StateReducers,
      ...callbacksToTable,
    },
    useColumnOrder,
    ...tablePluginsArgs.plugins,
  );

  function ShouldShowPagination() {
    if(embededScroll){
      return <EmbededPaginationMarkup {...instance} {...props} ></EmbededPaginationMarkup>;
    }
    else if (!shouldPagination || totalRecordsLen === 0 || totalRecordsLen < 0) {
      return null;
    }
    return <PaginationMarkup {...instance} {...props}  hidebutton={hideDropdown ? "Yes" : "No"}></PaginationMarkup>;
  }
  if (renderMarkup === "TableMarkup") {
    return (
      <div style={props?.style ? props.style : {minWidth:'50rem', maxWidth:'fit-content', margin:'auto'}}>
        <TableMarkup {...instance} {...props} hideColumns={props.initialHiddenColumns || []} />
        {ShouldShowPagination()}
      </div>
    );
  }
  if (renderMarkup === "GlobalFilterMarkup") {
    return (
      <>
        <GlobalFilterMarkup {...instance} {...props}></GlobalFilterMarkup>
        {ShouldShowPagination()}
      </>
    );
  }
  if (renderMarkup === "FooterMarkup") {
    return <FooterMarkup {...instance} {...props} />;
  }
  return (
    <>
      <BlockLayoutMarkup {...instance} {...props} />
      {ShouldShowPagination()}
    </>
  );
});
export default ReactTable;
