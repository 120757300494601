// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
import React, { useCallback } from 'react'

function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },resetInitalPageIndex,
    ...props},) {

    const calculateMinMaxWithSubRows = useCallback(() => {
        let allValues = [];
        preFilteredRows?.forEach((row) => {
          !isNaN(row.values[id]) && allValues.push(row.values[id]);
          if (row.subRows) {
            row.subRows.forEach((subRow) => {
              !isNaN(subRow.values[id]) && allValues.push(subRow.values[id]);
            });
          }
        });
        return allValues?.length ? [Math.min(...allValues), Math.max(...allValues)] : [0, 0];
    }, [id, preFilteredRows]);

    const calculateMinMax =  useCallback(() => {
      let min = preFilteredRows.length
          ? isNaN(preFilteredRows[0].values[id])
            ? 0
            : preFilteredRows[0].values[id]
          : 0;
        let max = preFilteredRows.length
          ? isNaN(preFilteredRows[0].values[id])
            ? 0
            : preFilteredRows[0].values[id]
          : 0;
        preFilteredRows.forEach((row) => {
          min = Math.min(isNaN(row.values[id]) ? 0 : row.values[id], min);
          max = Math.max(isNaN(row.values[id]) ? 0 : row.values[id], max);
        });
        return [min, max];
      }, [id, preFilteredRows]);

    const [min, max] = props?.containSubRows ? calculateMinMaxWithSubRows() : calculateMinMax()
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] >= 0 ? filterValue[0] : ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
            val && resetInitalPageIndex && resetInitalPageIndex(0)
          }}
          placeholder={`Min(${min})`}
          style={{width:"80%", margin:"4px 0 4px 4px", borderRadius:"2rem 0 0 2rem", paddingLeft:'2px', outline:'none', borderColor:'#343a40'}}
        />
        {/* <span style={{margin:"auto", fontSize:"1.3rem"}}>to</span> */}
        <input
          value={filterValue[1] >= 0 ? filterValue[1] : ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
            e.target.value && resetInitalPageIndex && resetInitalPageIndex(0)
          }}
          placeholder={`Max(${max})`}
          style={{width:"80%", margin:"4px 4px 4px 0", borderRadius:"0 2rem 2rem 0", paddingLeft:'2px', outline:'none', borderColor:'#343a40'}}
        />
      </div>
    )
  }

  export default NumberRangeColumnFilter
  