import * as actionTypes from "./ActionTypes"


export const getFilters = (payload) => ({
    type: actionTypes.FETCH_FILTER_DATA,
    payload
})

export const getStores = (payload) => ({
    type: actionTypes.GET_STORES,
    payload
})

export const storeValues = (payload) => ({
    type: actionTypes.STORE_VALUES,
    payload
})

export const getStoreMapping = (payload) => ({
    type: actionTypes.GET_STORE_MAPPING,
    payload
})

export const resetStep1 = () => ({
    type: actionTypes.RESET_STEP_1
})

export const resetStep2 = () => ({
    type: actionTypes.RESET_STEP_2
})

export const resetAll = () => ({
    type: actionTypes.RESET_ALL
})

export const createStoreTransferRule = (payload) => ({
    type: actionTypes.CREATE_STORE_TRANSFER_RULE,
    payload
})

export const validateRuleName = (payload) => ({
    type: actionTypes.VALIDATE_RULE_NAME,
    payload
})