export const sortDateColumn = (date1, date2, isDesc) => {
  if (
    new Date(date1) > new Date(date2)
  ) {
    return isDesc ? 1 : -1;
  }
  if (
    new Date(date2) > new Date(date1)
  ) {
    return isDesc ? -1 : 1;
  }
};
