export const ALLOCATION_REQUIRED_OPTIONS = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Deactivated",
    value: "Deactivated",
  },
  {
    label: "Paused",
    value: "Paused",
  },
];

export const TRANSFER_STRATEGY_OPTIONS = [
  {
    label: "Min + Demand",
    value: "min and demand",
  },
  {
    label: "Demand only",
    value: "demand",
  },
  {
    label: "Min only",
    value: "min",
  },
];
