import { cloneDeep } from "lodash";

const createOptions = (list) => {
  return list.map((item) => ({
    label: item,
    value: item,
  }));
};
const createOptionsfromObject = (object) => {
  return Object.keys(object).map((item) => ({
    label: object[item],
    value: Number(item),
  }));
}

const forecastOptions = [
  // {label:"Fixed",value:"Fixed"},
  {label:"IA Recommended",value:"IA"},
]

export const formatStoreTransferReviewData = (data,transfer_strategy_options) => {
  let newData = cloneDeep(data);
  newData.forEach((ele) => {
    ele.size_options = createOptions(ele.size_list);
    ele.store_options = createOptions(ele.source_destination);
    ele.rule_options = createOptionsfromObject(ele.rules);
    ele.forecastOptionsList = forecastOptions;
    ele.transfer_strategy_options = transfer_strategy_options;
    
    ele.size_value = ele.size_options;
    ele.store_value = ele.store_options;
    ele.demand_type = forecastOptions[0].value;
    ele.fixed_units = " ";
    ele.fixed_percentage = " ";
  });
  
  return newData;
};
