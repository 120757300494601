import React, { useRef, useState, useEffect } from "react";
import { skuStoreLevelDetailsData } from "../DailySummaryConstants.js";
import ColumnFilter from "../../../../UserManagement/ColumnFilter.jsx";
import StoreViewDetailsS2S from "./StoreViewDetailsS2S.jsx";
import ReactTable from "../../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../../components/Table/WithPlugins.js";
import { connect } from "react-redux";
import PageLoader from "../../../../../components/Loader/PageLoader.jsx";
import { getDailySummaryStoreViewS2S } from "./Actions/StoreViewS2SActions";
import "../../DailySummary.css";
import Card from "../../../../../components/Card/Card.jsx";
import { ERROR_MSG } from "../../../../../constants/globalConstant.js";
import NumberRangeColumnFilter from "../../../../../components/Filters/NumberRangeColumnFilter.jsx";
import { isEmpty } from "lodash";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function StoreViewS2S(props) {
  const [clickedArticle, setClickedArticle] = useState({});
  const [selectedDestStore, setSelectedDestStore] = useState(false);

  const tableRef = useRef();

  useEffect(() => {
    props.getDailySummaryStoresS2S({ request: props.selectedFilters });
  }, []);
  useEffect(() => {
    return () => setClickedArticle({});
  }, [props.activeTab, props.subActiveTab]);
  const storeViewColumnS2S = [
    {
      Header: "Dest Store",
      accessor: "destination_store",
      Filter: ColumnFilter,
      Cell: (instance) => (
        <div
          className="clickable"
          onClick={() => {
            setClickedArticle(instance.row?.original?.destination_store);
            setSelectedDestStore(true);
          }}
        >
          {instance.row?.original?.destination_store}
        </div>
      ),
      width: 200,
    },
    {
      Header: "Dest Store Name",
      accessor: "destination_store_name",
      Filter: ColumnFilter,
      width: 250,
    },
    {
      Header: "Unit Transferred",
      columns: [
        {
          Header: "Transfer Units",
          accessor: "transfer_units",
          Filter: NumberRangeColumnFilter,
          filter: "between",
          width: 200,
        },
      ],
    },
    {
      Header: "Pre Transfer Status",
      columns: [
        {
          Header: "Source Inventory",
          accessor: "source_inventory",
          Filter: NumberRangeColumnFilter,
          filter: "between",
          width: 200,
        },
      ],
    },
  ];
  const validateData = (data) => Array.isArray(data) && data.length > 0;

  return (
    <>
      <PageLoader loader={props.storesLoading} gridLoader={true}>
        {props.storesError ? (
          <div className="error">{props.storesError || ERROR_MSG}</div>
        ) : (
          <>
            <div className="card__wrapper">
              {props?.cardData?.map((val, ind) => (
                <Card key={ind} title={val.title} value={val.value} />
              ))}
            </div>
          </>
        )}

        <div style={{ paddingTop: "3rem" }} ref={tableRef}>
          {validateData(props.storesData) && (
            <ReactTableWithPlugins
              columns={storeViewColumnS2S}
              data={props.storesData ? props.storesData : []}
              renderMarkup="TableMarkup"
              keyRT="sortAndSearch"
              shouldPagination
              totalRecordsLen={props?.data?.length}
              tableId="skuStoreDailySummaryS2S"
              tableWrapperStyle={{ height: "fit-content", maxHeight: "50rem" }}
              headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
            />
          )}
        </div>
      </PageLoader>
      {!isEmpty(clickedArticle) ? (
        <>
          <div className="storesStore__heading mt-4">
            <h3 className="fnt-md fnt-bold ml-4">
              Dest Store - {clickedArticle}
            </h3>
            <span></span>
          </div>
          <hr />
          <StoreViewDetailsS2S
            req={{ ...props.selectedFilters }}
            selectedDestStore={selectedDestStore}
            setSelectedDestStore={setSelectedDestStore}
            clickedArticle={clickedArticle}
          />
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = ({ dailyStoreViewS2S }) => ({
  storesData: dailyStoreViewS2S.storesData?.tableData,
  cardData: dailyStoreViewS2S.storesData?.cardData,
  storesLoading: dailyStoreViewS2S.storesDataLoading,
  storesError: dailyStoreViewS2S.storesDataError,
});

const mapDispatchToProps = (dispatch) => ({
  getDailySummaryStoresS2S: (payload) =>
    dispatch(getDailySummaryStoreViewS2S(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StoreViewS2S);
