import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStoreViewSummary, getStoreProductView } from "../Actions/Actions";
import StoreViewTable from "./StoreViewTable";
import StoreProductViewTable from "./StoreProductViewTable";

const StoreView = (props) => {

  const [selectedStore, setStore] = useState();
  const [selectedStoreCode, setStoreCode] = useState();

  const {
    getStoreViewSummary,
    getStoreProductView,
    activeTab,
    storeProductData,
    storeProductLoading,
    storeProductError,
    allocationCode,
    originalAllocationCode,
    viewAllocationCallS2S,
    showFinalizeButton
  } = props;

  useEffect(() => {
    activeTab === "store-view" && allocationCode &&
      getStoreViewSummary({
        allocationcode: allocationCode
      });
  }, [activeTab, allocationCode]);

  const getProducts = (storeCode, storeName) => {
    setStore(`${storeCode} ( ${storeName} )`);
    getStoreProductView({
      allocationcode: allocationCode,
      destination_store: storeCode,
    });
  };

  const callAllProductApis = () => {
    getStoreProductView({
      allocationcode: allocationCode,
      destination_store: selectedStoreCode,
    });
    getStoreViewSummary({
      allocationcode: allocationCode
    });
}

  return (
    <>
      <StoreViewTable {...props} getProducts={getProducts} setStoreCode={setStoreCode} viewAllocationCallS2S={viewAllocationCallS2S}/>
      {storeProductError ? (
        <div className="error">{storeProductError}</div>
      ) : storeProductData.length || storeProductLoading ? (
        <StoreProductViewTable
          data={storeProductData}
          loading={storeProductLoading}
          store={selectedStore}
          allocationCode={allocationCode}
          originalAllocationCode={originalAllocationCode}
          callAllApis={callAllProductApis}
          viewAllocationCallS2S={viewAllocationCallS2S}
          showFinalizeButton={showFinalizeButton}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ stsStoreView }) => ({
  data: stsStoreView.storeSummaryData,
  loading: stsStoreView.storeSummaryLoading,
  error: stsStoreView.storeSummaryError,
  success: stsStoreView.storeSummarySuccess,
  storeProductData: stsStoreView.storeProductData,
  storeProductLoading: stsStoreView.storeProductLoading,
  storeProductError: stsStoreView.storeProductError,
  storeProductSuccess: stsStoreView.storeProductSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getStoreViewSummary: (payload) => dispatch(getStoreViewSummary(payload)),
  getStoreProductView: (payload) => dispatch(getStoreProductView(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreView);
