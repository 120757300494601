import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import {
  FETCH_FILTER_SUCCESS,
  FETCH_TABLE_DATA,
  FETCH_TABLE_DATA_SUCCESS,
  FETCH_TABLE_DATA_ERROR,
  FETCH_SKU_TABLE_DATA,
  FETCH_SKU_TABLE_DATA_SUCCESS,
  FETCH_SKU_TABLE_DATA_ERROR,
  RESET_FILTERS_DATA
} from "./DailySummaryS2SActions";

export const dailySummaryS2SState = {
  tableData: [],
  success: false,
  error: false,
  loading: false,
  skuTableData: [],
  skuLoading: false,
  skuError: false,
  skuErrorMessage: "",
  departmentOptions: [],
  genderOptions: [],
  subCatOptions: [],
  dcsOptions: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  styleOptions: [],
  colorOptions: [],
  articleIdOptions: [],
  dcOptions: [],
  filterlabels: {},
  errorMessage: "",
  cardData: [],
  excelData: [],
  skuExcelData: [],
  dc: {},
  sourceStoreOptions: [],
  destinationStoreOptions: [],
};

export default {
  dailySummaryS2S: handleActions(
    {
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              departmentOptions: {
                $set:
                  payload && payload.data.departmentOptions
                    ? payload.data.departmentOptions
                    : state.departmentOptions,
              },
              genderOptions: [],
              subCatOptions: [],
              dcsOptions: [],
              level5Options: [],
              level6Options: [],
              level7Options: [],
              colorOptions: [],
              articleIdOptions: [],
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l2":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l3":
            return immutable(state, {
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l4":
            return immutable(state, {
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l10":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l11":
            return immutable(state, {
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l8":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l9":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l12":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l13":
            return immutable(state, {
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });

          case "l14":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l15":
            return immutable(state, {
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
            case "l16":
            return immutable(state, {
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l5":
            return immutable(state, {
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l6":
            return immutable(state, {
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l7":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              sourceStoreOptions: {
                $set:
                  payload && payload.data.sourceStoreOptions
                    ? payload.data.sourceStoreOptions
                    : state.sourceStoreOptions,
              },
              destinationStoreOptions: {
                $set:
                  payload && payload.data.destinationStoreOptions
                    ? payload.data.destinationStoreOptions
                    : state.destinationStoreOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
        }
      },
      [FETCH_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          success: { $set: false },
          error: { $set: false },
          loading: { $set: true },
          errorMessage: { $set: "" },
          excelData: { $set: [] },
        });
      },
      [FETCH_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: [] },
          loading: { $set: false },
          error: { $set: true },
          success: { $set: false },
          errorMessage: { $set: payload.error },
          cardData: { $set: [] },
          excelData: { $set: [] },
        });
      },
      [FETCH_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: (payload && payload.data) || [] },
          loading: { $set: false },
          error: { $set: false },
          success: { $set: true },
          errorMessage: { $set: "" },
          cardData: { $set: (payload && payload?.cardData) || [] },
          excelData: { $set: (payload && payload?.excelData) || [] },
        });
      },
      [FETCH_SKU_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          skuError: { $set: false },
          skuLoading: { $set: true },
          skuErrorMessage: { $set: "" },
          skuExcelData: { $set: [] },
        });
      },
      [FETCH_SKU_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          skuTableData: { $set: [] },
          skuLoading: { $set: false },
          skuError: { $set: true },
          skuErrorMessage: { $set: payload.error },
          skuExcelData: { $set: [] },
        });
      },
      [FETCH_SKU_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          skuTableData: { $set: (payload && payload.data) || [] },
          skuLoading: { $set: false },
          skuError: { $set: false },
          skuErrorMessage: { $set: "" },
          skuExcelData: { $set: (payload && payload?.excelData) || [] },
        });
      },
      [RESET_FILTERS_DATA]: (state) => {
        return immutable(state, {
          tableData: { $set: [] },
          genderOptions: { $set: [] },
          subCatOptions: { $set: [] },
          dcsOptions: { $set: [] },
          level5Options:{$set:[]},
          level6Options:{$set:[]},
          level7Options:{$set:[]},
          styleOptions: { $set: [] },
          colorOptions: { $set: [] },
          storeIdOptions: { $set: [] },
          articleIdOptions: { $set: [] },
          sourceStoreOptions:{$set:[]},
          destinationStoreOptions:{$set:[]},
          error: { $set: false },
          success: { $set: false },
          loading: { $set: false },
          errorMessage: { $set: '' },
          cardData: { $set: [] },
          excelData: { $set: [] },
          skuExcelData: { $set: [] },
          skuTableData: { $set: [] },
          skuLoading: { $set: false },
          skuError: { $set: false },
          skuErrorMessage: { $set: ''},
        });
      }
    },
    dailySummaryS2SState
  ),
};
