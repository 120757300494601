export const FETCH_FILTER_DATA = "@@STORETOSTORECONFIG/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@STORETOSTORECONFIG/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@STORETOSTORECONFIG/FETCH_FILTER_ERROR";
export const RESET_FILTERS_DATA = "@@STORETOSTORECONFIG/RESET_FILTERS_DATA";
export const FETCH_SAVED_DATA = "@@STORETOSTORECONFIG/FETCH_SAVED_DATA";
export const FETCH_USER_CREATED_DATA_STORE = "@@STORETOSTORECONFIG/FETCH_USER_CREATED_DATA";
export const FETCH_USER_CREATED_DATA_SUCCESS_STORE = "@@STORETOSTORECONFIG/FETCH_USER_CREATED_DATA_SUCCESS";
export const FETCH_USER_CREATED_DATA_ERROR_STORE = "@@STORETOSTORECONFIG/FETCH_USER_CREATED_DATA_ERROR";
export const UPDATE_SAVE_CONFIG_GRID_EDIT = "@@STORETOSTORECONFIG/UPDATE_SAVE_CONFIG_GRID_EDIT"
export const UPDATE_SAVE_CONFIG_GRID_SUCCESS = "@@STORETOSTORECONFIG/UPDATE_SAVE_CONFIG_GRID_SUCCESS"
export const UPDATE_SAVE_CONFIG_GRID_ERROR = "@@STORETOSTORECONFIG/UPDATE_SAVE_CONFIG_GRID_ERROR"
export const RESET_ALL = "@@STORETOSTORECONFIG/RESET_ALL"


export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});
export const fetchFilterData = (payload, filterKey, filterType) => ({
  type: FETCH_FILTER_DATA,
  payload,
  filterKey,
  filterType,
});

export const resetFiltersOptions = (payload) => ({
  type: RESET_FILTERS_DATA,
  payload,
});

export const fetchUserCreatedDataStoreToStore = (payload) => ({
  type: FETCH_USER_CREATED_DATA_STORE,
  payload,
});

export const fetchUserCreatedDataSuccessStoreToStore = (payload) => ({
  type: FETCH_USER_CREATED_DATA_SUCCESS_STORE,
  payload,
});

export const fetchUserCreatedDataErrorStoreToStore = (payload) => ({
  type: FETCH_USER_CREATED_DATA_ERROR_STORE,
  payload,
});

// export const fetchSavedData = (payload) => ({
//     type: FETCH_SAVED_DATA,
//     payload
//   })

export const updateAutoAllocationRequired = (payload) => ({
  type: UPDATE_SAVE_CONFIG_GRID_EDIT,
  payload
})

export const resetAll = () => ({
  type: RESET_ALL,
});

