import React, { useState } from "react";
import DailySummary from "./DailySummary";
import { Tab, Tabs } from "react-bootstrap";
import DailySummaryS2S from "./DailySummaryS2S/DailySummaryS2S";
import {DEFAULT_TAB_KEY} from "./DailySummaryConstants"

const DailySummaryParentContainer = (props) => {
  const { activeTab } = props;
  const [reportingActiveTab, setReportingActiveTab] = useState("reporting_dc");

  const handleTabChange = (key) => {
    setReportingActiveTab(key);
  };

  return (
    <>
      <Tabs
        defaultActiveKey={DEFAULT_TAB_KEY}
        activeKey={reportingActiveTab}
        onSelect={handleTabChange}
      >
        <Tab eventKey={"reporting_dc"} title="DC Allocations">
          <DailySummary activeTab={activeTab} subActiveTab={reportingActiveTab}/>
        </Tab>
        <Tab eventKey={"reporting_s2s"} title="Store to Store Allocation">
          <DailySummaryS2S activeTab={activeTab} subActiveTab={reportingActiveTab}/>
        </Tab>
      </Tabs>
    </>
  );
};

export default DailySummaryParentContainer;
