import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { cloneDeep } from "lodash";

import {
  FETCH_FILTER_SUCCESS,
  RESET_FILTERS_DATA,
  FETCH_USER_CREATED_DATA_ERROR_STORE,
  FETCH_USER_CREATED_DATA_SUCCESS_STORE,
  FETCH_USER_CREATED_DATA_STORE,
  UPDATE_SAVE_CONFIG_GRID_SUCCESS,
  UPDATE_SAVE_CONFIG_GRID_EDIT,
  UPDATE_SAVE_CONFIG_GRID_ERROR,
  RESET_ALL
} from "./StoreTransferConfigurationAction";

export const intialState = {
  storeColumn:[],
  profiledProductColumn:[],
  userCreatedData: [],
  storeGradeData: [],
  storeData: [],
  colourStyleData: [],
  userCreatedError: false,
  storeGradeError: false,
  storeError: false,
  popUpError: false,
  userCreatedloading: false,
  storeGradeloading: false,
  storeloading: false,
  level1Options: [],
  level2Options: [],
  level3Options: [],
  level4Options: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  filterlabels : {},
  styleOptions: [],
  colourOptions: [],
  createFilterData: [],
  createFilterError: false,
  createFilterloading: false,
  profiledProductData: [],
  originalProfiledProductData: [],
  profiledProductLoading: false,
  profiledProductError: false,
  productListContributionData: [],
  productListContributionLoading: false,
  productListContributionError: false,
  periodOptions: [{label:'last 30 days', value:'30'},{label:'last 90 days', value:'90'},{label:'last 180 days', value:'180'},{label:'last 365 days', value:'365'}],
  popUpData: [],
  popUpLoader: false,
  isUpdated: false,
  saveLoading: false,
  saveError: false,
  saveSuccess: null,
  savedDataLoading: false,
  savedDataError: false,
  savedData: [] ,
  deleteError: false,
  deleteSuccess: null, 
  error: false,
  countryOptions: [],
  nextIndex: 0,
  totalCount: 0,
  updateAutoAllocationLoading: false,
  updateAutoAllocationSuccess: false,
  updateAutoAllocationError: false,
  transfer_strategy: []
};

export default {
    storeTransfer: handleActions(
    {
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              level1Options: {
                $set:
                  payload && payload.data.level1Options
                    ? payload.data.level1Options
                    : state.level1Options,
              },
              level2Options: [],
              level3Options: [],
              countryOptions: {
                $set:
                  payload && payload.data.countryOptions
                    ? payload.data.countryOptions
                    : state.countryOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l2":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l10":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l11":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l4":
            return immutable(state, {
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l6":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l7":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l8":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l5":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
        }

        // return immutable(state, {
        //   level1Options: {
        //     $set:
        //       payload && payload.data.level1Options
        //         ? payload.data.level1Options
        //         : state.level1Options,
        //   },
        //   level2Options: {
        //     $set:
        //       payload && payload.data.level2Options
        //         ? payload.data.level2Options
        //         : state.level2Options,
        //   },
        //   level3Options: {
        //     $set:
        //       payload && payload.data.level3Options
        //         ? payload.data.level3Options
        //         : state.level3Options,
        //   },
        //   level4Options: {
        //     $set:
        //       payload && payload.data.level4Options
        //         ? payload.data.level4Options
        //         : state.level4Options,
        //   },
        //   styleOptions: {
        //     $set:
        //       payload && payload.data.styleOptions
        //         ? payload.data.styleOptions
        //         : state.styleOptions,
        //   },
        //   colourOptions: {
        //     $set:
        //       payload && payload.data.colourOptions
        //         ? payload.data.colourOptions
        //         : state.colourOptions,
        //   },
        //   filterlabels : {
        //     $set:
        //       payload && payload.data.filterMapping
        //         ? payload.data.filterMapping
        //         : state.filterMapping
        //   }

        //   // error: { $set: null },
        // });
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          storeData: { $set: [] },
          colourStyleData: { $set: [] },
          userCreatedData: { $set: [] },
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          styleOptions: { $set: [] },
          colourOptions: { $set: [] },
          saveSuccess: { $set: null },
          saveError: { $set: false },
          profiledProductData: { $set: [] },
          createFilterData: { $set: [] },
          productListContributionData: { $set: [] },
          profiledProductColumn: { $set: [] },
          savedData: { $set: [] },
          deleteSuccess: { $set: null },
          deleteError: { $set: false },
          // totalCount: { $set: 0 },
          // nextIndex: { $set: 0 },
        });
      },
      [RESET_ALL]: (state) => {
        return immutable(state, {
          userCreatedData:{$set:[]},
          userCreatedError:{$set:false},
          updateAutoAllocationSuccess:{$set:false},
          updateAutoAllocationError:{$set:false},
          userCreatedLoading:{$set:false},

          
            // dataLoading: { $set: false },
            // data: { $set: [] },
            // dataError: { $set: null },
            // articleData: { $set: [] },
            // articleDataLoading: { $set: false },
            // articleDataError: { $set: null },
            // storeGroupData: { $set: {} },
            // storeGroupDataLoading: { $set: false },
            // storeGroupDataError: { $set: null },
            // departmentOptions: { $set: [] },
            // genderOptions: { $set: [] },
            // styleOptions: { $set: [] },
            // colorOptions: { $set: [] },
            // sizeOptions: { $set: [] },
            // productProfilesData: { $set: {} },
            // productProfilesDataLoading: { $set: false },
            // productProfilesDataError: { $set: null },
            // createConfigData: { $set: {} },
            // createConfigDataSuccess: { $set: false },
            // createConfigDataLoading: { $set: false },
            // createConfigDataError: { $set: null },
            // deleteConfigDataLoading: { $set: false },
            // deleteConfigData: { $set: {} },
            // deleteConfigDataError: { $set: null },
            // selectedFilters: { $set: null },
            // productConfigTotalCount: { $set: 0 },
            // productConfigNextIndex: { $set: 0 },
            // checkAllData: { $set: [] },
            
        })
    },
      [FETCH_USER_CREATED_DATA_SUCCESS_STORE]: (state, {payload }) => {
        return immutable(state, {
        userCreatedData: { $set: (payload && payload.data) || [] },
        userCreatedloading: { $set : false },
        userCreatedError: { $set: false },
        totalCount: { $set: (payload && payload.totalCount) || 0 },
        nextIndex: { $set: (payload && payload.nextIndex) || 0 },
        transfer_strategy:{ $set: (payload && payload?.transfer_strategy_options) || []}
        });
      },
      [FETCH_USER_CREATED_DATA_ERROR_STORE]: (state, {payload }) => {
        return immutable(state, {
        userCreatedData: { $set: [] },
        userCreatedloading: { $set : false },
        userCreatedError: { $set: true }
        });
      },
      [FETCH_USER_CREATED_DATA_STORE]: (state, {payload }) => {
        return immutable(state, {
        userCreatedloading: { $set : true },
        userCreatedError: { $set: false },
        storeData: { $set: [] },
        colourStyleData: { $set: [] },
        ppNextIndex: { $set: 0 }
        });
      },
      [UPDATE_SAVE_CONFIG_GRID_EDIT]: (state, payload) => {
        return immutable(state, {
          updateAutoAllocationLoading: { $set: true },
          updateAutoAllocationSuccess:{ $set: false},
          updateAutoAllocationError: { $set: null },
          userCreatedloading: { $set : true },
          userCreatedError: { $set: false },
        })
    },
      [UPDATE_SAVE_CONFIG_GRID_SUCCESS]: (state, payload) => {
        return immutable(state, {
          updateAutoAllocationLoading: { $set: false },
          updateAutoAllocationSuccess: { $set: true},
          updateAutoAllocationError: { $set: null },
          userCreatedloading: { $set : false },
          userCreatedError: { $set: false },
        })
    },
    [UPDATE_SAVE_CONFIG_GRID_ERROR]: (state, payload) => {
        return immutable(state, {
          updateAutoAllocationLoading: { $set: false },
          updateAutoAllocationSuccess:{ $set: false},
          updateAutoAllocationError: { $set: (payload && payload.error) || null },
          userCreatedloading: { $set : false },
          userCreatedError: { $set: true }
        })
    }
    },
    intialState
  ),
};
