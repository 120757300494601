import React, { useRef, useCallback, useEffect, useState } from "react";
import DailySummaryFiltersS2S from "./DailySummaryFiltersS2S";
import PageLoader from "../../../../components/Loader/PageLoader";
import ToggleComponent from "../../../../components/Toggle/ToggleComponent";
import Card from "../../../../components/Card/Card";
import StyleColorTableS2S from "./StyleColorTableS2S";
import SKUStoreLevelDetailsS2S from "./SKUStoreLevelDetailsS2S";
import StoreViewS2S from "./StoreViewS2S/StoreViewS2S";
import { fetchDailySummaryData } from "./DailySummaryS2SActions";
import { scrollIntoView } from "../../../../utils/commonUtilities";
import ExportFile from "../../../../components/ExportFile/ExportFile";
import "../../Reporting.css";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { ERROR_MSG } from "../../../../constants/globalConstant";

function DailySummaryS2S(props) {
  const {activeTab, subActiveTab, cardData, excelData, loading, error, fetchDailySummaryData} = props;
  const [isStoreView, setIsStoreView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [clickedArticle, setClickedArticle] = useState({});
  const [req, setReq] = useState({});

  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData?.length) scrollIntoView(tableRef);
    if (props.tableData?.length > 0) {
      setTableData([...props.tableData]);
    } else {
      setTableData([]);
    }
  }, [props.tableData]);

  const handleFilterRequest = useCallback((req) => {
    setIsStoreView(false);
    setClickedArticle({});
    setReq(req);
    fetchDailySummaryData({ request: req });
    setTableData([]);
  }, []);

  const getSKUStoreDetails = useCallback((row) => {
    let l_req = {
      article: [{ value: row.article }],
      released_date: row.released_at,
      allocationcode: row.plan_name,
    };
    setClickedArticle(l_req);
  }, []);
  return (
    <>
      <DailySummaryFiltersS2S
        activeTab={activeTab}
        subActiveTab={subActiveTab}
        handleFilterRequest={(p_req) => handleFilterRequest(p_req)}
        setClickedArticle={() => setClickedArticle({})}
      />
      <div>
        <PageLoader loader={loading} gridLoader={true}>
          {props.error ? (
            <div className="error">{error || ERROR_MSG}</div>
          ) : (
            <>
              {!!tableData?.length && (
                <div className="daily-alloc__toggle row justify-content-end">
                  <ToggleComponent
                    secondaryToggleText={"Style Color View"}
                    primaryToggleText={"Store View"}
                    onToggle={() => {
                      setIsStoreView((old) => !old);
                    }}
                  />
                </div>
              )}
              {!isStoreView && (
                <div className="card__wrapper">
                  {cardData?.map((val, ind) => (
                    <Card key={ind} title={val.title} value={val.value} />
                  ))}
                </div>
              )}
              {tableData.length && !isStoreView ? (
                <div className="deepdive__export__wrapper">
                  <ExportFile
                    text={"Excel"}
                    data={excelData}
                    sheetName={"Daily Allocation Summary_S2S"}
                    fileName={`daily_summary_S2S_${new Date().getTime()}`}
                    type={1}
                    formateXLSX={true}
                    // excelColumnsToBeFormatted={
                    //   DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING
                    // }
                  />
                </div>
              ) : null}
              {isStoreView ? (
                <StoreViewS2S
                  selectedFilters={req}
                  activeTab={activeTab}
                  subActiveTab={subActiveTab}
                />
              ) : (
                <div style={{ paddingTop: "2rem" }} ref={tableRef}>
                  <StyleColorTableS2S
                    data={tableData}
                    tableId={"dailySummaryS2S-table"}
                    getDetails={getSKUStoreDetails}
                  />
                </div>
              )}
            </>
          )}
        </PageLoader>
        {!isStoreView &&
          (!isEmpty(clickedArticle) ? (
            <>
              <div className="storesStore__heading mt-4">
                <h3 className="fnt-md fnt-bold ml-4">
                  Style Color ID - {clickedArticle?.article[0]?.value}
                </h3>
                <span></span>
              </div>
              <hr />
              <SKUStoreLevelDetailsS2S
                req={{ ...req, ...clickedArticle }}
                subActiveTab={subActiveTab}
              />
            </>
          ) : null)}
      </div>
    </>
  );
}

const mapStateToProps = ({ dailySummaryS2S }) => ({
  error: dailySummaryS2S.error,
  loading: dailySummaryS2S.loading,
  cardData: dailySummaryS2S.cardData,
  tableData: dailySummaryS2S.tableData,
  excelData: dailySummaryS2S.excelData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDailySummaryData: (payload) => dispatch(fetchDailySummaryData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailySummaryS2S);
