export const GET_STORE_VIEW_SUMMARY = "@@storetransferrule/GET_STORE_VIEW_SUMMARY";
export const GET_STORE_VIEW_SUMMARY_SUCCESS = "@@storetransferrule/GET_STORE_VIEW_SUMMARY_SUCCESS";
export const GET_STORE_VIEW_SUMMARY_ERROR = "@@storetransferrule/GET_STORE_VIEW_SUMMARY_ERROR";

export const GET_STORE_PRODUCT_VIEW = "@@storetransferrule/GET_STORE_PRODUCT_VIEW";
export const GET_STORE_PRODUCT_VIEW_SUCCESS = "@@storetransferrule/GET_STORE_PRODUCT_VIEW_SUCCESS";
export const GET_STORE_PRODUCT_VIEW_ERROR = "@@storetransferrule/GET_STORE_PRODUCT_VIEW_ERROR";
export const UPDATE_TRANSFER_UNITS_FOR_STORES = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_STORES";

export const UPDATE_TRANSFER_UNITS_FOR_STORES_SUCCESS = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_STORES_SUCCESS";
export const UPDATE_TRANSFER_UNITS_FOR_STORES_ERROR = "@@storetransferrule/UPDATE_TRANSFER_UNITS_FOR_STORES_ERROR";
export const RESET_ALL = "@@storetransferrule/RESET_ALL";