export const GET_STORE_TRANSFER_RULES = "@@storetransferrule/GET_STORE_TRANSFER_RULES";
export const GET_STORE_TRANSFER_RULES_SUCCESS = "@@storetransferrule/GET_STORE_TRANSFER_RULES_SUCCESS";
export const GET_STORE_TRANSFER_RULES_ERROR = "@@storetransferrule/GET_STORE_TRANSFER_RULES_ERROR";

export const DELETE_STORE_TRANSFER_RULE = "@@storetransferrule/DELETE_STORE_TRANSFER_RULE";
export const DELETE_STORE_TRANSFER_RULE_SUCCESS = "@@storetransferrule/DELETE_STORE_TRANSFER_RULE_SUCCESS";
export const DELETE_STORE_TRANSFER_RULE_ERROR = "@@storetransferrule/DELETE_STORE_TRANSFER_RULE_ERROR";

export const GET_RULE_MAPPING = "@@storetransferrule/GET_RULE_MAPPING";
export const GET_RULE_MAPPING_SUCCESS = "@@storetransferrule/GET_RULE_MAPPING_SUCCESS";
export const GET_RULE_MAPPING_ERROR = "@@storetransferrule/GET_RULE_MAPPING_ERROR";
