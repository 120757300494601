import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import {
  isFutureDate,
  getUserPreferenceFilter,
} from "../../../../utils/commonUtilities";
import moment from "moment";
import Select from "../../../../components/Filters/SingleSelect";
import { connect } from "react-redux";
import * as Notify from "../../../../components/Notification/Notifications";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import AsyncMulti from "../../../../components/Filters/AsyncMulti";
import { resetStoreViewData } from "./StoreViewS2S/Actions/StoreViewS2SActions";
import { intersectionWith, isEqual } from "lodash";
import {
  isMandatory,
  getFilterForClients,
  shouldHideForCurrentClient,
} from "../../../../utils/commonUtilities";
import { fetchFilterData, resetFiltersOptions } from "./DailySummaryS2SActions";
function DailySummaryFiltersS2S(props) {
  const [request, setRequest] = useState(null);
  const [min_date, setStartDate] = useState(null);
  const [max_date, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState(null);
  const [department, setDepartment] = useState(null);
  const [gender, setGender] = useState(null);
  const [genderOptions, setGenderOptions] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [subCatOptions, setSubCatOptions] = useState(null);
  const [dcs, setDCS] = useState(null);
  const [dcsOptions, setDCSOptions] = useState(null);
  const [level5, setLevel5] = useState(null);
  const [level5Options, setLevel5Options] = useState(null);
  const [level6, setLevel6] = useState(null);
  const [level6Options, setLevel6Options] = useState(null);
  const [color, setColor] = useState(null);
  const [colorOptions, setColorOptions] = useState(null);
  const [article, setArticle] = useState(null);
  const [articleIdOptions, setArticleIdOptions] = useState(null);
  const [style, setStyle] = useState(null);
  const [styleOptions, setStyleOptions] = useState(null);
  const [sourceStore, setSourceStore] = useState(null);
  const [destinationStore, setDestinationStore] = useState(null);
  const [sourceStoreOptions, setSourceStoreOptions] = useState(null);
  const [destinationStoreOptions, setDestinationStoreOptions] = useState(null);

  const applyFilters = () => {
    if (department && gender?.length && min_date && max_date) {
      props.handleFilterRequest({
        l1_name: [department],
        l2_name: gender,
        l3_name: request?.level3,
        l4_name: request?.level4,
        l5_name: request?.level5,
        l6_name: request?.level6,
        style: request?.style,
        color: request?.color,
        article: request?.article,
        source_store: request?.source_store,
        destination_store: request?.destination_store,
        start_date: min_date ? moment(min_date).format("YYYY-MM-DD") : null,
        end_date: max_date ? moment(max_date).format("YYYY-MM-DD") : null,
      });
    } else {
      Notify.error("Please Select All Mandatory Options!!");
    }
  };
  const resetRestFilters = (p_index) => {
    //Newly added for s2s
    destinationStore && setDestinationStore(null);
    destinationStoreOptions && setDestinationStoreOptions(null);

    if (p_index === 0) {
      gender && setGender(null);
      genderOptions && setGenderOptions(null);
    }
    if (p_index === 0 || p_index === 1) {
      subCat && setSubCat(null);
      dcs && setDCS(null);
      level5 && setLevel5(null);
      color && setColor(null);
      article && setArticle(null);
      sourceStore && setSourceStore(null);
      subCatOptions && setSubCatOptions(null);
      dcsOptions && setDCSOptions(null);
      level5Options && setLevel5Options(null);
      colorOptions && setColorOptions(null);
      articleIdOptions && setArticleIdOptions(null);
      level6 && setLevel6(null);
      level6Options && setLevel6Options(null);
      sourceStoreOptions && setSourceStoreOptions(null);
      destinationStore && setDestinationStore(null);
      destinationStoreOptions && setDestinationStoreOptions(null);
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
    }
    if (p_index === 2) {
      dcs && setDCS(null);
      dcsOptions && setDCSOptions(null);
    }
    if (p_index === 5) {
      //
      // level7 && setLevel7(null);
      // level7Options && setLevel7Options(null);
      color && setColor(null);
      colorOptions && setColorOptions(null);
      article && setArticle(null);
      articleIdOptions && setArticleIdOptions(null);
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
    }
    if (p_index === 6) {
      color && setColor(null);
      colorOptions && setColorOptions(null);
      article && setArticle(null);
      articleIdOptions && setArticleIdOptions(null);
    }

    if (p_index === 2 || p_index === 3) {
      level5 && setLevel5(null);
      level5Options && setLevel5Options(null);
      color && setColor(null);
      article && setArticle(null);
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
      colorOptions && setColorOptions(null);
      articleIdOptions && setArticleIdOptions(null);
      level6 && setLevel6(null);
      level6Options && setLevel6Options(null);
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
    }
    if (p_index === 4) {
      level6 && setLevel6(null);
      level6Options && setLevel6Options(null);
      // level7 && setLevel7(null);
      // level7Options && setLevel7Options(null);
      color && setColor(null);
      colorOptions && setColorOptions(null);
      article && setArticle(null);
      articleIdOptions && setArticleIdOptions(null);
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
    }
    if (p_index === 7) {
      article && setArticle(null);
      articleIdOptions && setArticleIdOptions(null);
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
    }
    if (p_index === 8) {
      style && setStyle(null);
      styleOptions && setStyleOptions(null);
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
    }
    if (p_index === 9) {
      sourceStore && setSourceStore(null);
      sourceStoreOptions && setSourceStoreOptions(null);
      destinationStore && setDestinationStore(null);
      destinationStoreOptions && setDestinationStoreOptions(null);
    }
  };
  const resetFilters = () => {
    setRequest(null);
    setDepartment(null);
    setGender(null);
    setSubCat(null);
    setDCS(null);
    setLevel5(null);
    setLevel6(null);
    setStyle(null);
    setColor(null);
    setArticle(null);
    setSourceStore(null);
    setDestinationStore(null);
    props.resetFiltersOptions([]);
  };

  const onDateChange = (startDate, endDate) => {
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      props.fetchFilterData(
        {
          start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
          end_date: endDate && moment(endDate).format("YYYY-MM-DD"),
        },
        { key: "l1" }
      );
      department && setDepartment(null);
      departmentOptions && setDepartmentOptions(null);
      resetRestFilters(0);
    } else {
      // setDate("");
      console.log("startdate and enddate are empty");
    }
  };

  const onDepartmentChange = (val, p_userPreference) => {
    let today = new Date();
    setRequest((old) => {
      return {
        ...old,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,
        style: null,
        color: null,
        article: null,
        source_store: null,
        destination_store: null,
      };
    });
    setDepartment(val);
    resetRestFilters(0);
    if (p_userPreference?.user_preference) {
      props.fetchFilterData(
        {
          level1: [val],
          start_date: moment(min_date ? min_date : today).format("YYYY-MM-DD"),
          end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
        },
        { key: "l10" }
      );
    } else {
      props.fetchFilterData(
        {
          level1: [val],
          start_date: moment(min_date ? min_date : today).format("YYYY-MM-DD"),
          end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
        },
        { key: "l2" }
      );
    }
  };

  const onGenderChange = (val, event, that) => {
    let today = new Date();
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
      setGender(valuesArray[1]);
      setRequest((old) => {
        return {
          ...old,
          level2: valuesArray[0],
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          style: null,
          color: null,
          article: null,
          source_store: null,
          destination_store: null,
        };
      });
      if (event?.user_preference) {
        props.fetchFilterData(
          {
            level1: [event?.l1_name],
            start_date: moment(min_date ? min_date : today).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
            level2: val,
          },
          { key: "l3" }
        );
      } else {
        props.fetchFilterData(
          {
            level1: [department],
            start_date: moment(min_date ? min_date : today).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
            level2: req_val,
          },
          { key: "l3" }
        );
      }
    } else {
      setGender([val]);
      if (event?.user_preference) {
        props.fetchFilterData(
          {
            level1: [event?.l1_name],
            start_date: moment(min_date ? min_date : today).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
            level2: [val],
          },
          { key: "l3" }
        );
      } else {
        props.fetchFilterData(
          {
            level1: department,
            start_date: moment(min_date ? min_date : today).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(max_date ? max_date : today).format("YYYY-MM-DD"),
            level2: [val],
          },
          { key: "l3" }
        );
      }
      setRequest((old) => {
        return {
          ...old,
          level2: [val],
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          style: null,
          color: null,
          article: null,
          source_store: null,
          destination_store: null,
        };
      });
    }
    resetRestFilters(1);
  };

  const onSubCatChange = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
      setSubCat(valuesArray[1]);

      let req = {
        level1: [department],
        level3: [...req_val],
        start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
        end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
      };
      if (gender) {
        req["level2"] = [gender];
      }
      props.fetchFilterData(req, { key: "l4" });
      setRequest((old) => {
        return {
          ...old,
          level3: valuesArray[0],
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          style: null,
          color: null,
          article: null,
          source_store: null,
          destination_store: null,
        };
      });
    } else {
      setSubCat(val);
      if (event?.user_preference) {
        props.fetchFilterData(
          {
            level1: [department],
            level2: [event?.l2_name],
            level3: [val],
            start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
            end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
          },
          { key: "l4" }
        );
      } else {
        props.fetchFilterData(
          {
            level1: [department],
            level2: [gender],
            level3: [val],
            start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
            end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
          },
          { key: "l4" }
        );
      }
      setRequest((old) => {
        return {
          ...old,
          level3: val,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          style: null,
          color: null,
          article: null,
          source_store: null,
          destinations_storee: null,
        };
      });
    }
    resetRestFilters(2);
  };

  const onDCSChange = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
    setDCS(valuesArray[1]);
    let req = {
      level1: [department],
      level4: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    props.fetchFilterData(req, { key: "l8" });
    setRequest((old) => {
      return {
        ...old,
        level4: valuesArray[0],
        level5: null,
        level6: null,
        level7: null,
        style: null,
        color: null,
        article: null,
        source_store: null,
        destinations_store: null,
      };
    });
    resetRestFilters(3);
  };

  const onlevel5Change = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
    setLevel5(valuesArray[1]);
    let req = {
      level1: [department],
      level5: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    props.fetchFilterData(req, { key: "l9" });
    setRequest((old) => {
      return {
        ...old,
        level5: valuesArray[0],
        level6: null,
        style: null,
        color: null,
        article: null,
        source_store: null,
        destination_store: null,
      };
    });
    resetRestFilters(4);
  };

  const onlevel6Change = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
    setLevel6(valuesArray[1]);
    let req = {
      level1: [department],
      level6: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    level5 && (req["level5"] = level5);
    props.fetchFilterData(req, { key: "l12" });
    setRequest((old) => {
      return {
        ...old,
        level6: valuesArray[0],
        style: null,
        color: null,
        article: null,
        source_store: null,
        destination_store: null,
      };
    });
    resetRestFilters(5);
  };

  const onColorChange = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
    setColor(valuesArray[1]);
    let req = {
      level1: [department],
      color: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    level5 && (req["level5"] = level5);
    level6 && (req["level6"] = level6);
    // level6 && (req["level7"] = level7);
    props.fetchFilterData(req, { key: "l6" });
    setRequest((old) => {
      return {
        ...old,
        color: valuesArray[0],
        article: null,
        style: null,
        source_store: null,
        destination_store: null,
      };
    });
    resetRestFilters(7);
  };

  const onArticleIdChange = (val, event, that) => {
    // let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(val, articleIdOptions, isEqual);
    setArticle(req_val);
    let req = {
      level1: [department],
      article: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    level5 && (req["level5"] = level5);
    level6 && (req["level6"] = level6);
    // level6 && (req["level7"] = level7);
    color && (req["color"] = [...color]);

    props.fetchFilterData(req, { key: "l14" });
    setRequest((old) => {
      return {
        ...old,
        article: req_val,
        style: null,
        source_store: null,
        destination_store: null,
      };
    });
    resetRestFilters(8);
  };

  const onStyleChange = (val, event, that) => {
    // let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(val, styleOptions, isEqual);
    setStyle(req_val);
    let req = {
      level1: [department],
      style: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    level5 && (req["level5"] = level5);
    level6 && (req["level6"] = level6);
    color && (req["color"] = [...color]);
    article && (req["article"] = [...article]);

    props.fetchFilterData(req, { key: "l15" });

    //write custom reset logic for now generic is done
    resetRestFilters(9);

    setRequest((old) => {
      return {
        ...old,
        style: req_val,
        source_store: null,
        destination_store: null,
      };
    });
  };

  const onSourceStoreChange = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    let req_val = intersectionWith(valuesArray[0], sourceStoreOptions, isEqual);

    setSourceStore(valuesArray[1]);
    let req = {
      level1: [department],
      source_store: [...req_val],
      start_date: min_date && moment(min_date).format("YYYY-MM-DD"),
      end_date: max_date && moment(max_date).format("YYYY-MM-DD"),
    };
    gender && (req["level2"] = [request?.level2]);
    subCat && (req["level3"] = request?.level3);
    dcs && (req["level4"] = dcs);
    level5 && (req["level5"] = level5);
    level6 && (req["level6"] = level6);
    color && (req["color"] = [...color]);
    article && (req["article"] = [...article]);
    style && (req["style"] = [...style]);
    props.fetchFilterData(req, { key: "l16" });
    setRequest((old) => {
      return {
        ...old,
        source_store: valuesArray[0],
        destination_store: null,
      };
    });
    resetRestFilters();
  };

  const onDestinationStoreChange = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    setDestinationStore(valuesArray[1]);
    setRequest((old) => {
      return {
        ...old,
        destination_store: valuesArray[0],
      };
    });
  };

  useEffect(() => {
    setDepartmentOptions(() => props.departmentOptions);
  }, [props.departmentOptions]);

  useEffect(() => {
    setGenderOptions(() => props.genderOptions);
  }, [props.genderOptions]);

  useEffect(() => {
    setSubCatOptions(() => props.subCatOptions);
  }, [props.subCatOptions]);

  useEffect(() => {
    setDCSOptions(() => props.dcsOptions);
  }, [props.dcsOptions]);

  useEffect(() => {
    setLevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    setLevel6Options(() => props.level6Options);
  }, [props.level6Options]);

  useEffect(() => {
    setStyleOptions(() => props.styleOptions);
  }, [props.styleOptions]);

  useEffect(() => {
    setColorOptions(() => props.colorOptions);
  }, [props.colorOptions]);

  useEffect(() => {
    setArticleIdOptions(() => props.articleIdOptions);
  }, [props.articleIdOptions]);

  useEffect(() => {
    setSourceStoreOptions(() => props.sourceStoreOptions);
  }, [props.sourceStoreOptions]);

  useEffect(() => {
    setDestinationStoreOptions(() => props.destinationStoreOptions);
  }, [props.destinationStoreOptions]);

  useEffect(() => {
    if (
      props.activeTab == "dailySummary" &&
      props.subActiveTab == "reporting_s2s"
    ) {
      let today = moment(new Date());
      setStartDate(today);
      setEndDate(today);

      getUserPreferenceFilter(
        { user_id: localStorage.getItem("user_id") },
        {
          onl1Change: onDepartmentChange,
          onl2Change: onGenderChange,
          // onl3Change: onSubCatChange,
        }
      );
      props.fetchFilterData(
        {
          start_date: moment(today).format("YYYY-MM-DD"),
          end_date: moment(today).format("YYYY-MM-DD"),
        },
        { key: "l1" }
      );
    }
    props.resetFiltersOptions([]);
    props.resetStoreViewData([]);
    //new logic for resetting so that table resets on subTab change
    props.setClickedArticle();
  }, [props.subActiveTab, props.activeTab]);

  const getDDLabel = (label) => (label ? label : "");

  return (
    <>
      <section className="row section">
        <div className="col-md-3 required">
          <label className="fnt-md fnt-bold">Period</label>
          <DateRangePicker
            startDate={min_date}
            startDateId="s_id"
            endDate={max_date}
            endDateId="e_id"
            onDatesChange={({ startDate, endDate }) => {
              onDateChange(startDate, endDate, "Static");
            }}
            focusedInput={focusedInput}
            onFocusChange={(e) => setFocusedInput(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={isFutureDate}
            withPortal
            numberOfMonths={3}
            minimumNights={0}
          />
        </div>
        <div className="col-md-3  required">
          <label className="fnt-md fnt-bold fnt-bold">
            {props.filterLabels?.level1}
          </label>
          <Select
            name="department"
            value={department}
            onChange={onDepartmentChange}
            placeHolder={`Select ${props.filterLabels?.level1 || "Department"}`}
            options={departmentOptions?.length ? departmentOptions : []}
          />
        </div>
        <div className={`col-md-3 ${isMandatory("l2_name")}`}>
          <label className="fnt-md fnt-bold fnt-bold">
            {props.filterLabels?.level2}
          </label>
          <MultiSelect
            name="gender"
            dropdownValue={gender}
            onDropDownChange={onGenderChange}
            placeHolder={`Select ${props.filterLabels?.level2 || "Department"}`}
            optionsArray={genderOptions?.length ? genderOptions : []}
          />
        </div>
        {!shouldHideForCurrentClient("l3_name") && (
          <>
            <div className={`col-md-3 ${isMandatory("l3_name")}`}>
              {getFilterForClients({
                label: props.filterLabels?.level3,
                name: "subCat",
                dropdownValue: subCat,
                onDropDownChange: onSubCatChange,
                placeHolder: props.filterLabels?.level3
                  ? props.filterLabels?.level3
                  : "Select Class",
                optionsArray: subCatOptions?.length ? subCatOptions : [],
                level: "l3_name",
                isStandAlone: true,
              })}
            </div>
            <div className="col-md-3  fill">
              <label className="fnt-md fnt-bold">
                {props.filterLabels?.level4}
              </label>
              {/* This is the L4 Filter. */}
              <MultiSelect
                name="dcs"
                dropdownValue={dcs}
                onDropDownChange={onDCSChange}
                placeHolder={`Select ${getDDLabel(
                  props.filterLabels?.level4 || "Collection"
                )}`}
                optionsArray={dcsOptions?.length ? dcsOptions : []}
              />
            </div>
          </>
        )}
        {!shouldHideForCurrentClient("l5_name") && (
          <div className="col-md-3  fill">
            <label className="fnt-md fnt-bold">
              {props.filterLabels?.level5}
            </label>
            <MultiSelect
              name="level5"
              dropdownValue={level5}
              onDropDownChange={onlevel5Change}
              placeHolder={`Select ${getDDLabel(
                props.filterLabels?.level5 || "Material Description"
              )}`}
              optionsArray={level5Options?.length ? level5Options : []}
            />
          </div>
        )}
        {!shouldHideForCurrentClient("l6_name") && (
          <div className="col-md-3  fill">
            <label className="fnt-md fnt-bold">
              {props.filterLabels?.level6}
            </label>
            <MultiSelect
              name="level6"
              dropdownValue={level6}
              onDropDownChange={onlevel6Change}
              placeHolder={`Select ${getDDLabel(
                props.filterLabels?.level6 || "Product Type"
              )}`}
              optionsArray={level6Options?.length ? level6Options : []}
            />
          </div>
        )}
        <div className="col-md-3  fill">
          <label className="fnt-md fnt-bold">{"Color"}</label>
          <MultiSelect
            name="color"
            dropdownValue={color}
            onDropDownChange={onColorChange}
            placeHolder={"Select Color"}
            optionsArray={colorOptions?.length ? colorOptions : []}
          />
        </div>
        <div className="col-md-3  fill">
          <label className="fnt-md fnt-bold">{"Style Color Id"}</label>
          <AsyncMulti
            name="article"
            dropdownValue={article}
            onDropDownChange={onArticleIdChange}
            placeHolder={"Select Article"}
            optionsArray={articleIdOptions?.length ? articleIdOptions : []}
          />
        </div>
        <div className="col-md-3  fill">
          <label className="fnt-md fnt-bold">{"Style"}</label>
          <AsyncMulti
            name="style"
            dropdownValue={style}
            onDropDownChange={onStyleChange}
            placeHolder={"Select Style"}
            optionsArray={styleOptions?.length ? styleOptions : []}
          />
        </div>
        <div className="col-md-3  fill">
          <label className="fnt-md fnt-bold">{"Source Store"}</label>
          <MultiSelect
            name="source_store"
            dropdownValue={sourceStore}
            onDropDownChange={onSourceStoreChange}
            placeHolder={"Select Store"}
            optionsArray={sourceStoreOptions?.length ? sourceStoreOptions : []}
          />
        </div>
        <div className="col-md-3  fill">
          <label className="fnt-md fnt-bold">{"Destination Store"}</label>
          <MultiSelect
            name="destination_store"
            dropdownValue={destinationStore}
            onDropDownChange={onDestinationStoreChange}
            placeHolder={`Select destination store`}
            optionsArray={
              destinationStoreOptions?.length ? destinationStoreOptions : []
            }
          />
        </div>
        <div className="col-md-3 ">
          <button
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            onClick={() => resetFilters()}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div
          className="col-md-3  required fnt-md pt-5"
          style={{ color: "red" }}
        >
          <label></label>Fields are mandatory
        </div>
      </section>
    </>
  );
}

const mapStateToProps = ({ dailySummaryS2S }) => ({
  filterLabels: dailySummaryS2S.filterlabels,
  departmentOptions: dailySummaryS2S.departmentOptions,
  genderOptions: dailySummaryS2S.genderOptions,
  subCatOptions: dailySummaryS2S.subCatOptions,
  dcsOptions: dailySummaryS2S.dcsOptions,
  level5Options: dailySummaryS2S.level5Options,
  level6Options: dailySummaryS2S.level6Options,
  styleOptions: dailySummaryS2S.styleOptions,
  colorOptions: dailySummaryS2S.colorOptions,
  articleIdOptions: dailySummaryS2S.articleIdOptions,
  sourceStoreOptions: dailySummaryS2S.sourceStoreOptions,
  destinationStoreOptions: dailySummaryS2S.destinationStoreOptions,
});
const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload, filterKey, filterType) =>
    dispatch(fetchFilterData(payload, filterKey, filterType)),
  resetFiltersOptions: (filters) => dispatch(resetFiltersOptions(filters)),
  resetStoreViewData: (filters) => dispatch(resetStoreViewData(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailySummaryFiltersS2S);
