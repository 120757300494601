import React, { useEffect, useRef, useState } from 'react'
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping';
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { DAILY_SUMMARY_TABLE_HEADERS } from './DailySummaryConstants';
import PageLoader from '../../../components/Loader/PageLoader'
import ReactTable from '../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../components/Table/WithPlugins';
import { connect } from 'react-redux';
import { scrollIntoView } from '../../../utils/commonUtilities';
import { fetchSKUData } from './DailySummaryAction'
import ExportFile from '../../../components/ExportFile/ExportFile';
import { ERROR_MSG } from '../../../constants/globalConstant';
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const SKUStoreLevelDetails = (props) => {
    const { dcList, dc_map } = props.dc

    const [colums, setColums] = useState([])

    const tableRef = useRef()

    useEffect(() => {
        props.subActiveTab === "reporting_dc" && props.fetchSKUData({request:props.req, dc: props.dc})
    },[props.req])

    const skuStoreTableColumns = React.useMemo(() =>
        [
            {
                Header: DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID,
                accessor: "plan_description",
                Filter: ColumnFilter,
                width:350,
            },
            {
                Header: DAILY_SUMMARY_TABLE_HEADERS.STORE_ID,
                accessor: "store",
                id: "store_code",
                Filter: ColumnFilter,
                width:200,
            },
            {
                Header: "Units Allocated",
                identify: "units_allocated",
                columns: [],
            },
            {
                Header: "DC Units OH",
                identify: "units_OH",
                columns: [],
            },
        ],[props.sizeData])

    useEffect(() => {
        if(props.tableData?.length)
            scrollIntoView(tableRef)
    }, [props.tableData])

    
    useEffect(() => {
        if(props.sizeData.length) {
            let unitIndex = skuStoreTableColumns.findIndex(col => col.identify === "units_allocated")
            let ohIndex = skuStoreTableColumns.findIndex(col => col.identify === "units_OH")

            props.sizeData?.forEach((val) => {
              skuStoreTableColumns[unitIndex]?.columns.push({
                Header: val,
                columns: dcList?.map((dcKey) => {
                  return {
                    Header: dc_map[dcKey],
                    id: dcKey + val,
                    accessor: (row) => row?.units_allocated_dc?.[val]?.[dcKey] || 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                  };
                }),
              });
              skuStoreTableColumns[ohIndex]?.columns.push({
                Header: val + " ",
                columns: dcList?.map((dcKey) => {
                  return {
                    Header: dc_map[dcKey],
                    id: dcKey + val + "oh",
                    accessor: (row) => row?.units_allocated_dc?.[val]?.[dcKey] || 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                  };
                }),
              });
            });
        }
        setColums(skuStoreTableColumns)
    },[props.sizeData])


    return (
        <>
              <div>
                <PageLoader loader={props.loading} gridLoader={true}>
                    {
                        props.error ? <div className="error">{props.tableDataError ||  ERROR_MSG}</div>
                        : 
                        <>
                            <div style={{paddingTop:'3rem'}} ref={tableRef}>
                                <div className="deepdive__export__wrapper">
                                    {props?.tableData.length ? <ExportFile text={"Excel"} data={props.skuExcelData} sheetName={"Daily Allocation Summary"} fileName={`daily_summary_${new Date().getTime()}`} type={1} /> : null}
                                </div>
                                <ReactTableWithPlugins
                                    columns={colums}
                                    data={props?.tableData?.length ? props.tableData : []}
                                    renderMarkup='TableMarkup'
                                    keyRT="sortAndSearch"
                                    shouldPagination
                                    totalRecordsLen={props?.tableData?.length}
                                    tableId="skuStoreDailySummary"
                                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                />
                            </div>
                        </>
                    }
                </PageLoader>
            </div>
        </>

    )
}

const mapStateToProps = ({ dailySummary }) => ({
    error: dailySummary.skuError,
    tableDataError: dailySummary.skuErrorMessage,
    loading: dailySummary.skuLoading,
    tableData: dailySummary.skuTableData,
    sizeData: dailySummary.sizeData,
    skuExcelData: dailySummary.skuExcelData,
  });
  
const mapDispatchToProps = (dispatch) => ({
    fetchSKUData:  (payload) => dispatch(fetchSKUData(payload)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(SKUStoreLevelDetails)
