import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
} from "./StoretoStoreActions";
import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { isEmpty, pickBy } from "lodash";

import { ERROR_MSG } from "../../constants/globalConstant";
import { getStore2StoreFilterData } from "../../routes/api";

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    let l_req = {};

    if (payload["filter_type"] && filterType["key"] === "dc") {
      l_req = payload;
    } else if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          req[key] = payload[key].map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele.value;
            }
          });
        }
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
      l_req = pickBy(req, (value) => value.length > 0);
    }

    const res = yield call(getStore2StoreFilterData, l_req);

    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data.filterMapping;
      if (filterType["key"] === "dc") {
        data["data"] = res.data.data.map((element) => ({
          value: element["store_code"],
          label: element["store_name"],
        }));
      } else {
        // let topObject = Object.keys(res.data.data[0])[0];
        for (const key in res.data.data[0]) {
          let k = "";
          if (key === "l1_name") {
            k = "level1Options";
          } else if (key === "l2_name") {
            k = "level2Options";
          } else if (key === "l3_name") {
            k = "level3Options";
          } else if (key === "l4_name") {
            k = "level4Options";
          } else if (key === "l5_name") {
            k = "level5Options";
          } else if (key === "l6_name") {
            k = "level6Options";
          } else if (key === "l7_name") {
            k = "level7Options";
          } else if (key === "style") {
            k = "styleIdOptions";
          } else if (key === "article") {
            k = "styleColorIdOptions";
          } else if (key === "dc") {
            k = "dcOptions";
          } else if (key === "factory_type") {
            k = "factoryTypeOptions";
          } else if (key === "lms_store_attribute") {
            k = "lmsStoreAttributeOptions";
          } else if (key === "lms_store_value") {
            k = "lmsStoreValueOptions";
          }

          // if (key === "style" || key === "color") {
          //   data[k] = res.data.data.map((element) => ({
          //     value: element[key],
          //     label: element[key],
          //   }));
          // } else
          if (key == "dc") {
            if (res.data.data[0][key]) {
              data["dcOptions"] = Object.entries(res.data.data[0][key])?.map(
                (element) => ({
                  value: element[0],
                  label: element[1],
                })
              );
            }
            // Do nothing for Ashley
          } else {
            let values;
            if (key === "style") {
              values = Object.values(res.data.data[0][key]);
            } else {
              values = res.data.data[0][key];
            }
            data[k] = values
              .filter((element) => element)
              .map((element) => ({
                value: element,
                label: element,
              }));
          }
        }
      }
      console.log("🚀 ~ function*fetchFilterDataWorker ~ data:", data);
      yield put(
        fetchFilterDataSuccesss({ data: data, key: action?.filterType?.key })
      );
    } else {
      yield put(fetchFilterDataError({ error: res.error }));
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

export function* storeToStoreSaga() {
  yield all([fetchFilterDataWatcher()]);
}
