import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../../../../routes/api";
import * as actionTypes from "./Actions/ActionTypes";
import { ERROR_MSG } from "../../../../../constants/globalConstant";
import {updateTransferUnitsForMultipleStoresSuccess,updateTransferUnitsForMultipleStoresError} from "./Actions/Actions"

function* fetchStoreView(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getSTSStoreView, payload);
    if (response.status) {
      yield put({
        type: actionTypes.GET_STORE_VIEW_SUMMARY_SUCCESS,
        data: response?.data?.data
      });
    } else {
      yield put({
        type: actionTypes.GET_STORE_VIEW_SUMMARY_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STORE_VIEW_SUMMARY_ERROR, error: ERROR_MSG });
  }
}

function* fetchStoreProductView(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.getSTSStoreProductView, payload);
    if (response.status) {
      yield put({
        type: actionTypes.GET_STORE_PRODUCT_VIEW_SUCCESS,
        data: response?.data?.data
      });
    } else {
      yield put({
        type: actionTypes.GET_STORE_PRODUCT_VIEW_ERROR,
        error: response.data.message ? response.data.message : ERROR_MSG
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_STORE_PRODUCT_VIEW_ERROR, error: ERROR_MSG });
  }
}

function* updateTransferUnitsForStoresWorker(action){
  const response = yield call(Api.updateTransferUnitsForStoreToStore, {...action.payload })
  try {
      if (response.status) {
          yield put(updateTransferUnitsForMultipleStoresSuccess({ 'allocationCode': response?.data?.data?.allocation_code, 'data': true }))
      }
      else {
          yield put(updateTransferUnitsForMultipleStoresError({ 'error': ERROR_MSG }));
      }
  }
  catch (error) {
      yield put(updateTransferUnitsForMultipleStoresError(error));
  }
   
}

function* fetchStoreViewWatcher() {
  yield takeLatest(actionTypes.GET_STORE_VIEW_SUMMARY, fetchStoreView);
}

function* fetchStoreProductViewWatcher() {
  yield takeLatest(actionTypes.GET_STORE_PRODUCT_VIEW, fetchStoreProductView);
}

function* updateTransferUnitsForStoresWatcher() {
  yield takeLatest(actionTypes.UPDATE_TRANSFER_UNITS_FOR_STORES, updateTransferUnitsForStoresWorker)
}

export function* storeViewSaga() {
  yield all([
    fetchStoreViewWatcher(),
    fetchStoreProductViewWatcher(),
    updateTransferUnitsForStoresWatcher()
  ]);
}
