import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes";
import { isEmpty } from "lodash";
import { levelFiltersLabelFormatter } from "../../../../../utils/filterLevelMapping";
import { ERROR_MSG } from "../../../../../constants/globalConstant";
import moment from "moment";
import { getDailySummaryStoreViewS2S } from "../../../../../routes/api";
import { cardsInfo } from "../DailySummaryConstants";
import { FETCH_STORE_DETAILS_TABLE_DATA } from "./Actions/ActionTypes";
import { getDailySummaryStoreArticleDetailsS2S } from "../../../../../routes/api";
import {
  fetchStoreViewDetailsS2SSuccess,
  fetchStoreViewDetailsS2SError,
} from "./Actions/StoreViewS2SActions";

function* getStoreViewWorker(action) {
  try {
    const { payload } = action;
    const { request } = payload;
    const req = {};
    for (let i in request) {
      if (!isEmpty(request[i])) {
        if (Array.isArray(request[i])) {
          req[i] = request[i].map((val) => val.value);
        } else {
          req[i] = request[i];
        }
      }
    }

    const res = yield call(getDailySummaryStoreViewS2S, req);

    if (res.data.status) {
      const { aggregated_data, table_data } = res?.data;

      const l_aggregated_data = aggregated_data[0];

      const cardData = [
        {
          title: cardsInfo.CARD1,
          value: l_aggregated_data?.allocation_count || 0,
        },
        {
          title: cardsInfo.CARD2,
          value: l_aggregated_data?.style_color_count || 0,
        },
        {
          title: cardsInfo.CARD3,
          value: l_aggregated_data?.total_transfer_units || 0,
        },
        {
          title: cardsInfo.CARD4,
          value: l_aggregated_data?.total_source_inventory || 0,
        },
        {
          title: cardsInfo.CARD5,
          value: l_aggregated_data?.total_destination_inventory || 0,
        },
      ];

      yield put({
        type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_SUCCESS,
        data: {
          tableData: res.data.table_data,
          cardData: cardData,
        },
      });
    } else {
      yield put({
        type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR,
        error: ERROR_MSG,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DAILY_SUMMARY_STORE_VIEW_ERROR,
      error: ERROR_MSG,
    });
  }
}

function* fetchStoreDetailsTableWorker(action) {
  try {
    const { payload } = action;
    const { request } = payload;
    const req = {};
    for (let i in request) {
      if (!isEmpty(request[i])) {
        if (Array.isArray(request[i])) {
          req[i] = request[i].map((val) => val.value);
        } else {
          req[i] = request[i];
        }
      }
    }

    const res = yield call(getDailySummaryStoreArticleDetailsS2S, req);

    const excelData = res.data.data.flatMap((detail) => {
      // If there are no subRows, create one row with main details
      if (!detail.subRows || detail.subRows.length === 0) {
        return [
          {
            [levelFiltersLabelFormatter("level1")]: detail.l1_name,
            [levelFiltersLabelFormatter("level2")]: detail.l2_name,
            [levelFiltersLabelFormatter("level3")]: detail.l3_name,
            [levelFiltersLabelFormatter("level4")]: detail.l4_name,
            [levelFiltersLabelFormatter("level5")]: detail.l5_name,
            [levelFiltersLabelFormatter("level6")]: detail.l6_name,
            Style: detail.style,
            Color: detail.color,
            "Style Description": detail.style_description,
            "Style Color": detail.style_color,
            "Global Color": detail.global_color,
            "Source Store": detail.source_store,
            "Source Store Name": detail.source_store_name,
            "Style Color Id": detail.article,
            Sizes: "",
            "Plan Name": "",
            "Released Date": "",
            Style: detail.style,
            "Transfer Units": "",
            "Source Inventory": "",
            "Source WOS": "",
            "Destination Inventory": "",
            "Destination WOS": "",
          },
        ];
      }

      // If there are subRows, create a row for each subRow
      return detail.subRows.map((subRow) => ({
        [levelFiltersLabelFormatter("level1")]: detail.l1_name,
        [levelFiltersLabelFormatter("level2")]: detail.l2_name,
        [levelFiltersLabelFormatter("level3")]: detail.l3_name,
        [levelFiltersLabelFormatter("level4")]: detail.l4_name,
        [levelFiltersLabelFormatter("level5")]: detail.l5_name,
        [levelFiltersLabelFormatter("level6")]: detail.l6_name,
        Style: detail.style,
        Color: detail.color,
        "Style Description": detail.style_description,
        "Style Color": detail.style_color,
        "Global Color": detail.global_color,
        "Source Store": detail.source_store,
        "Source Store Name": detail.source_store_name,
        "Style Color Id": detail.article,
        Sizes: subRow.size,
        "Plan Name": subRow.plan_name,
        "Released At": subRow.released_at
          ? moment(subRow.released_at).format("MM-DD-YYYY HH:mm:ss")
          : "",
        "Transfer Units": subRow.transfer_units,
        "Source Inventory": subRow.source_inventory,
        "Source WOS": subRow.source_wos,
        "Destination Inventory": subRow.destination_inventory,
        "Destination WOS": subRow.dest_wos,
      }));
    });

    if (res.data.status) {
      yield put(
        fetchStoreViewDetailsS2SSuccess({
          data: res?.data?.data,
          excelData: excelData,
        })
      );
    } else {
      yield put(fetchStoreViewDetailsS2SError({ error: ERROR_MSG }));
    }
  } catch (error) {
    yield put(fetchStoreViewDetailsS2SError({ error: ERROR_MSG }));
  }
}

function* getStoreViewWatcher() {
  yield takeLatest(
    actionTypes.GET_DAILY_SUMMARY_STORE_VIEW,
    getStoreViewWorker
  );
}
function* fetchStoreDetailsTableData() {
  yield takeLatest(
    FETCH_STORE_DETAILS_TABLE_DATA,
    fetchStoreDetailsTableWorker
  );
}

export function* storeViewSagaS2S() {
  yield all([getStoreViewWatcher(), fetchStoreDetailsTableData()]);
}
