import { handleActions } from "redux-actions";
import createRule from "./CreateStoreTransferRule/Reducers/CreateRuleReducer"
import storeTransferRules from "./StoreTransferRules/Reducers/RulesReducers"

const storeTransferRule = {
    storeTransferRule: handleActions(
        {

        },
        {},
    ),
    ...createRule,
    ...storeTransferRules
};

export default storeTransferRule;