import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CREATE_STORE_TRANSFER_RULE,
  STORE_TRANSFER_RULE_PAGE,
} from "../../../../constants/routeConstants";
import StoreTransferRulesTable from "./StoreTransferRulesTable";
import PageLoader from "../../../../components/Loader/PageLoader";
import { deleteRule, getRules, getRuleMapping } from "./Actions/RulesActions";
import { connect } from "react-redux";
import * as Notify from "../../../../components/Notification/Notifications";
import { getAffectedArticleCount } from "../../../../routes/api";
import RuleMappingDetails from "./RuleMappingDetails";

const StoreTransferRulesView = (props) => {
  const {
    getRules,
    rules,
    rulesLoading,
    deleteRule,
    deleteRuleLoading,
    deleteRuleError,
    deleteRuleSuccess,
    getRuleMapping,
    ruleMappingData,
    ruleMappingLoading
  } = props;

  const [ruleName, setRuleName] = useState(false);

  useEffect(() => {
    getRules({});
  }, []);

  const groupId = localStorage.getItem("group_id");
  const history = useHistory();

  const goToAddNewRule = () => {
    history.push(`${CREATE_STORE_TRANSFER_RULE}?step=0`);
  };

  const handleEdit = (rule_id) => {
    history.push(`${STORE_TRANSFER_RULE_PAGE}/edit/${rule_id}?step=0`);
    getRules({ rule_id });
  };

  const handleDelete = async (rule_id) => {
    try {
      const res = await getAffectedArticleCount({ rule_id });
      if (res?.data?.status) {
        const { article_count } = res.data;
        if (
          window.confirm(
            `${article_count} articles will affect, Are you sure you wish to delete this rule?`
          )
        ) {
          deleteRule({ rule_id });
        }
      }
    } catch (error) {
      console.log("getAffectedArticleCount", error);
    }
  };

  useEffect(() => {
    if (deleteRuleSuccess) {
      Notify.success("Rule Deleted Successfully!!");
      getRules({});
    } else if (deleteRuleError) {
      Notify.error("Error in deleting rule!!");
    }
  }, [deleteRuleSuccess, deleteRuleError]);

  const getRuleMappingDetails = (rule_id, name) => {
    setRuleName(name);
    getRuleMapping({rule_id});
  }

  const closeAlert = () => {
    setRuleName(false);
  }

  return (
    <PageLoader loader={rulesLoading || deleteRuleLoading} gridLoader={true}>
      {ruleName && <RuleMappingDetails ruleName={ruleName} closeAlert={closeAlert} data={ruleMappingData} loading={ruleMappingLoading} />}

      <div className="container__wrapper pt-0">
        <div className="container__header m-0 p-3 align-items-end">
          <h3 className="fnt-bold m-0">Store Transfer Rules</h3>
          <div className="row">
            {/* <button className="btn btn-primary-light mr-4" title="Edit">Edit</button> */}
            <button
              disabled={groupId != 1}
              className="btn btn-primary mr-2 px-4"
              onClick={goToAddNewRule}
            >
              Add New Rule
            </button>
          </div>
        </div>
        <hr className="m-0" />
        <StoreTransferRulesTable
          data={rules}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          getRuleMapping={getRuleMappingDetails}
        />
      </div>
    </PageLoader>
  );
};

const mapStateToProps = ({ storeTransferRules }) => ({
  rules: storeTransferRules.rules,
  rulesLoading: storeTransferRules.rulesLoading,
  rulesError: storeTransferRules.rulesError,
  rulesSuccess: storeTransferRules.rulesSuccess,
  deleteRuleLoading: storeTransferRules.deleteRuleLoading,
  deleteRuleError: storeTransferRules.deleteRuleError,
  deleteRuleSuccess: storeTransferRules.deleteRuleSuccess,
  ruleMappingData: storeTransferRules.ruleMappingData,
  ruleMappingLoading: storeTransferRules.ruleMappingLoading,
  ruleMappingError: storeTransferRules.ruleMappingError,
});

const mapDispatchToProps = (dispatch) => ({
  getRules: (payload) => dispatch(getRules(payload)),
  deleteRule: (payload) => dispatch(deleteRule(payload)),
  getRuleMapping: (payload) => dispatch(getRuleMapping(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreTransferRulesView);
