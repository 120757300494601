export const COMBINATION_OPTIONS = [
    {
      label: "Source and Destination",
      value: 1,
    },
    {
      label: "Source",
      value: 2,
    },
    {
      label: "Destination",
      value: 3,
    },
  ];